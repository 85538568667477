<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
// import AuthNavbar from '../components/Navbars/AuthNavbar.vue';

export default {
  data() {
    return {
      pages: false,
    };
  },
  components: {
    Navbar,
    // FooterComponent,
    // AuthNavbar,
  },
};
</script>
