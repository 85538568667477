<template>
  <div>
    <div class="back"></div>
    <div class="heart"></div>
  </div>
</template>
<script>
import config from "../../config";
import { configcode } from "../config/bzbsconfig";
const baseUrl = configcode.baseSelfUrl;
export default {
  data() {
    return {
      // redirect_uri: "http://localhost:8080",
      redirect_uri: baseUrl,
      client_secret: localStorage.getItem("client_secret"),
      client_id: localStorage.getItem("client_id"),
      scope: "profile%20openid%20email",
      nonce: "Saijai",
      grant_type: "authorization_code",
      branch_clinic: "",
      clinic_id: "",
      code: "",
      token: "",
      content: "Content-Type: 'application/x-www-form-urlencoded'",
      pathUrl: "",
    };
  },
  methods: {
    getProfile() {
      this.$store
        .dispatch("getProfile")
        .then((res) => {
          console.log(res);
          console.log(this.$route.name);
          console.log(this.pathUrl);
          const pathname = this.pathUrl;
          if (localStorage.getItem("redirect_approve")) {
            let pathapprove = localStorage.getItem("redirect_approve");
            localStorage.removeItem("redirect_approve");
            window.location = this.redirect_uri + pathapprove;
          } else if (pathname == "mycourse_line") {
            this.$store.commit("ChangePages", "course");
            this.$store.commit("ChangePageBack", "course");
            this.$router.push("/dashboard");

            console.log("appointment_line");
          } else if (pathname == "appointment_line") {
            this.$store.commit("ChangePages", "appointList");
            this.$store.commit("ChangePageBack", "appointList");
            this.$router.push("/dashboard");

            console.log("profile_line");
          } else if (pathname == "profile_line") {
            this.$store.commit("ChangePages", "myProfile");
            this.$store.commit("ChangePageBack", "myProfile");
            this.$router.push("/dashboard");
          } else {
            this.$router.push("/dashboard");
          }

          // this.$router.push("/dashboard");
          // setInterval(function(){
          //   // location.replace("/dashboard")

          // },1000)
        })
        .catch((err) => {
          console.log(err);
          this.$router.push({ name: "login" });
          //  setInterval(function(){
          //   // location.replace("/login")
          // },1000)
          //
        });
    },
    checkLineLogin() {
      var branch_id = localStorage.getItem("branch");

      console.log(branch_id);
      this.token = localStorage.getItem("token_" + branch_id);
      console.log(this.token);
      this.code = this.$route.query.code;
      this.state =
        typeof this.$route.query.state == "undefined"
          ? this.$route.params.id
          : this.$route.query.state;
      console.log(this.state);
      console.log(`${typeof this.token}  ${typeof this.code}`);
      if (this.$route.query.redirect) {
        localStorage.setItem("redirect_approve", this.$route.query.redirect);
      }
      if (typeof this.token != "string" && typeof this.code != "string") {
        location.replace(
          "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=" +
            this.client_id +
            "&redirect_uri=" +
            this.redirect_uri +
            "&state=" +
            this.state +
            "&scope=" +
            this.scope +
            "&nonce=" +
            this.nonce
        );
      } else {
        if (typeof this.token != "string") {
          const {
            content,
            grant_type,
            code,
            redirect_uri,
            client_id,
            client_secret,
            state,
          } = this;
          this.$store
            .dispatch("getTokenLine", {
              content,
              grant_type,
              code,
              redirect_uri,
              client_id,
              client_secret,
              state,
            })
            .then((res) => {
              console.log(res);
              console.log(this.state);
              localStorage.setItem("branch", this.state);
              console.log(this.state);
              this.getProfile();
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.getProfile();
        }
      }
    },
    getLineLich(id) {
      this.$store.dispatch("getLineLich", id).then(async (res) => {
        console.log(res);
        console.log(res[0].clinic_id);
        this.client_id = res[0].channel_id;
        this.client_secret = res[0].channel_secret;
        this.branch_clinic = res[0].clinic_id;
        // this.client_id = '2000732702';
        // this.client_secret = 'c4ae60b09623dd11155391a2f47be0de';
        localStorage.setItem("client_id", this.client_id);
        localStorage.setItem("client_secret", this.client_secret);
        localStorage.setItem("clinic_id", res[0].clinic_id);
        // this.checkLineLogin();
        console.log(this);
        if (
          typeof localStorage.getItem("token_" + this.branch_clinic) == "string"
        ) {
          // localStorage.clear();
          // await this.getLineLich(this.branch_clinic);
          await this.checkLineLogin();

          // localStorage.setItem("branch", this.$route.params.id);
        } else {
          // await this.getLineLich(this.branch_clinic);
          await this.checkLineLogin();
        }
      });
    },
  },
  async mounted() {
    this.branch_clinic =
      typeof this.$route.query.state == "undefined"
        ? this.$route.params.id
        : this.$route.query.state;
    localStorage.setItem("branch", this.branch_clinic);
    this.pathUrl = this.$route.name;
    console.log(this.$route);
    await this.getLineLich(this.branch_clinic);
  },
};
</script>
<style scoped>
.center {
  display: flex;
}

.back {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  animation-name: backdiv;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.heart {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: #00205b; */
  background-position: center;
  background-image: url("../assets/img/icons/Logo5.png");
  background-repeat: no-repeat;
  height: auto;
  width: auto;
  transform: rotate(1deg);
  animation-name: beat;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes backdiv {
  50% {
    background: #ffe6f2;
  }
}

@keyframes beat {
  0% {
    transform: scale(1) rotate(1deg);
  }

  50% {
    transform: scale(0.6) rotate(1deg);
  }
}
</style>
