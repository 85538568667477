<template>
  <div class="container mt-3 py-4">
    <div v-if="!confirm" class="detail-promotion">
      <div class="img-detail-courses">
        <img
          v-if="detailCourse.course_picture"
          style="border-radius: 13px"
          :src="url + detailCourse.course_picture"
          alt=""
        />
        <img
          v-else
          style="border-radius: 13px"
          src="../../assets/img/default.jpg"
          alt=""
        />
      </div>
      <div class="mt-2">
        <h4>แพ็คเกจ : {{ detailCourse.name_th }}</h4>
        <span class="text-coin"
          ><img
            src="../../assets/img/icons/coin.png"
            alt=""
            class="img-coin"
          />{{ detailCourse.points }} คะแนน</span
        >
      </div>
      <div class="detail-text">
        <b>รายละเอียด</b>
        <p>
          {{ detailCourse.details_th }}
        </p>
      </div>
      <hr class="mt-3 mb-3" style="border-top: 1px solid #aaaaaa" />
      <div id="items-product">
        <div class="mt-3">
          <b>สินค้าประกอบ</b>
          <table style="width: 100%">
            <tbody>
              <tr
                v-for="(product, idp) in detailCourse.product"
                :key="'p' + idp"
              >
                <td>{{ product.name_th }}</td>
                <td class="amount-text">{{ product.amount }}</td>
                <td class="text-right">{{ product.unit }}</td>
              </tr>
              <tr
                v-for="(service, ids) in detailCourse.service"
                :key="'s' + ids"
              >
                <td>{{ service.name_th }}</td>
                <td class="amount-text">{{ service.amount }}</td>
                <td class="text-right">{{ service.unit }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="price-detail mt-2">
        <table style="width: 100%">
          <tbody>
            <tr>
              <td>ราคา</td>
              <td class="text-right">฿ {{ formatNum(detailCourse.price) }}</td>
            </tr>
            <tr>
              <td>โปรโมชั่น</td>
              <td class="text-right">
                <span class="percent-discount" v-if="detailCourse.discount != 0"
                  >{{ "-" + formatNum(detailCourse.discount) }}
                  {{ detailCourse.discount_type == 1 ? "บาท" : "%" }}</span
                >
                <span v-else>-</span>
              </td>
            </tr>
            <tr v-if="campaignList.length != 0">
              <td>แคมเปญส่วนลด</td>
              <td class="text-right">
                <select
                  style="border-color: #00205c"
                  class="form-control"
                  name="campaign_select"
                  id="campaign_select"
                  v-model="campaign"
                >
                  <option value="">เลือกส่วนลด</option>
                  <option
                    v-for="(cp, icp) in campaignList"
                    :key="icp"
                    :value="cp"
                  >
                    {{ cp.campaign_name_th }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>ราคารวม</td>
              <td>
                <h3 class="text-right">
                  ฿ {{ calculateTotal(detailCourse.total) }}
                </h3>
              </td>
            </tr>
            <tr v-if="detailCourse.down_payment == 1">
              <td>มัดจำ</td>
              <h2 class="text-right">
                ฿ {{ formatNum(detailCourse.down_price) }}
              </h2>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="mt-4">
        <div v-if="btnLoading">
          <ButtonLoading />
        </div>

        <div v-else>
          <button
            v-if="detailCourse.down_payment == 1"
            class="btn btn-main-submit form-control mb-2"
            @click="handleBooking(detailCourse.id)"
          >
            ชำระมัดจำ
          </button>
          <button
            v-else
            class="btn btn-main-submit form-control mb-2"
            @click="handleBooking(detailCourse.id)"
          >
            จองแพ็คเกจ
          </button>
        </div>

        <!-- <button class="btn btn-danger form-control" @click="handleAfter">ยกเลิก</button> -->
      </div>
    </div>

    <div v-if="confirm">
      <!-- <booking-promotion :bookingCoures="responseBooking"></booking-promotion> -->
      <div class="booking">
        <div class="img-detail-courses">
          <img
            class="rounded"
            :src="url + responseBooking.course_picture"
            alt=""
          />
        </div>
        <div class="p-4" style="text-align: -webkit-center">
          <img src="../../assets/img/icons/booking-success.png" alt="" />
          <div class="border-booking">
            <p style="font-size: 20px" class="font-weight-bold">
              คุณได้ทำการจอง
            </p>
            <h4 style="font-size: 30px" class="text-yeah font-weight-bold">
              แพ็คเกจ : {{ responseBooking.name_th }}
            </h4>
            <h4
              style="font-size: 30px; color: #40609b"
              class="font-weight-bold"
            >
              ราคา ฿ {{ formatNum(responseBooking.total) }}
            </h4>
            <p style="font-size: 28px" class="mt-3 text-yeah font-weight-bold">
              เรียบร้อยแล้ว
            </p>
          </div>
          <div class="text-xl">
            <!-- <p>คุณจะได้รับคะนนสะสม {{ bookingCoures.points }} คะแนน</p> -->
            <!-- <p>เมื่อมีการเข้ารับบริการและชำระในครั้งแรก</p> -->
          </div>
          <div class="mt-3">
            <h5 class="text-yeah font-weight-bold">
              ทำการนัดหมายเพื่อเข้ารับบริการ
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <button
              class="btn btn-danger form-control"
              @click="RedirectMyCousres"
            >
              ภายหลัง
            </button>
          </div>
          <div class="col-7">
            <!-- <button
              class="btn btn-main-submit form-control"
              @click="AppointDate(responseBooking.id)"
            >
              นัดหมาย
            </button> -->
            <button
              class="btn btn-main-submit form-control"
              @click="getListDoctor(responseBooking.id)"
            >
              นัดหมาย
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <modal-booking :data_booking="`responseBooking`" /> -->
    <modal-booking :data_booking="responseBooking" :day_close="btn_appoint" />
  </div>
</template>
<script>
import BookingPromotion from "../Promotions/BookingPromotion";
import numeral from "numeral";
import ButtonLoading from "../ButtonLoading";
import ModalBooking from "../Modal/ModalBooking.vue";

export default {
  data() {
    return {
      confirm: false,
      campaign: "",
      url: this.$store.getters.url_api,
      detailCourse: {},
      responseBooking: {},
      campaignList: [],
      btnLoading: false,
      btn_appoint: true,
    };
  },
  components: {
    BookingPromotion,
    ButtonLoading,
    ModalBooking,
  },
  methods: {
    handleBooking(id) {
      this.btnLoading = true;
      if (this.campaign) {
        var dataBooking = {
          course_id: id,
          user_campaign_id: this.campaign.id,
        };
      } else {
        var dataBooking = { course_id: id };
      }
      console.log(dataBooking);
      console.log(this.detailCourse);
      // this.modalShow();

      // if (this.detailCourse.down_payment == 1) {
      //   // this.responseBooking = res.course;
      //   this.$store.dispatch("getProfile");
      //   this.$store.commit("ChangePages", "payments");
      //   this.$store.commit("idCourse", 311);
      // } else {
      //   // this.responseBooking = res.course;
      //   this.$store.dispatch("getProfile");
      //   this.$store.commit("idCourse", id);
      //   this.confirm = true;
      // }
      this.$store.dispatch("bookingCourse", dataBooking).then((res) => {
        console.log(res);
        this.btnLoading = false;
        if (this.detailCourse.down_payment == 1) {
          this.responseBooking = res.course;
          this.$store.dispatch("getProfile");
          this.$store.commit("ChangePages", "payments");
          this.$store.commit("idCourse", res.course.id);
        } else {
          this.$store
            .dispatch("getDetailCourseById", res.course.id)
            .then((res) => {
              this.responseBooking = res.course;
              // this.$store.dispatch("getProfile");
              if (this.detailCourse.doctor) {
                this.$store.commit("ChangePages", "doctorList");
                this.$store.commit("idCourse", res.course.id);
              } else {
                this.$store.commit("ChangePages", "appointDate");
                this.$store.commit("idCourse", res.course.id);
              }

              // this.modalShow();
              // this.confirm = true;
            });
        }
      });
    },
    handleAfter() {
      this.confirm = false;
    },
    RedirectMyCousres() {
      var branch_id = localStorage.getItem("branch");
      this.$store.dispatch("getCourseUser", branch_id).then((res) => {
        console.log(res);
        console.log(this.$store.getters.myCourse);
        this.$store.commit("ChangePages", "course");
      });
    },
    getListDoctor(id) {
      this.$store.commit("ChangePages", "doctorList");
      this.$store.commit("idCourse", id);
    },

    formatNum(num) {
      return numeral(num).format("0,0");
    },
    getDataCourse() {
      const id = this.$store.getters.id_course;
      this.$store.dispatch("getDetailCourse", id).then((res) => {
        console.log(res);
        this.detailCourse = res.course;
        this.campaignList = res.campaign;
        console.log(this.detailCourse);
      });
    },
    calculateTotal(num) {
      if (this.campaign.discount_type == 1) {
        var discount = num - this.campaign.discount;
        console.log(discount);
      } else if (this.campaign.discount_type == 2) {
        var dis = (num * this.campaign.discount) / 100;
        var discount = num - dis;
      } else {
        var discount = num;
      }
      return numeral(discount).format("0,0");
    },
    modalShow() {
      this.$root.$emit("dialogAppoint");
    },
    getDayClose() {
      const day = this.$store.getters.date_close;
      console.log(day.length);
      if (day.length == 7) {
        this.btn_appoint = false;
      }
    },
  },
  mounted() {
    console.log(this.$store.getters.id_course);
    this.getDayClose();
    this.getDataCourse();
  },
};
</script>
<style>
.amount-text {
  color: #9d1a3a;
  text-align: center;
}
.percent-discount {
  border: 1px solid red;
  border-radius: 10px;
  color: red;
  /* padding: 1px; */
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}
.detail-text p {
  color: #aaaaaa;
  font-size: 16px;
}
.price-detail {
  border: 1px solid #aaaaaa;
  padding: 3%;
}
#items-product b {
  font-size: 18px;
}
#items-product td {
  font-size: 20px;
}
.detail-promotion select {
  text-align-last: right;
}
.detail-promotion option {
  direction: rtl;
}
.text-complete {
  color: #9d1a3a;
}
</style>
