import axios from "axios";
import Vue from "vue";
// import store from "../store";
import { configcode } from '../config/bzbsconfig'

export function initAxios() {
  const api_url = configcode.baseUrl
  console.log(api_url);
  Vue.prototype.$http = axios;
  // Sets the default url used by all of this axios instance's requests
  // axios.defaults.baseURL = "https://api.saijai-crm.com";
  const branch = localStorage.getItem("branch");
  const token = localStorage.getItem("token_" + branch);
  axios.defaults.headers['Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept']
  axios.defaults.headers.common["Branches"] = branch;
  axios.defaults.baseURL = api_url;
  console.log(token);
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.defaults.headers.common["Branches"] = branch;
    axios.defaults.baseURL = api_url;
    // axios.defaults.baseURL = "https://api.saijai-crm.com";
    axios.defaults.headers.get["Accept"] = "application/json";
    console.log("testDDD");
  }

  axios.interceptors.request.use(
    function(request) {
      // Do something before request is sent
      // NProgress.start();
      return request;
    },
    function(error) {
      // Do something with request error
      console.log(error);
      // NProgress.done();
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function(response) {
      // NProgress.done();
      // console.log(response)
      return response;
    },
    function(error) {
      // Do something with response error
      console.log(error);
      // NProgress.done();
      return Promise.reject(error);
    }
  );
}
