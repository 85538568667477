import Vue from "vue";
import App from "@/App.vue";
import router from "./routes";
import { initAxios } from "./api/api";
import store from "./store";
import Vuelidate from "vuelidate";
import vueAwesomeCountdown from "vue-awesome-countdown";
// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import "@/assets/styles/custom.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/th.json";
import VCalendar from "v-calendar";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vSelect from "vue-select";

import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

// Vue.prototype.$liff = window.liff
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule],
  });
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(vueAwesomeCountdown, "vac");
Vue.use(Vuelidate);
Vue.use(VCalendar, {
  componentPreFix: "vc",
});
initAxios();
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
