<template>
  <div class="container mt-3 py-4">
    <div class="img-detail-courses">
      <img
        v-if="detailCourse.course_picture"
        style="border-radius: 13px"
        :src="url + detailCourse.course_picture"
        alt=""
      />
      <img
        v-else
        style="border-radius: 13px"
        src="../../assets/img/default.jpg"
        alt=""
      />
    </div>
    <div class="mt-2">
      <h4>แพ็คเกจ : {{ detailCourse.name_th }}</h4>
      <span class="text-coin"
        ><img src="../../assets/img/icons/coin.png" alt="" class="img-coin" />{{
          detailCourse.points
        }}
        คะแนน</span
      >
    </div>
    <div class="detail-text">
      <b>รายละเอียด</b>
      <p>
        {{ detailCourse.details_th }}
      </p>
    </div>
    <div class="my-4">
      <span class="font-weight-bold text-lg "
        >เลือกผู้ให้บริการที่คุณต้องการปรึกษา</span
      >
    </div>
    <div>
      <div
        @click="handleNoDoctor(detailCourse.id)"
        class="card mb-3 card-doctor"
      >
        <div class="row no-gutters">
          <div class="col-4">
            <img
              style="object-fit:cover;"
              class="p-3"
              src="../../assets/img/doctor.png"
              alt=""
            />
          </div>
          <div class="col-8 no-require">
            <h5>ไม่ระบุผู้ให้บริการ</h5>
          </div>
        </div>
      </div>
    </div>
    <hr class="py-2" />
    <div v-for="(val, idx) in doctorList" :key="idx">
      <doctor-card :data="val" />
    </div>
  </div>
</template>
<script>
import DoctorCard from "./DoctorCard.vue";
export default {
  components: { DoctorCard },
  data() {
    return {
      url: this.$store.getters.url_api,
      detailCourse: {},
      campaignList: [],
      doctorList: [],
    };
  },
  methods: {
    getDataCourse() {
      const id = this.$store.getters.id_course;
      console.log(id);
      this.$store.dispatch("getDetailCourseById", id).then((res) => {
        console.log(res);
        this.detailCourse = res.course;
        this.campaignList = res.campaign;
        this.doctorList = res.course.doctor;
        console.log(this.detailCourse);
      });
    },
    handleNoDoctor(id) {
      this.$store.commit("getDoctor", " ")
      this.$store.commit('getDayDoctor', " ")

      this.$store.commit("ChangePages", "appointDate");
      this.$store.commit("idCourse", id);
    },
  },
  mounted() {
    console.log(this.$store.getters.id_course);
    this.getDataCourse();
  },
};
</script>
<style scoped></style>
