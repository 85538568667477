<template>
  <div>
    <!-- <Navbar :checkPages="pageSet" /> -->
    <main class="profile-page py-16">
      <profile-tab />
      <branches />
    </main>
  </div>
</template>
<script>
// import Navbar from "../Navbars/AuthNavbar";
import ProfileTab from "../Profiles/ProfileTab";
import Branches from "./Branches";

export default {
  data() {
    return {
      pageSet: false,
      branches: [],
    };
  },
  components: {
    // Navbar,
    ProfileTab,
    Branches,
  },
  computed: {
    dynamicPage: function() {
      console.log(this.$store.getters);
      console.log(this.$store.getters.defaultPage);
      console.log(this.$store.getters.page_history);
      this.myCourseList = this.$store.getters.myCourse;
      if (this.$store.getters.defaultPage == this.$store.getters.page_history) {
        console.log("true");
        this.pageSet = false;
      } else {
        console.log("false");

        this.pageSet = true;
      }
      return this.$store.getters.defaultPage;
    },
  },
};
</script>
