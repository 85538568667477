<template>
  <div id="benefit-page">
    <div class="pt-5 mt-5 text-center" v-if="isLoading">
      <Loading />
    </div>
    <div v-else>
      <!-- Nav tabs -->
      <div class="mt-1 pt-5 mt-5">
        <div class="text-center">
          <h3>
            Tier Benefits
          </h3>
        </div>
        <ul class="nav nav-tabs nav-justified">
          <li class="nav-item" v-for="(tier, key) in tiers" :key="key">
            <a
              :class="`nav-link ${tier_user == tier.id ? 'active' : ''}`"
              data-toggle="tab"
              :href="`#${tier.name_en}`"
              @click="showTab(tier.id)"
              >{{ tier.name_th }}</a
            >
          </li>
        </ul>
      </div>
      <!-- Tab panes -->

      <div>
        <div class="tab-content" v-for="(detail, idx) in tiers" :key="idx">
          <div
            v-show="tier_user == detail.id"
            :class="
              `tab-pane container fade show card ${
                tier_user == detail.id ? 'active d-block' : ''
              }`
            "
            :id="detail.name_en"
          >
            <div class="text-xl text-secondary mt-3">
              ระดับของคุณ: {{ tier_user_current }}
            </div>
            <div class="text-xl">
              สิทธิประโยชน์
            </div>
            <div v-if="campaigns_list.length > 0">
              <a
                class="rewards"
                :class="detail.id != tier_current ? 'disabled' : ''"
                @click="handletoListReward(detail.id != tier_current)"
              >
                <div class="text-center py-3" v-if="tier_loading">
                  <Loading />
                </div>
                <div v-else>
                  <div
                    class="row no-gutters py-3"
                    style="border-block-end: #F1F1F3 3px solid;"
                    v-for="(val, key) in campaigns_list"
                    :key="key"
                  >
                    <div class="col-3">
                      <div class="lock_tier" v-show="detail.id != tier_current">
                        <i class="fas fa-lock text-white"></i>
                      </div>
                      <img
                        class="img_benefit"
                        :src="checkImg(val.campaign_picture)"
                        alt=""
                      />
                    </div>
                    <div class="col-9 px-3">
                      <div class="title_campaign text-truncate">
                        <span class="font-weight-bold">ชื่อแคมเปญ : </span>
                        <span class="">{{ val.campaign_name_th }}</span>
                      </div>
                      <div class="detail_campaign">
                        <div class="flex justify-between">
                          <div>
                            <span>ประเภทแคมเปญ : </span>
                            <span class="text-campaign-type">{{
                              val.campaign_type == 1 ? "ฟรี" : "ส่วนลด"
                            }}</span>
                          </div>
                          <div v-if="val.campaign_type == 2">
                            <span>ใช้ร่วมกับ : </span>
                            
                            <span class="text-coupon-type">{{
                              val.campaign_type == 2 && val.coupon_type == 1
                                ? "ส่วนลดแพ็คเกจ"
                                : ""
                            }}</span>
                            <span class="text-coupon-type">{{
                              val.campaign_type == 2 && val.coupon_type == 2
                                ? "ทั่วไป"
                                : ""
                            }}</span>
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div>
                            <span>แลกได้ถึง : </span>
                            <span class="text-date-time">{{
                              formatDate(val.end_date)
                            }}</span>
                          </div>
                          <div>
                            <span>คะแนนที่ใช้ : </span>
                            <span class="text-use-points">{{
                              formatNum(val.points)
                            }}</span>
                          </div>
                        </div>
                        <div>
                          <span>อายุการใช้งาน : </span>
                          <span class="text-date-time">
                            {{ val.duration }} </span
                          ><span> {{ typeDuration(val.duration_type) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div v-else>
              <div>
                <div class="img-benefit">
                  <img src="../../assets/img/benefit_tiers.png" alt="" />
                </div>
                <div class="font-weight-bold px-2">
                  <span
                    >ยินดีต้อนรับเข้าสู่ SaijaisoftRewards! รับคะแนนกับทุกๆ
                    การใช้บริการและการจองแพ็คเกจสุดพรีเมียมยิ่งได้รับคะแนนมากยิ่งได้เลื่อนขั้นระดับสมาชิกเร็วขึ้น
                    พร้อมรับสิทธิพิเศษอีกมากมาย</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div>
          <div class="text-xl mb-2">
            ระดับของสมาชิก
          </div>
          <table>
            <tbody>
              <tr v-for="(lv, idx) in tiers" :key="idx">
                <td>
                  <div
                    :style="
                      `background-color: ${lv.color};border-radius:50%;width:35px;height:35px;`
                    "
                  ></div>
                </td>
                <td class="text-xl">
                  {{ lv.name_th }} : สะสม {{ checkColor(lv.points) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import numeral from "numeral";
import moment from "moment";
import Loading from "../Loading";
export default {
  data() {
    return {
      tiers: "",
      tier_current: "",
      tier_user_current: "",
      tier_user: "",
      campaigns_list: "",
      url: this.$store.getters.url_api,
      current_tab: "",
      isLoading: true,
      tier_loading: true,
    };
  },
  components: {
    Loading,
  },
  methods: {
    checkColor(point) {
      return point == 0
        ? point + " คะแนน"
        : numeral(point).format("0,0") + " คะแนนขึ้นไป";
    },
    getListTier() {
      this.$store.dispatch("getListTier").then((res) => {
        console.log(res);
        this.tiers = res;
        this.tier_user = this.$store.getters.myProfile.userTier.id;
        this.tier_current = this.$store.getters.myProfile.userTier.id;
        this.tier_user_current = this.$store.getters.myProfile.userTier.name_th;
        this.$store
          .dispatch("getCampaignTierByid", this.tier_user)
          .then((campaigns) => {
            this.isLoading = false;
            console.log(campaigns.campagin);
            this.campaigns_list = campaigns.campagin;
            this.current_tab = campaigns.userTier.id;
            this.tier_loading = false;
          });
      });
    },
    showTab(id) {
      this.tier_current = id;
      this.tier_loading = true;

      this.$store
        .dispatch("getCampaignTierByid", this.tier_current)
        .then((campaigns) => {
          this.tier_loading = false;
          console.log(campaigns);
          this.campaigns_list = campaigns.campagin;
        });
    },
    checkImg(img) {
      if (img) {
        return this.url + img;
      } else {
        return "../../assets/img/Default-caurse.png";
      }
    },
    formatNum(num) {
      return numeral(num).format("0,0");
    },
    typeDuration(type) {
      if (type == "years") {
        return "ปี";
      } else if (type == "months") {
        return "เดือน";
      } else {
        return "วัน";
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    handletoListReward(val) {
      console.log(val);
      if (val) {
        return;
      } else {
        this.$store.commit("ChangePages", "rewardsList");
        this.$store.commit("ChangePageBack", "benefitPage");
      }
      // this.$store.commit("ChangePages", "rewardsList");
      // this.$store.commit("ChangePageBack", "benefitPage");
    },
  },
  mounted() {
    this.getListTier();
  },
};
</script>
<style scoped>
#benefit-page .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom-color: #00205b !important;
  border-bottom-width: 3px !important;
  border-bottom-style: solid !important;
  border-color: transparent;
  color: #00205b !important;
  font-size: 19px;
}
#benefit-page .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #000 !important;
  font-size: 19px;
}
.img_benefit {
  border-radius: 50%;
  height: 95px;
  object-fit: cover;
}
.title_campaign {
  font-size: 18px;
}
.detail_campaign {
  font-size: 16px;
}
.lock_tier {
  width: 100%;
  position: absolute;
  display: flex;
  backdrop-filter: contrast(0.3);
  padding: 40px;
  border-radius: 50%;
}
.rewards .disabled {
  pointer-events: none;
  cursor: default;
}
</style>
