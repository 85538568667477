var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dynamicPage != 'confirmAppoint' && _vm.dynamicPage != 'appointment_confirm_consult')?_c('Navbar',{attrs:{"checkPages":_vm.pageSet}}):_vm._e(),(_vm.dynamicPage == 'benefitPage')?_c('TierBenefit'):_vm._e(),(_vm.dynamicPage == 'confirmAppoint')?_c('ConfirmAppoint'):_vm._e(),(_vm.dynamicPage == 'appointment_confirm_consult')?_c('confirmEmptyAppointment'):_c('main',{staticClass:"profile-page mt-1 py-16"},[(_vm.dynamicPage != 'confirmAppoint' && _vm.dynamicPage != 'appointment_confirm_consult')?_c('ProfileTab'):_vm._e(),(_vm.dynamicPage != 'confirmAppoint' && _vm.dynamicPage != 'appointment_confirm_consult')?_c('div',{staticClass:"tab_menu mt-3"},[_c('ul',{staticClass:"nav nav-tabs nav-justified"},[_c('li',{staticClass:"nav-item"},[_c('a',{class:("nav-link " + (_vm.dynamicPage == 'default' || _vm.dynamicPage == 'detailPage'
                ? 'active'
                : '') + " "),attrs:{"href":"#tabPromotion","data-toggle":"tab"},on:{"click":function($event){return _vm.handleChageTabs('default')}}},[_c('img',{staticClass:"icon-stable",attrs:{"src":require("../assets/img/icons/shop.png"),"alt":""}}),_vm._v(" ช้อป ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:("nav-link " + (_vm.dynamicPage == 'course' ||
              _vm.dynamicPage == 'detailMyCourse' ||
              _vm.dynamicPage == 'payments'
                ? 'active'
                : '') + " "),attrs:{"href":"#tabCourses","data-toggle":"tab"},on:{"click":function($event){return _vm.handleChageTabs('course')}}},[_c('img',{staticClass:"icon-stable",attrs:{"src":require("../assets/img/icons/course.png"),"alt":""}}),_vm._v(" กระเป๋า ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:_vm.dynamicPage == 'appointList' ||
              _vm.dynamicPage == 'appointDate' ||
              _vm.dynamicPage == 'doctorList' ||
              _vm.dynamicPage == 'appointment_consult' ||
              _vm.dynamicPage == 'appointment_empty_consult'
                ? 'active'
                : '',attrs:{"href":"#tabAppoint","data-toggle":"tab"},on:{"click":function($event){return _vm.handleChageTabs('appointList')}}},[_c('img',{staticClass:"icon-stable",attrs:{"src":require("../assets/img/icons/appoint.png"),"alt":""}}),_vm._v(" นัดหมาย ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:_vm.dynamicPage == 'myProfile' ||
              _vm.dynamicPage == 'editProfile' ||
              _vm.dynamicPage == 'memberships' ||
              _vm.dynamicPage == 'history' ||
              _vm.dynamicPage == 'rewardsList' ||
              _vm.dynamicPage == 'detailReward' ||
              _vm.dynamicPage == 'detailCoupon'
                ? 'active'
                : '',attrs:{"href":"#tabProfile","data-toggle":"tab","id":"tabProfit"},on:{"click":function($event){return _vm.handleChageTabs('myProfile')}}},[_c('div',{staticClass:"profit-nav"},[_c('img',{staticClass:"profit-img",attrs:{"src":require("../assets/img/icons/icon-profit.png"),"alt":""}})])])])]),_c('div',{staticClass:"tab-content",attrs:{"id":"tabContent"}},[_c('div',{class:("tab-pane fade show  " + (_vm.dynamicPage == 'default' || _vm.dynamicPage == 'detailPage'
              ? ' active'
              : '')),attrs:{"id":"tabPromotion"}},[(_vm.dynamicPage == 'default')?_c('Promotions'):_vm._e(),(_vm.dynamicPage == 'detailPage')?_c('DetailPromotion'):_vm._e()],1),_c('div',{staticClass:"tab-pane fade show",class:("" + (_vm.dynamicPage == 'course' ||
            _vm.dynamicPage == 'detailMyCourse' ||
            _vm.dynamicPage == 'payments' ||
            _vm.dynamicPage == 'doctorList'
              ? ' active'
              : '')),attrs:{"id":"tabCourses"}},[(_vm.dynamicPage == 'course')?_c('MyCourses'):_vm._e(),(_vm.dynamicPage == 'detailMyCourse')?_c('MyCourseDetail'):_vm._e(),(_vm.dynamicPage == 'payments')?_c('Payments'):_vm._e()],1),_c('div',{staticClass:"tab-pane fade show",class:_vm.dynamicPage == 'appointList' ||
            _vm.dynamicPage == 'appointDate' ||
            _vm.dynamicPage == 'doctorList' ||
            _vm.dynamicPage == 'appointment_consult' ||
            _vm.dynamicPage == 'appointment_empty_consult'
              ? ' active'
              : '',attrs:{"id":"tabAppoint"}},[(_vm.dynamicPage == 'appointList')?_c('AppointmentList'):_vm._e(),(_vm.dynamicPage == 'appointDate')?_c('AppointDate'):_vm._e(),(_vm.dynamicPage == 'doctorList')?_c('doctor-list'):_vm._e(),(_vm.dynamicPage == 'appointment_consult')?_c('create-appoint'):_vm._e(),(_vm.dynamicPage == 'appointment_empty_consult')?_c('edit-empty-appoint'):_vm._e()],1),_c('div',{staticClass:"tab-pane fade show",class:_vm.dynamicPage == 'myProfile' ||
            _vm.dynamicPage == 'editProfile' ||
            _vm.dynamicPage == 'memberships' ||
            _vm.dynamicPage == 'history' ||
            _vm.dynamicPage == 'rewardsList' ||
            _vm.dynamicPage == 'detailReward' ||
            _vm.dynamicPage == 'detailCoupon'
              ? 'active'
              : '',attrs:{"id":"tabProfile"}},[(_vm.dynamicPage == 'myProfile')?_c('profile'):_vm._e(),(_vm.dynamicPage == 'editProfile')?_c('Infomation'):_vm._e(),(_vm.dynamicPage == 'memberships')?_c('membership'):_vm._e(),(_vm.dynamicPage == 'history')?_c('history'):_vm._e(),(_vm.dynamicPage == 'rewardsList')?_c('RewardList'):_vm._e(),(_vm.dynamicPage == 'detailReward')?_c('DetailReward'):_vm._e(),(_vm.dynamicPage == 'detailCoupon')?_c('detail-coupon'):_vm._e()],1)])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }