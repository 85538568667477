<template>
  <div>
    <div class="text-center">
      <h3>เลือกสาขา</h3>
      <h3>ที่คุณต้องการใช้บริการ</h3>
    </div>
    <div class="container">
      <div class="search_input">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="ค้นหาสาขา"
            v-model="search"
            aria-describedby="basic-addon2"
          />
          <div class="input-group-append">
            <span class="input-group-text" id="search-icon"
              ><i class="fas fa-search"></i
            ></span>
          </div>
        </div>
      </div>
      <!-- {{filterBranch}} -->
      <div v-if="filterBranch.length != 0">
              <div v-for="(val, i) in filterBranch" :key="i">
        <div @click="handleChoose(val.id)" class="card mb-3 card-branch">
          <div>
            <div class="row no-gutters">
              <div class="col-5">
                <img
                  :src="url + val.logo"
                  class="card-img logo-branch"
                  alt="..."
                />
              </div>
              <div class="col-6">
                <div class="card-body mx-1 text-truncate card-clinic">
                  <h4 class="card-title">สาขา {{ val.branches_name_th }}</h4>
                  <span class="card-text text-muted text-l">
                    ที่ตั้งร้าน {{ val.address.address }} ต.{{
                      val.address.districts_name_th
                    }}
                    <br />
                    อ.{{ val.address.amphures_name_th }} จ.{{
                      val.address.provinces_name_th
                    }}
                    {{ val.address.zip_code }}
                  </span>
                  <br />
                  <span class="card-text phontext">
                    เบอร์โทรศัพท์ : {{ val.phone_number }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div v-else class="text-center">
        <span class="text-mute">***ไม่พบข้อมูล***</span>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      items: [
        { message: "Foo", id: "8" },
        { message: "Bar", id: "8" },
        { message: "Bar", id: "8" },
      ],
      list_branch: [],
      url: this.$store.getters.url_api,
    };
  },
  methods: {
    handleChoose(id) {
      console.log(id);
      this.$router.push(`/id/${id}`);
    },
    getListBranch(id) {
      this.$store.dispatch("getBranchList", id).then((res) => {
        console.log(res.branches);
        this.list_branch = res.branches;
      });
    },
  },
  mounted() {
    console.log(this.$route);
    var branch_id = this.$route.params.id;
    this.getListBranch(branch_id);
  },
  computed: {
    filterBranch() {
      return this.list_branch.filter((branch) => {
        console.log(branch.branches_name_th.includes(this.search));
        return branch.branches_name_th.includes(this.search);
      });
    },
  },
};
</script>
<style scoped>
#search-icon {
  background-color: #0d286b;
  color: #898989;
}

.search_input input {
  font-size: 21px !important;
}
.card-branch {
  border-radius: 15px;
  box-shadow: 0 1px 11px;
  height: 140px;
}
.img-branch {
  height: 125px;
  border-radius: 15px;
}
.phontext {
  font-size: 18px;
}
.logo-branch {
  object-fit: cover;
  /* box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3); */
  border-radius: 13px;
  height: 80%;
  padding: 5%;
  margin: 3%;
}
.card-clinic {
  margin-bottom: 0.1rem;
  width: 200px;
}
</style>
