<template>
  <div class="mt-3">
    <div v-if="campaignList.length > 0">
      <b class="text-xl text-left">แคมเปญที่น่าสนใจ </b>
      <div class="horizontal-scroll">
        <div
          class="card text-center p-0 mr-2 col-6"
          v-for="(val, idx) in campaignList"
          :key="idx"
        >
          <img
            class="card-img-top-custom"
            v-if="val.campaign_picture"
            :src="url + val.campaign_picture"
            alt="..."
          />
          <img
            v-else
            src="../../assets/img/default.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title text-truncate">
              แพ็คเกจ {{ val.campaign_name_th }}
            </h5>

            <a @click="handleDetail(val)" class="mx-auto text-danger"
              >แลกสิทธิ์</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- card course-recommend  -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: this.$store.getters.url_api,
      campaignList: [],
    };
  },
  methods: {
    handleDetail(id) {
      console.log(id);
      this.$store.commit("ChangePages", "detailReward");
      this.$store.commit("ChangePageBack", "default");
      this.$store.commit("getRewardId", id);
    },
    getListCampaign() {
      this.$store.dispatch("getCampaign").then((res) => {
        console.log(res);
        this.campaignList = res;
      });
    },
  },
  mounted() {
    console.log(this.$store.getters);
    // getCampaignTierByid
    this.getListCampaign();
  },
};
</script>
<style>
.horizontal-scroll .card {
  border-radius: 13px;
}
.horizontal-scroll {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  height: 270px;
}
.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
.bar-rank {
  position: absolute;
  /* width: 170px; */
  top: 4px;
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  /* font-size: 0; */
  color: #ffffff;
  /* background-color: #4c809e; */
  border-radius: 12.5px;
}
.bar-rank p {
  position: relative;
  /* left: 175px; */
  top: 6px;
  /* width: 150px; */
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding: 10px;
}
.card-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* border-top-left-radius: 25px;
  border-top-right-radius: 25px; */
  border-radius: 13px;
}
.card-img-top-custom {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  max-height: 125px;
  width: auto;
  object-fit: contain;
}
</style>
