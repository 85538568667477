<template>
  <div class="container mt-3">
    <div v-if="!id_detail" id="courses-section" class="py-4">
      <!-- course hot -->
      <div class="mb-2">
        <div class="mb-2">
          <b class="text-xl"
            >Hot Promotion<img
              src="../../assets/img/icons/fire.png"
              style="display: inline"
          /></b>
        </div>
        <div class="card-hot-promotion" v-if="courseHot.length != 0">
          <div
            id="hotpromotion"
            class="carousel slide"
            data-ride="carousel"
            data-interval="3000"
          >
            <div class="carousel-inner">
              <div
                class="carousel-item"
                v-for="(val, idx) in courseHot"
                :key="idx"
                :class="idx == 0 ? 'active' : ''"
              >
                <a @click="handleShow(val.id)">
                  <img
                    v-if="val.hot_picture"
                    :src="url + val.hot_picture"
                    class="d-block w-100"
                    @error="imageErrorheader"
                  />
                  <img
                    v-else
                    src="../../assets/img/Default-caurse_hot.png"
                    class="d-block w-100"
                  />
                </a>
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#hotpromotion"
              role="button"
              data-slide="prev"
              v-show="courseHot.length > 1"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#hotpromotion"
              role="button"
              data-slide="next"
              v-show="courseHot.length > 1"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
            <ol class="carousel-indicators" v-show="courseHot.length > 1">
              <li
                v-for="(val, id) in courseHot"
                :key="id"
                data-target="#hotpromotion"
                :data-slide-to="id"
                :class="id == 0 ? 'active' : ''"
              ></li>
            </ol>
          </div>
        </div>
        <div class="card-hot-promotion" v-if="courseHot.length == 0">
          <img src="../../assets/img/Default_hot.jpg" class="d-block w-100" />
        </div>
      </div>
      <div v-if="courseRec.length != 0">
        <Recommend :course_recommend="courseRec" />
      </div>
      <!-- course fast -->
      <div v-if="courseFast.length != 0" class="card-Tier-deal py-3">
        <b class="text-xl"
          >ดีลด่วน จำนวนจำกัด
          <img
            src="../../assets/img/icons/limited.png"
            style="display: inline"
            alt=""
          />
        </b>
        <div class="horizontal-scroll">
          <div
            class="p-0 mt-2 mr-2 col-5"
            v-for="(fast, idx) in courseFast"
            :key="idx"
          >
            <div class="card">
              <a @click="handleShow(fast.id)">
                <div class="bar-discount">
                  <p
                    v-if="fast.discount != 0"
                    class="header-rank"
                    :style="bg_tag[0].discount.bg"
                    href="#"
                  >
                    {{ "-" + fast.discount }}
                    {{ fast.discount_type == 1 ? "฿" : "%" }}
                  </p>
                </div>

                <img
                  v-if="fast.course_picture"
                  :src="url + fast.course_picture"
                  class="card-img-promotion-fast"
                  alt="..."
                />
                <img
                  v-else
                  src="../../assets/img/default.jpg"
                  class="card-img-promotion-fast"
                  alt="..."
                />
                <div class="card-body">
                  <div>
                    <h5 class="text-left text-truncate title-courses">
                      <b>{{ fast.name_th }}</b>
                    </h5>
                    <p class="detail-truncate">
                      {{ fast.details_th }}
                    </p>
                  </div>
                  <div class="row mt-1">
                    <div class="col-5 pr-0">
                      <span style="color: #ff9900" class="px-1"
                        ><img
                          src="../../assets/img/icons/coin.png"
                          alt=""
                          style="display: inherit"
                        />{{ formatNum(fast.points) }}</span
                      >
                    </div>
                    <div class="col-7 text-right">
                      <h4>฿ {{ formatNum(fast.total) }}</h4>
                    </div>
                  </div>
                  <div
                    class="progress mt-3"
                    style="background-color: #dce2e8; height: 13px"
                  >
                    <div
                      class="progress-bar"
                      role="progressbar"
                      :style="
                        `width: ${calPer(
                          fast.min,
                          fast.max
                        )}%; background: ${checkColor(fast.min, fast.max)}`
                      "
                    >
                      <small
                        class="justify-content-center d-flex position-absolute w-100 progress-text"
                        >ขายแล้ว {{ calPer(fast.min, fast.max) }} %</small
                      >
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <campaign-list></campaign-list>
      </div>
      <!-- card all -->
      <div class="card-all">
        <div class="mb-2">
          <b class="text-xl">แพ็คเกจอื่นๆ ที่น่าสนใจ</b>
          <!-- {{courseAll}} -->
          <div v-if="courseAll.length != 0">
            <div
              class="card mb-3"
              v-for="(course, idx) in courseAll"
              :key="idx"
            >
              <a @click="handleShow(course.id)">
                <div class="row no-gutters">
                  <div class="col-5">
                    <div class="bar-discount">
                      <p
                        v-if="course.discount != 0"
                        class="header-rank"
                        :style="bg_tag[0].discount.bg"
                        href="#"
                      >
                        {{ "-" + course.discount }}
                        {{ course.discount_type == 1 ? "฿" : "%" }}
                      </p>
                    </div>
                    <img
                      v-if="course.course_picture"
                      :src="url + course.course_picture"
                      @error="imageError"
                      class="card-img-promotion-all"
                      alt="..."
                    />
                    <img
                      v-else
                      src="../../assets/img/default.jpg"
                      class="card-img-promotion-all"
                      alt="..."
                    />
                  </div>
                  <div class="col-7 pt-2">
                    <div class="card-body pt-0 pl-0 px-3">
                      <h5 class="text-left text-truncate title-courses">
                        <b>แพ็คเกจ : {{ course.name_th }}</b>
                      </h5>
                      <p class="detail-truncate">
                        รายละเอียด {{ course.details_th }}
                      </p>
                    </div>
                    <hr />
                    <div class="row px-3">
                      <div class="col mt-4">
                        <span class="text-coin"
                          ><img
                            src="../../assets/img/icons/coin.png"
                            alt=""
                            style="display: inherit"
                          />{{ formatNum(course.points) }}</span
                        >
                        คะแนน
                      </div>
                      <div class="col price-other-course text-right">
                        <div class="discount-with">
                          <span
                            v-if="course.discount != 0"
                            style="text-decoration: line-through; color: red"
                            >{{ "฿ " + formatNum(course.price) }}</span
                          >
                          <span class="text-white" v-else>ssss</span>
                        </div>
                        <h4 class="text-truncate">
                          ฿ {{ formatNum(course.total) }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div v-if="courseAll.length == 0" class="text-center">
            ไม่พบข้อมูล
          </div>
        </div>
      </div>
    </div>

    <div v-if="id_detail">
      <detail-promotion></detail-promotion>
    </div>
  </div>
</template>
<script>
import DetailPromotion from "../Promotions/DetailPromotion.vue";
import Recommend from "../Promotions/Recommend";
import numeral from "numeral";
import CampaignList from "../Membership/CampaignList.vue";
import imagebase from "../../assets/img/Default-caurse_hot.png"
export default {
  components: { DetailPromotion, Recommend, CampaignList },
  data() {
    return {
      bg_tag: [
        {
          tier_color: {
            silver: "background-color: #AAAAAA; border-radius: 12.5px;",
            gold: "background-color: #97825F; border-radius: 12.5px;",
            platinum: "background-color: #4C809E; border-radius: 12.5px;",
          },

          discount: {
            bg: "background-color: #9F1C3B; border-radius: 12.5px;",
          },
        },
      ],
      id_detail: "",
      url: this.$store.getters.url_api,
      courseAll: "",
      courseHot: "",
      courseRec: "",
      courseFast: "",
    };
  },
  methods: {
    imageErrorheader(event) {
      event.target.src = imagebase;
    },
    imageError(event) {
      event.target.src = this.url + "/app/logo_app.png";
    },
    checkId() {
      this.id_detail = "";
    },
    handleShow(id) {
      console.log(id);
      this.id_detail = id;
      this.$store.commit("ChangePages", "detailPage");
      this.$store.commit("idCourse", id);
    },
    calPer(min, max) {
      var cal = (min / max) * 100;

      return parseInt(cal);
    },
    checkColor(min, max) {
      var cal = (min / max) * 100;
      if (cal <= 25) {
        return "#17AF46";
      } else if (cal > 25 && cal <= 50) {
        return "#D6D62D";
      } else if (cal > 50 && cal <= 75) {
        return "#E07A1E";
      } else if (cal > 75 && cal <= 100) {
        return "#F21111";
      }
    },
    getCourseList() {
      // console.log()
      var branch_id = localStorage.getItem("branch");
      if(this.$route.query.branches_id){
        branch_id = this.$route.query.branches_id
      }
      this.$store.dispatch("getListCourse", branch_id).then((res) => {
        this.courseAll = res;
        console.log(this.courseAll);
      });
      this.$store.dispatch("getCoursesHot", branch_id).then((res) => {
        this.courseHot = res;
        console.log(this.courseHot);
      });
      this.$store.dispatch("getCoursesRec", branch_id).then((res) => {
        this.courseRec = res;
        console.log(this.courseRec);
      });
      this.$store.dispatch("getCoursesFast", branch_id).then((res) => {
        this.courseFast = res;
        console.log(this.courseFast);
      });
    },
    formatNum(num) {
      return numeral(num).format("0,0");
    },
  },

  mounted() {
    console.log(this);
    console.log(this.$store.getters.defaultPage);
    var branch = localStorage.getItem("branch");
    if (this.$route.query.branches_id) {
      branch = this.$route.query.branches_id;
    }
    this.token = localStorage.getItem("token_" + branch);
    if (this.token) {
      this.getCourseList();
    }
  },
  watch: {
    id_detail: function(newVal) {
      console.log(newVal);
    },
  },
};
</script>
<style scoped>
#courses-section .card {
  -webkit-box-shadow: 1px 1px 10px rgb(0 0 0 / 15%);
  border-radius: 13px;
  /* border: 1px solid; */
}
#courses-section span {
  font-size: 16px;
}
#img-course {
  background-image: url("../../assets/img/used-images.png");
  background-repeat: no-repeat;
  background-size: cover;
}
#img-book {
  background-image: url("../../assets/img/book-images.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.booking-no-used {
  color: red;
  font-size: 14px;
}
.card-used-service img {
  mix-blend-mode: luminosity;
}

.horizontal-scroll {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  height: 350px;
}
.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

.bar-rank {
  position: absolute;
  /* width: 170px; */
  top: 4px;
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  /* font-size: 0; */
  color: #ffffff;
  /* background-color: #4c809e; */
  border-radius: 12.5px;
}
.bar-rank p {
  position: relative;
  left: 175px;
  top: 6px;
  /* width: 150px; */
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding: 10px;
}
.detail-course {
  font-size: 10px;
}

/* #courses-section .card-Tier-deal .card {
  border-top-left-radius: 10%;
  border-top-right-radius: 10%;
} */
#courses-section .card-all .col {
  /* padding-top: 5px; */
  padding-right: 10px;
}
#courses-section .carousel {
  border-radius: 15px;
  overflow: hidden;
}

#courses-section .carousel .carousel-item {
  height: 250px;
}
#courses-section .carousel-item img {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
.card-img-promotion-all {
  width: 172px;
  height: 172px;
  object-fit: cover;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}
.card-img-promotion-fast {
  width: 100%;
  height: 158px;
  object-fit: cover;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}
.book-course .btn {
  /* padding: 2px; */
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 12px;
  background-color: #21326a;
  border: 1px solid #21326a;
}
.card-body {
  padding: 0.5rem;
}

.progress-text {
  font-size: 16px;
}
.card-all .col-6 {
  /* margin-bottom: 18px; */
}
.detail-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
  color: #aaaaaa;
  height: 44px;
  line-height: normal;
}

.bar-discount {
  position: absolute;
  /* width: 170px; */
  top: 4px;
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  /* font-size: 0; */
  color: #ffffff;
  /* background-color: #4c809e; */
  border-radius: 12.5px;
}
.bar-discount p {
  position: relative;
  /* left: 100px; */
  top: 3px;
  /* width: 150px; */
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding: 10px;
}
.price-other-course {
  vertical-align: top;
  display: inline-block;
  width: 120px;
}
.discount-with {
  display: block;
  margin-left: 35%;
}
.img-coin {
  display: inherit;
  vertical-align: middle;
}
.text-coin {
  font-size: 16px;
  color: #ff9900;
}
.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
/* .bg- */
</style>
