<template>
  <div class="container mx-auto px-4 h-full py-20 login-page">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4 rounded-lg border-0 p-3">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 border-0 mt-12"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="-text-center mb-3">
              <img v-if="img_logo" :src="url + img_logo" alt="" />
              <img v-else src="../../assets/img/icons/Logo.png" alt="" />
            </div>
          </div>
          <div class="flex-auto pt-0 mt-2" v-if="btnConfirmSignIn">
            <input
              class="px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-3 w-full ease-linear transition-all duration-150"
              placeholder="codenumber 123456"
              v-model="code_number"
              type="text"
              style="font-size: 18px;"
            />
            <button
              class="btn btn-main-submit text-white mb-2 form-control"
              type="button"
              :style="setTheme"
              :disabled="code_number == ''"
              @click="handleCheckCode"
            >
              {{ text_language.thai.confirm }}
            </button>
            <button
              class="btn btn-danger text-xl text-white active:bg-gray-700 uppercase px-6 py-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              type="button"
              @click="btnConfirmSignIn = false"
            >
              {{ text_language.thai.cancel }}
            </button>
          </div>
          <div class="flex-auto pt-0">
            <form>
              <div v-if="!btnConfirmSignIn" class="text-center mt-6">
                <div class="text-point text-center">
                  <h3>ลงชื่อสมัครสมาชิกเพื่อใช้ระบบ</h3>
                  <h3>SAIJAI CRM</h3>
                </div>
                <router-link :to="{ path: '/register' }">
                <a >
                  <img src="../../assets/img/btn_register.png" alt="">
                </a>
                  <!-- <button
                    :style="setTheme"
                    class="btn btn-main-submit active:bg-gray-700 px-6 py-2 form-control"
                    type="button"
                  >
                    <i class=""></i>
                    {{ text_language.thai.signup }}
                  </button> -->
                </router-link>
              </div>
              <div v-if="!btnConfirmSignIn" class="text-center mt-3 ">
                <span class="text-secondary">หากท่านลงทะเบียนแล้วให้คลิก </span>

                <a
                  class="py-2 text-xl text-point"
                  style="text-decoration: underline;"
                  @click="handleSignIn"
                >
                  {{ text_language.thai.signin }}
                </a>
                <span class="text-secondary"> เพื่อใช้งานต่อไป</span>
              </div>
            </form>
          </div>
          <a @click="clearToken">รีเซ็ตข้อมูล</a>
        </div>
      </div>
    </div>
    <!-- {{ checkToken }} -->
  </div>
</template>
<script>
export default {
  props: ["setTheme"],
  data() {
    return {
      text_language: {
        thai: {
          signin: "เข้าสู่ระบบ",
          signup: "สมัครสมาชิก",
          confirm: "ยืนยัน",
          cancel: "ยกเลิก",
        },
        eng: {
          signin: "Sign In",
          signup: "Sign Up",
          confirm: "confirm",
          cancel: "cancel",
        },
      },
      btnConfirmSignIn: false,
      code_number: "",
      url: this.$store.getters.url_api,
      img_logo: "",
    };
  },
  methods: {
    handleSignIn() {
      this.btnConfirmSignIn = true;
    },
    handleCheckCode() {
      console.log(this.code_number);
      var code_number = { tag_number: this.code_number };

      console.log(this.$router);
      this.$store.dispatch("getLoginWith", code_number).then((res) => {
        console.log(res);
        this.$router.push({ name: "dashboard" });
      });
    },
    getClinic(branches_id) {
      this.$store
        .dispatch("getClinic", branches_id)
        .then((res) => {
          console.log(res);
          this.img_logo = res[0].logo;
          this.$store.commit("getLogo", res[0].logo);
          localStorage.setItem("img_logo", res[0].logo);
          this.clinic_id = res[0].clinic_id;
          console.log(this.$store);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProfile() {
      this.$store
        .dispatch("getProfile")
        .then((res) => {
          console.log(res);
          this.$router.push({ name: "dashboard" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearToken() {
      localStorage.clear();
      location.reload();
    },
  },
  mounted() {
    var branch_id = localStorage.getItem("branch");
    this.getClinic(branch_id);
  },
};
</script>

<style>
button {
  border-radius: 15px;
}
.signin_bg_btn {
  background-color: #111827 !important;
  color: #fff !important;
  font-size: 24px !important;
}
.btn_bg_main {
  background: #21326a;
  border-radius: 15px;
  color: #fff;
}
.login-page {
  background-image: url("../../assets/img/Background.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #1d2b64 0%,
    #f8cdda 51%,
    #1d2b64 100%
  );
}
.btn-grad {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
</style>
