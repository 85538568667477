<template>
  <div class="mt-3">
    <div class="menu m-5 text-center">
      <a href="#">กลับหน้าหลัก</a>
    </div>
    <div class="container">
      <div class="card card-appoint">
        <div class="row no-gutters">
          <div class="col-5">
            <img
              class="img-fluid"
              style="object-fit: cover; height: 189px"
              src="../../assets/img/used-images.png"
            />
          </div>
          <div class="col-7">
            <div class="card-body pt-0 pl-0 mt-2">
              <h6 class="float-left pl-2">แพ็คเกจ A</h6>
              <h6 class="float-right">10 ครั้ง</h6>
              <br />
              <div class="container">
                <span
                  >รายละเอียด: <br />
                  <span class="pl-4" style="font-size: 10px"
                    >XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</span
                  >
                </span>
              </div>

              <h6 class="float-right mt-2 mb-2" style="color: #21326a">
                1 แพ็คเกจ
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center m-5">
        <span>เลือกวันนัดหมายครั้งถัดไป</span>
      </div>
      <div class="calendar-set text-center">
        <VCalendar
          is-expanded
          :min-date="new Date()"
          :attributes="attributes"
          @dayclick='dayClicked'
        />
      </div>
      <div class="container"></div>
    </div>
  </div>
</template>
<script>
import VCalendar from "v-calendar/lib/components/calendar.umd";
// import Swal from 'sweetalert2';
export default {
  data() {
    return {
      calendar: {},
      attributes: [
        {
          mode: "single",
          mode: "calendar.mode",
          dates: new Date(),
          color: "red",
          highlight: true,
          // content: 'red',
          dates: new Date(),
        },
      ],
    };
  },
  components: {
    VCalendar,
  },
  methods: {
    dayClicked(day) {
      console.log(day)
      // Swal.fire({
      //   title: day.id
      // })
    }
  }
};
</script>
<style>
.card-appoint {
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
</style>