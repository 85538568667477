<template>
  <div class="container payment-page">
    <div v-if="!confirm">
      <div class="pt-1">
        <div class="row no-gutters mt-3 mb-2">
          <div class="col-3">
            <!-- {{ user_course }}   -->
            <img
              class="img_course_payment"
              :src="url + user_course.course_picture"
              alt="..."
              v-if="user_course.course_picture"
            />
            <img
              v-else
              class="img_course_payment"
              src="../../assets/img/Default-caurse.png"
              alt="..."
            />
          </div>
          <div class="col-9 course-body">
            <div class="card-body py-0">
              <span class="text-xl text-left text-truncate title-appoint">
                แพ็คเกจ : {{ user_course.name_th }} </span
              ><br />
              <div class="text-truncate text-18">
                <b>รายละเอียด</b>
                <span class="text-secondary">
                  {{ user_course.detail_th }}
                </span>
              </div>
              <div class="flex justify-between">
                <div class="text-total-price py-1">
                  <span style="font-weight: bold"
                    >ราคารวม : ฿ {{ formatNum(user_course.total) }}</span
                  >
                </div>
                <div class="text-downpayment">
                  <h5>
                    <span style="font-weight: bold"
                      >ชำระมัดจำ : ฿
                      {{ formatNum(user_course.down_price) }}</span
                    >
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-3" />
      <div class="text-secondary">
        <span class="text-xl">บัญชีชำระเงิน:</span>
      </div>
      <div class="py-1 mb-3">
        <v-select
          style="font-size: 20px"
          v-model="select_bank"
          :options="options_bank"
          label="bank_name"
          placeholder="---เลือกช่องทางการชำระ---"
          :searchable="false"
          :clearable="false"
        >
          <template
            class="flex justify-between"
            slot="option"
            slot-scope="option"
            label="เลือกธนาคาร"
          >
            <div class="row">
              <div class="col">
                <img
                  style="height: 62px; width: 62px; object-fit: cover"
                  :src="url + option.bank_picture"
                />
              </div>
              <div class="col mt-3">
                <span class="text-left" style="font-size: 24px">
                  {{ option.bank_name }}
                </span>
              </div>
            </div>
          </template>
        </v-select>
      </div>
      <div v-if="select_bank.length != 0">
        <div id="info_payment" class="card">
          <div class="row no-gutters">
            <div class="col-3">
              <img
                class="img_banks_select"
                :src="url + select_bank.bank_picture"
                alt=""
                style=""
              />
              <!-- <img
              class="object-cover"
              width="70%"
              src="../../assets/img/bank_kbank.png"
              alt=""
            /> -->
            </div>
            <div class="col-9text-left text-xl">
              <div class="my-1 px-2 font-weight-bold">
                <span>{{ select_bank.account_name }}</span
                ><br />
                <span
                  >{{ select_bank.bank_name }}
                  {{ select_bank.account_number }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <div class="py-2 mx-4">
            <div v-if="copied" class="alert alert-success" role="alert">
              คัดลอกแล้ว
            </div>
            <button class="btn btn-main" @click="copyNumAccount">
              คัดลอกเลขบัญชี
            </button>

            <input
              type="hidden"
              name="getNumaccount"
              id="getNumaccount"
              :value="select_bank.account_number"
            />
          </div>
        </div>
        <div v-if="select_bank.type == 2">
          <div class="text-center text-secondary text-18 py-4">
            <span>ใช้แอปธนาคารสแกน QR หรือกรณีโทรศัพท์มือถือ กรุณาเลือก </span
            ><br />
            <span
              >บันทึกรูป QR ลงเครื่อง เพื่อใช้ในหน้าสแกน QR ของแอปธนาคาร</span
            >
          </div>
          <div class="-text-center border-qrcode card m-3">
            <div class="card-body">
              <img
                style="width: 65%"
                :src="url + select_bank.picture_promptpay"
                alt=""
              />
            </div>

            <div class="mt-4 card-footer" style="background: transparent">
              <img
                width="15%"
                src="../../assets/img/icons/hold_qrcode.png"
                alt=""
              />
              <span class="text-xl text-secondary"
                >กดค้างที่ Qr Code เพื่อบันทึก</span
              >
            </div>
          </div>
        </div>
        <div class="mx-3 py-5 text-xl">
          <div class="flex justify-between">
            <div>โอนเข้าบัญชี</div>
            <div class="text-right">
              {{ select_bank.account_name }}
            </div>
          </div>
          <hr class="mb-4" />
          <div class="flex justify-between">
            <div>จำนวนเงิน</div>
            <div class="text-right">
              {{ formatNum(user_course.down_price) }}
            </div>
          </div>
          <hr class="mb-4" />
          <div class="upload_payment">
            <div class="text-secondary">
              <span>อัพโหลดหลักฐานการชำระเงิน:</span>
            </div>
            <div class="text-center">
              <img
                class="-text-center"
                v-if="img_slip_show"
                :src="img_slip_show"
                alt=""
              />
              <!-- <img
            class="-text-center"
            v-else
            src="../../assets/img/bg_slip.png"
            alt=""
          /> -->
              <div class="file-upload">
                <div class="flex justify-center pt-3">
                  <button
                    type="button"
                    class="btn btn-main text-xl"
                    @click="uploadSlip"
                  >
                    อัพโหลดหลักฐานการชำระ
                  </button>
                </div>

                <!-- hidden tag file upload -->
                <div style="height: 0px; overflow: hidden">
                  <input
                    type="file"
                    id="uploadSlip"
                    name="uploadSlip"
                    accept="image/*"
                    @change="handleFile"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div v-if="btnLoading">
          <ButtonLoading />
        </div>
        <div v-else class="row">
          <div class="col-5">
            <button
              class="btn btn-danger form-control"
              @click="RedirectMyCousres"
            >
              ภายหลัง
            </button>
          </div>
          <div class="col-7">
            <button
              @click="payment()"
              :disabled="btn_booking"
              class="btn btn-main-submit form-control"
            >
              ชำระเงิน
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="confirm">
      <!-- <booking-promotion :bookingCoures="responseBooking"></booking-promotion> -->
      <div class="booking pt-3">
        <div class="img-detail-courses">
          <img class="rounded" :src="url + user_course.course_picture" alt="" />
        </div>
        <div class="p-4" style="text-align: -webkit-center">
          <img src="../../assets/img/icons/booking-success.png" alt="" />
          <div class="border-booking">
            <p style="font-size: 20px" class="font-weight-bold">
              คุณได้ทำการจอง
            </p>

            <h4 style="font-size: 30px" class="text-yeah font-weight-bold">
              แพ็คเกจ : {{ user_course.name_th }}
            </h4>
            <h4
              style="font-size: 30px; color: #40609b"
              class="font-weight-bold"
            >
              ราคา ฿ {{ formatNum(user_course.total) }}
            </h4>
            <span style="font-size: 30px" class="font-weight-bold">
              โดยชำระมัดจำ
              <span style="font-size: 36px; color: #9c0e05"
                >{{ formatNum(user_course.down_price) }} บาท</span
              >
            </span>

            <p style="font-size: 28px" class="mt-3 text-yeah font-weight-bold">
              เรียบร้อยแล้ว
            </p>
          </div>
          <div class="text-xl">
            <!-- <p>คุณจะได้รับคะนนสะสม {{ bookingCoures.points }} คะแนน</p> -->
            <!-- <p>เมื่อมีการเข้ารับบริการและชำระในครั้งแรก</p> -->
          </div>
          <div class="mt-3">
            <h5 class="text-yeah font-weight-bold">
              ทำการนัดหมายเพื่อเข้ารับบริการ
            </h5>
          </div>
        </div>
        <div class="row" id="btn_footer">
          <div class="col-5">
            <button
              class="btn btn-danger form-control"
              @click="RedirectMyCousres"
            >
              ภายหลัง
            </button>
          </div>
          <div class="col-7">
            <button
              class="btn btn-main-submit form-control"
              @click="AppointDate(id_course)"
            >
              นัดหมาย
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal-booking :data_booking="user_course" />
  </div>
</template>
<script>
import numeral from "numeral";
import axios from "axios";
import ButtonLoading from "../ButtonLoading";
import ModalBooking from "../Modal/ModalBooking.vue";

export default {
  data() {
    return {
      confirm: false,
      img_slip_show: "",
      img_slip_up: "",
      user_course: [],
      select_bank: [],
      options: [
        {
          title: "ไทยพานิช",
          cardImage: "https://promptpay.io/0655894451/0.png",
        },
        {
          title: "TMB",
          cardImage: "https://i.imgur.com/MnLYrtf.png",
        },
      ],
      options_bank: [
        {
          account_name: "เลือกธนาคาร",
        },
      ],
      url: this.$store.getters.url_api,
      copied: false,
      responseBooking: {},
      id_course: "",
      btn_booking: true,
      attributes: {
        searchable: "false",
      },
      btnLoading: false,
    };
  },
  components: {
    ButtonLoading,
    ModalBooking,
  },
  methods: {
    copyNumAccount() {
      let numaccount = document.getElementById("getNumaccount");
      numaccount.setAttribute("type", "text");
      numaccount.select();
      numaccount.setSelectionRange(0, 99999);
      console.log(numaccount);
      try {
        var successful = document.execCommand("copy");
        numaccount.setAttribute("type", "hidden");

        var msg = successful ? "successful" : "unsuccessful";
        this.copied = true;
        alert("copied : " + numaccount.value);
      } catch (err) {
        alert("Oops, unable to copy");
      }
      this.copied = false;
    },
    async downloadQR() {
      console.log(this.select_bank.picture_promptpay);
      const fileName = "qrcode.png";
      const path_img = this.url + this.select_bank.picture_promptpay;

      const response = await fetch(path_img);
      console.log(response);
      const blob = await response.blob();
      const newBlob = new Blob([blob]);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
      } else {
        // For other browsers: create a link pointing to the ObjectURL containing the blob.
        const objUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = objUrl;
        link.download = fileName;
        link.click();
        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => {
          window.URL.revokeObjectURL(objUrl);
        }, 250);
      }
    },

    uploadSlip() {
      document.getElementById("uploadSlip").click();
    },
    handleFile(e) {
      const file = e.target.files[0];
      console.log(file);
      this.img_slip_show = URL.createObjectURL(file);
      this.img_slip_up = file;
      this.btn_booking = false;
    },
    checkSlip(img) {
      if (img == "") {
        return "../../assets/img/bg_slip.png";
      } else {
        return this.img_slip_show;
      }
    },
    AppointDate(id) {
      console.log(id);
      // this.$store.commit("ChangePages", "appointDate");
      // this.$store.commit("idCourse", id);

      if (this.$store.getters.id_course.course.doctor) {
        this.$store.commit("ChangePages", "doctorList");
        this.$store.commit("idCourse", id);
      } else {
        this.$store.commit("ChangePages", "appointDate");
        this.$store.commit("idCourse", id);
      }
    },
    RedirectMyCousres() {
      var branch_id = localStorage.getItem("branch");
      this.$store.dispatch("getCourseUser", branch_id).then((res) => {
        console.log(res);
        console.log(this.$store.getters.myCourse);
        this.$store.commit("ChangePages", "course");
      });
    },
    formatNum(num) {
      return numeral(num).format("0,0");
    },
    payment() {
      // this.modalShow();

      const id =
        this.$store.getters.id_course == ""
          ? this.$route.params.course
          : this.$store.getters.id_course;
      const data = {
        slip_picture: this.img_slip_up,
        user_course_id: id,
      };
      this.btnLoading = true;
      this.$store.dispatch("uploadSlip", data).then((res) => {
        console.log(res);
        if (res.message == "success") {
          // this.confirm = true;
          this.btnLoading = false;
          this.modalShow();
        }
      });
    },
    arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = [].slice.call(new Uint8Array(buffer));

      bytes.forEach((b) => (binary += String.fromCharCode(b)));

      return window.btoa(binary);
    },
    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
      // alert(link)
    },
    downloadWithAxios(url, title) {
      axios({
        method: "get",
        url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"));
    },
    modalShow() {
      this.$root.$emit("dialogAppoint");
    },
  },
  mounted() {
    console.log(this.$store.getters.id_course);
    console.log(this.$route);
    const id =
      this.$store.getters.id_course == ""
        ? this.$route.params.course
        : this.$store.getters.id_course;
    this.id_course = id;
    this.$store.dispatch("getDetailCourseById", id).then((res) => {
      console.log(res);
      this.user_course = res.course;
      this.$store
        .dispatch("getBankList", res.course.down_price)
        // .dispatch("getBankList", "500")
        .then((banks) => {
          this.options_bank = banks.bank_account;
          console.log(this.options_bank);
        });
    });
  },
};
</script>
<style>
#info_payment {
  border: 1px solid #888888;
  padding: 10px;
  border-radius: 13px;
}
.bg_payments {
  background-image: url("../../assets/img/bg_payments.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.btn-save-qr {
  background-color: #43c506 !important;
  border-color: #43c506 !important;
  font-size: 18px !important;
  color: #fff !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.img_course_payment {
  border-radius: 13px;
  width: 93px;
  height: 95px;
  object-fit: cover;
}
.text-18 {
  font-size: 18px;
}
.text-total-price {
  color: #0a2959;
}
.text-downpayment {
  color: #db013e;
}
.course-body {
  border: 1px solid #e5e5e5;
  border-radius: 13px;
}
.img_banks_select {
  width: 70%;
  object-fit: cover;
}
#btn_footer {
  /* width: 100%;
  bottom: 3%; */
}
.border-qrcode {
  border-radius: 13px !important;
}
.vs__selected-options ::placeholder {
  color: #888888;
}
</style>
