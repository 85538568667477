<template>
  <div class="container mt-3">
    <div class="flex justify-content-center py-3">
      <!-- <button class="btn btn-main">แก้ไขข้อมูลส่วนตัว</button> -->
      <img
        class="border-none object-cover"
        style="height:170px;border-radius:50%;width:170px;box-shadow: 0 0 8px 3px #B8B8B8;"
        :src="img_show ? checkImg(img_show) : 'https://via.placeholder.com/1x1'"
        alt=""
      />
      <div v-if="setInput == false" class="camera_upload" @click="showImage">
        <i class="fas fa-camera"></i>
      </div>
    </div>
    <div class="text-center" v-if="setInput == false">
      <!-- <button style="color:#34578E" @click="showImage">
        <b>แก้ไขรูปภาพ</b>
      </button> -->
      <div v-if="sizeMax" id="text-warning-size" class="text-danger">
        ** ขนาดของรูปเกินกำหนด 10 mb **
      </div>
      <div v-else id="text-warning-size" class="text-danger">
        กรุณาอัพรูปที่ไม่เกิน 10 mb
      </div>
      <div style="height: 0px; overflow: hidden">
        <input
          type="file"
          id="uploadImg"
          name="uploadImg"
          accept=".jpg, .jpeg, .png"
          @change="onFileChange"
        />
      </div>
    </div>
    <div class="container detail_profile">
      <h4>ข้อมูลส่วนตัว</h4>
      <div v-if="setInput">
        <div class="row fullname mb-2">
          <div class="col row-full">
            <input
              type="text"
              class="form-control border-gray-300"
              disabled
              v-model="profiles.user.fname"
            />
          </div>
          <div class="col">
            <input
              type="text"
              placeholder="นามสกุล"
              disabled
              class="form-control border-gray-300 disabled"
              v-model="profiles.user.lname"
            />
          </div>
        </div>
        <input
          type="text"
          disabled
          placeholder="วันเกิด"
          class="form-control border-gray-300 disabled"
          :value="formatDate(profiles.user.birthday)"
        />
        <input
          type="number"
          disabled
          placeholder="เบอร์โทรศัพท์"
          class="form-control border-gray-300 disabled"
          v-model="profiles.user.phone"
        />
        <input
          type="email"
          disabled
          placeholder="email"
          class="form-control border-gray-300 disabled"
          v-model="profiles.user.email"
        />
        <div class="text-center">
          <button class="btn btn-main-submit form-control" @click="handleEdit">
            แก้ไขข้อมูล
          </button>
        </div>
      </div>
      <div v-else>
        <ValidationObserver
          v-slot="{ invalid }"
          ref="register-form"
          slimtag="form"
        >
          <div class="row fullname mb-2">
            <div class="col row-full">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="ชื่อ"
              >
                <input
                  type="text"
                  class="form-control border-gray-300"
                  v-model="fname"
                  placeholder="ชื่อ"
                />
                <span
                  v-if="errors"
                  class="text-danger"
                  style="font-size: 16px"
                  >{{ errors[0] }}</span
                >
              </ValidationProvider>
            </div>
            <div class="col">
              <ValidationProvider v-slot="{ errors }" name="นามสกุล">
                <input
                  type="text"
                  class="form-control border-gray-300 disabled"
                  v-model="lname"
                  placeholder="นามสกุล"
                />
                <span
                  v-if="errors"
                  class="text-danger"
                  style="font-size: 16px"
                  >{{ errors[0] }}</span
                >
              </ValidationProvider>
            </div>
          </div>

          <datepicker
            :language="th"
            :bootstrap-styling="true"
            class="mb-3 rounded mt-2"
            placeholder="วันเกิด"
            v-model="birthday"
            calendar-button-icon="fas fa-calendar-alt"
            :calendar-button="true"
          ></datepicker>
          <ValidationProvider
            v-slot="{ errors }"
            rules="length:10|max:10|required"
            name="เบอร์โทรศัพท์"
          >
            <input
              type="number"
              placeholder="เบอร์โทรศัพท์"
              class="form-control border-gray-300 disabled"
              v-model="phone"
            />
            <span v-if="errors" class="text-danger" style="font-size: 16px">{{
              errors[0]
            }}</span>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" rules="email" name="email">
            <input
              type="email"
              placeholder="email"
              class="form-control border-gray-300 disabled"
              v-model="email"
            />
            <span v-if="errors" class="text-danger" style="font-size: 16px">{{
              errors[0]
            }}</span>
          </ValidationProvider>
          <div class="text-center">
            <button
              class="btn btn-main-submit  form-control mb-2"
              @click="handleUpdate"
              :disabled="invalid"
            >
              ยืนยัน
            </button>
            <button
              class="btn btn-danger form-control text-2xl"
              @click="handleCancel"
            >
              ยกเลิก
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import { th } from "vuejs-datepicker/dist/locale";
import moment from "moment";
export default {
  data() {
    return {
      // profiles: {},
      url: this.$store.getters.url_api,
      setInput: true,
      th: th,
      img_show: "",
      img_set: "",
      img_up: "",
      fname: "",
      lname: "",
      phone: "",
      email: "",
      birthday: new Date(),
      sizeMax: false,
    };
  },
  components: { Datepicker },
  methods: {
    formatDate(date) {
      moment.locale("th");
      if (date !== null) {
      return moment(date)
              .add(543, "year")
              .format("DD MMMM YYYY");
      } else {
        return moment().add(543, "year").format("DD MMMM YYYY")
      }
     
    },
    checkImg(path) {
      console.log(path);
      if (path[0] == "/") {
        return this.url + this.img_show;
      } else {
        return this.img_show;
      }
    },
    handleEdit() {
      this.setInput = !this.setInput;
      console.log(this.setInput);
      if (this.setInput == true) {
        this.img_up = "";
        this.fname = this.profiles.fname;
        this.lname = this.profiles.lname;
        this.phone = this.profiles.phone;
        this.email = this.profiles.email;
        this.birthday = this.profiles.birthday;
      }
      $('input[type="text"]').prop("disabled", this.setInput);
    },
    handleCancel() {
      console.log(this.img_set);
      this.img_show = this.url + this.img_set;
      this.img_up = "";
      this.setInput = !this.setInput;
    },
    handleUpdate() {
      console.log(this);
      let formData = new FormData();
      formData.append("user_profile", this.img_up ? this.img_up : "");
      formData.append("fname", this.fname);
      formData.append("lname", this.lname);
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      formData.append("birthday", moment(this.birthday).format("YYYY-MM-DD"));

      this.$store.dispatch("updateProfile", formData).then((res) => {
        console.log(res);
        console.log(this.setInput);
        this.getProfile();
        $('input[type="text"]').prop("disabled", (this.setInput = true));
      });
    },
    showImage() {
      document.getElementById("uploadImg").click();
    },
    onFileChange(e) {
      console.log(e);
      const file = e.target.files[0];
      console.log(file.size / 1024);
      var max_size = 10000;
      if (file.size / 1024 > max_size) {
        this.sizeMax = true;
      } else {
        this.sizeMax = false;
        this.img_show = URL.createObjectURL(file);
        this.img_up = e.target.files[0];
      }
    },
    getProfile() {
      this.$store.dispatch("getProfile").then((res) => {
        console.log(res.data.data);
        console.log(res.data.data.user_profile);
        const data = res.data.data.user;
        console.log(data.birthday == null)
        // this.profiles = res.data.data;
        this.img_show = data.user_profile;
        this.img_set = data.user_profile;
        this.fname = data.fname;
        this.lname = data.lname;
        this.phone = data.phone;
        this.email = data.email;
        this.birthday = data.birthday == null ? new Date(): data.birthday;
      });
    },
  },
  mounted() {
    this.getProfile();
  },
  computed: {
    ...mapGetters({
      profiles: "myProfile",
    }),
  },
};
</script>
<style scoped>
.row-full {
  padding-right: 0px;
}
.fullname input[type="text"] {
  /* border-color: #211935; */
  border-radius: 13px;
  padding: 2% 10%;
  margin-bottom: 5%;
  font-size: 20px;
}
input {
  /* border-color: #211935; */
  border-radius: 13px;
  padding: 2% 4%;
  margin-bottom: 5%;
  font-size: 20px;
}
input[type="text"]:disabled {
  /* background-color: transparent; */
}
.progress {
  background: #b7b7b7;
  height: 36px;
  border-radius: 10px;
}
.progress span {
  padding: 3%;
  padding-left: 6px;
  color: #fff;
}
.level .progress-bar {
  font-size: 18px;
  border-radius: 10px;
}

.score .progress-bar {
  border-radius: 10px;
}
.score .progress-value {
  font-size: 14px;
}

.silver-point li::before {
  background: gray;
}
.camera_upload {
  background-color: black;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  height: 35px;
  margin: 0px;
  padding: 8px 10px;
  width: 35px;
  position: absolute;
  top: 430px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  right: 32%;
}
@media screen and (max-width: 375px) {
  .progress span {
    padding: 1%;
  }
}
</style>
