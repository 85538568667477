<template>
  <div id="detail-coupon">
    <div>
      <acculate-point />
      <div class="row mb-2 container">
        <div class="col-6">
          <img
            class="img-detail-reward"
            :src="data_redeem.campaign_picture ? checkImg(data_redeem.campaign_picture) : ''"
            alt=""
          />
        </div>
        <div class="col-6">
          <div class="detail_reward text-truncate">
            <h4>
              <b>{{ data_redeem.campaign_name_th }}</b>
            </h4>
            <div>
              <span
                >ประเภทแคมเปญ :
                <span class="text_used">{{
                  data_redeem.campaign_type == 1 ? "ฟรี" : "ส่วนลด"
                }}</span></span
              >
            </div>
            <div>
              <span
                >ระยะเวลาใช้งาน :
                <span class="text_used">{{ data_redeem.duration }}</span>
                {{ typeDuration(data_redeem.duration_type) }}</span
              >
            </div>
            <div class="bg-date-expired text-center mb-2">
              ใช้ถึง   {{ formatDate(data_redeem.exp_date) }}
            </div>
            <div class="bg-point text-center">
              <span class="text_used">คะแนน</span>
              <span class="text-point"> {{ data_redeem.points }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <h5><b>รายละเอียด </b></h5>
        <span>
          {{
            data_redeem.description_th !== "" ? data_redeem.description_th : "-"
          }}
        </span>
      </div>
      <div
        class="py-3"
        style="background-color: #F1F5F8;"
        v-if="data_redeem.campaign_type == 2 && data_redeem.coupon_type == 1"
      >
        <div class="container">
          <h4><b>แพ็คเกจที่ร่วมรายการ</b></h4>
          <span class="text-danger">** เลือกใช้ได้เพียง 1 แพ็คเกจเท่านั้น</span>
          <div
            v-if="data_redeem.course.length != 0"
            class="mt-3 container flex flex-wrap"
          >
            <div
              class="mb-2"
              v-for="(course, idx) in data_redeem.course"
              :key="idx"
            >
              <span class="px-3 py-1 mr-2 list_only text-truncate">{{
                course.name_th
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-3 text-center">
        <button class="btn btn-danger px-5 mr-2 form-control" @click="handleBack">
          ย้อนกลับ
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import AcculatePoint from "./AcculatePoint.vue";
import moment from "moment";
export default {
  components: { AcculatePoint },
  data() {
    return {
      redeem: false,
      phone_contact: "",
      data_redeem: "",
      url: this.$store.getters.url_api,
    };
  },
  methods: {
    handleBack() {
      this.$store.commit("ChangePages", "memberships");
      this.$store.commit("ChangePageBack", "myProfile");
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    checkImg(img) {
      console.log(img[0]);
      if (img[0] == "/") {
        console.log("true");
        return this.url + img;
      } else {
        console.log("false");

        return "../../assets/img/Default-caurse.png";
      }
    },
    typeDuration(type) {
      if (type == "years") {
        return "ปี";
      } else if (type == "months") {
        return "เดือน";
      } else {
        return "วัน";
      }
    },
    getDataReward(id) {
      this.$store.dispatch("getRewardDetail", id).then((res) => {
        console.log(res);
        this.data_redeem = res;
      });
    },
  },
  mounted() {
    console.log(this.$store.getters);
    var clinic_phone = this.$store.getters.clinic_detail.phone_number;
    var redeem = this.$store.getters.reward_id.campaign_id;
    console.log(redeem);
    this.getDataReward(redeem);

    this.phone_contact = clinic_phone;
  },
};
</script>
<style>
.detail_reward span {
  font-size: 18px !important;
}
.img-detail-reward {
  height: 165px;
  object-fit: cover;
  border-radius: 13px;
}
.list_only {
  background-color: #4c809e;
  border-radius: 13px;
  color: white;
}

</style>
