<template>
  <div class="container py-4" id="detail-reward">
    <div v-if="!redeem">
      <acculate-point />
      <div class="row mb-2 container py-4">
        <div class="col-6">
          <img
            class="img-detail-reward"
            :src="
              data_redeem.campaign_picture
                ? checkImg(data_redeem.campaign_picture)
                : ''
            "
            alt=""
          />
        </div>
        <div class="col-6">
          <div class="detail_reward text-truncate">
            <h4>
              <b>{{ data_redeem.campaign_name_th }}</b>
            </h4>
            <div>
              <span
                >ประเภทแคมเปญ :
                <span class="text_used">{{
                  data_redeem.campaign_type == 1 ? "ฟรี" : "ส่วนลด"
                }}</span></span
              >
            </div>
            <div>
              <span
                >ระยะเวลาใช้งาน :
                <span class="text_used">{{ data_redeem.duration }}</span>
                {{ typeDuration(data_redeem.duration_type) }}</span
              >
            </div>
            <div class="bg-date-expired text-center mb-2">
              ก่อน {{ formatDate(data_redeem.end_date) }}
            </div>
            <div class="bg-point text-center">
              <span class="text_used">คะแนน</span>
              <span class="text-point"> {{ data_redeem.points }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <h5><b>รายละเอียด </b></h5>
        <span>
          {{
            data_redeem.description_th !== "" ? data_redeem.description_th : "-"
          }}
        </span>
      </div>
      <div
        class="py-3"
        style="background-color: #F1F5F8;"
        v-if="data_redeem.campaign_type == 2 && data_redeem.coupon_type == 1"
      >
        <div class="container">
          <h4><b>แพ็คเกจที่ร่วมรายการ</b></h4>
          <span class="text-danger">** เลือกใช้ได้เพียง 1 แพ็คเกจเท่านั้น</span>
          <div
            v-if="data_redeem.course.length != 0"
            class="mt-3 container flex flex-wrap"
          >
            <div
              class="mb-2"
              v-for="(course, idx) in data_redeem.course"
              :key="idx"
            >
              <span class="px-3 py-1 mr-2 list_only text-truncate">{{
                course.name_th
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="py-3"
        style="background-color: rgba(2, 128, 120, 0.1);"
        v-if="data_redeem.campaign_type == 1"
      >
        <div class="container">
          <h4><b>สินค้าที่ร่วมรายการ</b></h4>
          <!-- <span class="text-danger">** เลือกใช้ได้เพียง 1 แพ็คเกจเท่านั้น</span> -->
          <div
            v-if="data_redeem.product.length != 0"
            class="mt-3 container flex flex-wrap"
          >
            <div
              class="mb-2"
              v-for="(val, idx) in data_redeem.product"
              :key="idx"
            >
              <span class="px-3 py-1 mr-2 list_only text-truncate"
                >{{ val.name_th }} {{ val.amount }} {{val.unit}}</span
              >
            </div>
          </div>
          <div
            v-if="data_redeem.service.length != 0"
            class="mt-3 container flex flex-wrap"
          >
            <div
              class="mb-2"
              v-for="(val, idx) in data_redeem.service"
              :key="idx"
            >
              <span class="px-3 py-1 mr-2 list_only text-truncate"
                >{{ val.name_th }} {{ val.amount }} {{val.unit}}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-3 text-center">
        <div v-if="btn_redeem" class="text-danger">
          ** คะแนนของคุณไม่เพียงพอ **
        </div>

        <div v-if="btnLoading">
          <button-loading />
        </div>
        <div v-else>
          <button class="btn btn-danger px-4 mr-2" @click="handleBack">
            ย้อนกลับ
          </button>
          <button
            :disabled="btn_redeem"
            class="btn btn-main-submit px-4"
            @click="handleRedeem"
          >
            แลกสิทธิ์
          </button>
        </div>
      </div>
    </div>
    <div v-if="redeem">
      <div class="container">
        <div class="py-3 text-center ">
          <b class="text-xl">คุณได้ทำการแลกสิทธิ์</b>
          <div class="coupon-redeem-free" v-if="data_redeem.campaign_type == 1">
            <div class="coupon_card_free mb-2">
              <div class="row no-gutters">
                <div class="col-4">
                  <img
                    class=""
                    :src="checkImg(data_redeem.campaign_picture)"
                    alt=""
                  />
                </div>
                <div class="col-8 ">
                  <span class="text-xl px-4"
                    >แพ็คเกจ {{ data_redeem.campaign_name_th }}</span
                  >
                  <div class="flex pl-4 detail-membership">
                    รายละเอียด
                    {{
                      data_redeem.description_th !== ""
                        ? data_redeem.description_th
                        : "-"
                    }}
                  </div>
                  <div class="pl-4 flex justify-between mt-2 pb-3">
                    <div class="expired-date">
                      <span class="bg-date-expired"
                        >ก่อน {{ formatDate(data_redeem.end_date) }}</span
                      >
                    </div>
                    <div class="bg-point text-right mr-2">
                      <span class="text_used">คะแนน</span>
                      <span class="text-point"> {{ data_redeem.points }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="coupon-redeem-course mt-3"
            v-if="data_redeem.campaign_type == 2"
          >
            <div class="coupon_card_course">
              <div class="row no-gutters">
                <div class="col-4">
                  <img
                    class=""
                    :src="checkImg(data_redeem.campaign_picture)"
                    alt=""
                  />
                </div>
                <div class="col-8 ">
                  <span class="text-xl px-4"
                    >แพ็คเกจ {{ data_redeem.campaign_name_th }}</span
                  >
                  <div class="flex pl-4 detail-membership">
                    รายละเอียด
                    {{
                      data_redeem.description_th !== ""
                        ? data_redeem.description_th
                        : "-"
                    }}
                  </div>
                  <div class="pl-4 flex justify-between mt-2 ">
                    <div class="expired-date">
                      <span class="bg-date-expired"
                        >ก่อน {{ formatDate(data_redeem.end_date) }}</span
                      >
                    </div>
                    <div class="bg-point text-right mr-2">
                      <span class="text_used">คะแนน</span>
                      <span class="text-point"> {{ data_redeem.points }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="point-redeem py-4">
            <h3>โดยคะแนน {{ data_redeem.points }}</h3>
            <div>
              <h4 class="font-weight-bold ">เรียบร้อยแล้ว</h4>
            </div>
          </div>
          <div class="text-information mt-3">
            <span style="font-size:18px;"
              >คุณสามารถเข้ารับบริการโดยใช้สิทธิ์ดังกล่าวได้ตามเงื่อนไขที่บริษัทกำหนด
              <p>หรือติดต่อผู้ให้บริการได้ที่ {{ phone_contact }}</p>
            </span>
          </div>
        </div>
        <div>
          <button
            class="btn btn-main-submit form-control"
            @click="handleSuccess"
          >
            เสร็จสิ้น
          </button>
        </div>
      </div>
    </div>
    {{ checkPointUser }}
  </div>
</template>
<script>
import AcculatePoint from "./AcculatePoint.vue";
import moment from "moment";
import ButtonLoading from "../ButtonLoading.vue";
export default {
  components: { AcculatePoint, ButtonLoading },
  data() {
    return {
      redeem: false,
      phone_contact: "",
      data_redeem: "",
      btn_redeem: false,
      url: this.$store.getters.url_api,
      getPointsUser: this.$store.getters.myProfile.user.points,
      btnLoading: false,
    };
  },
  methods: {
    handleRedeem() {
      this.btnLoading = true;
      console.log(this.data_redeem.id);
      this.$store.dispatch("redeemReward", this.data_redeem.id).then((res) => {
        this.btnLoading = false;
        this.redeem = true;
        console.log(res);
        this.$store.dispatch("getProfile");
      });
    },
    handleBack() {
      this.$store.commit("ChangePages", "rewardsList");
      this.$store.commit("ChangePageBack", "memberships");
    },
    handleSuccess() {
      this.$store.commit("ChangePages", "memberships");
      this.$store.commit("ChangePageBack", "myProfile");
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    checkImg(img) {
      console.log(img[0]);
      if (img[0] == "/") {
        console.log("true");
        return this.url + img;
      } else {
        console.log("false");

        return "../../assets/img/Default-caurse.png";
      }
    },
    typeDuration(type) {
      if (type == "years") {
        return "ปี";
      } else if (type == "months") {
        return "เดือน";
      } else {
        return "วัน";
      }
    },
    getDataReward(id) {
      this.$store.dispatch("getRewardDetail", id).then((res) => {
        console.log(res);
        this.data_redeem = res;
      });
    },
  },
  mounted() {
    console.log(this.$store.getters);
    var clinic_phone = this.$store.getters.clinic_detail.phone_number;
    var redeem = this.$store.getters.reward_id.id;
    console.log(redeem);
    this.getDataReward(redeem);

    this.phone_contact = clinic_phone;
  },
  computed: {
    checkPointUser() {
      if (this.getPointsUser < this.data_redeem.points) {
        this.btn_redeem = true;
      } else {
        this.btn_redeem = false;
      }
    },
  },
};
</script>
<style>
.detail_reward span {
  font-size: 18px !important;
}
.img-detail-reward {
  height: 165px;
  object-fit: cover;
  border-radius: 13px;
}
.list_only {
  background-color: #4c809e;
  border-radius: 13px;
  color: white;
}
</style>
