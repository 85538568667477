<template>
  <div id="app">
    <router-view :setTheme="useTheme" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      setTheme: [
        {
          name: "default",
          background: "#21326a",
          color: "#FFF",
        },
        {
          name: "second",
          background: "#ff0000",
          color: "#040205",
        },
        {
          name: "third",
          background: "#17a2b8",
          color: "#FFF",
        },
      ],
      useTheme: {
        name: "default",
        background: "#21326a",
        color: "#FFF",
      },
    };
  },
  methods: {},
  created: function () {
    const theme = { name: "default" };
    this.setTheme.filter((res) => {
      console.log(res);
      if (res.name == theme.name) {
        this.useTheme = res;
      }
    });
  },
  // beforeCreate() {
  //   // console.log(this.$liff)

  //   this.$liff.init({
  //     liffId: "1655710319-ZoY1VGkv"
  //   })
  //   .then(() => {
  //     if(this.$liff.isLoggedIn()) {
  //       this.login();
  //     }
  //   })
    
  // }
};
</script>
<style>
@font-face {
  font-family: "helvethaica";
  src: url("../public/fonts/DB Helvethaica X.ttf") format("truetype");
}
body {
  /* font-family: "Mitr", sans-serif; */
  font-family: "helvethaica", sans-serif !important;
}
</style>
