<template>
  <div class="container-fluid mt-2">
    <div class="mx-auto pt-3">
      <div class="text-center">
        <h5 v-if="branch_id == 8">แก้ไขปรึกษาแอดมิน</h5>
        <h5 v-else>แก้ไขปรึกษาผู้ให้บริการ</h5>
      </div>
      <div>
        <div class="mt-2">
          <v-date-picker
            is-expanded
            :min-date="new Date()"
            v-model="dateAppoint"
            @dayclick="handleCheckDate"
            :disabled-dates="disabledDate(dateClose, daySpecial)"
            @input="getTimeSlot"
          >
          </v-date-picker>
        </div>
        <div class="mt-2">
          <h5>เลือกเวลา</h5>
          <select
            v-if="dateAppoint"
            class="form-control"
            v-model="selectTime"
            name="select_time"
            id="select_time"
          >
            <option value="">เวลา</option>
            <option :selected="time" :value="time" v-for="(time, j) in time_list" :key="j">
              {{ time.time }}
            </option>
          </select>
          <div v-else>กรุณาเลือกวันนัดหมายก่อน</div>
        </div>
        <div class="mt-2">
          <textarea
            placeholder="ข้อความ...."
            class="form-control"
            name="text_appoint"
            id="text_appoint"
            v-model="text_appoint"
            cols="20"
            rows="5"
          ></textarea>
        </div>
        <div class="mt-2">
          <button
            class="btn btn-main-submit form-control"
            @click="handleAppointmentEmpty"
            :disabled="
              dateAppoint == '' || selectTime == '' || text_appoint == ''
            "
          >
            นัดหมาย
          </button>
          <div class="mt-2">
            <button class="btn btn-danger form-control" @click="redirectPage">
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="appointEditModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="appointEditModalModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold" id="exampleModalLabel">
              คุณได้ทำเลื่อนการนัดหมาย
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="text-align: -webkit-center">
            <img width="55" src="../../assets/img/icons/appoints.png" alt="" />
            <h4
              style="font-size: 30px"
              class="text-yeah font-weight-bold text-truncate"
            >
              {{ formatDate(dateAppoint) }}
            </h4>
            <h5
              style="font-size: 30px"
              class="text-yeah font-weight-bold text-truncate"
            >
              {{ selectTime.time }}
            </h5>
            <p
              style="font-size: 28px"
              class="mt-3 text-complete font-weight-bold"
            >
              เรียบร้อยแล้ว
            </p>
          </div>
          <div class="modal-footer">
            <!-- <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button> -->
            <button
              type="button"
              class="btn btn-main form-control"
              @click="changePage"
            >
              ตกลง
            </button>
            <!-- <button
     
              type="button"
              class="btn btn-main"
              @click="changePage"
            >
              ตกลง
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      url: this.$store.getters.url_api,
      dateClose: this.$store.getters.date_close,
      dateAppoint: "",
      weekDisabled: false,
      dateSelected: "",
      getDate: "",
      daySpecial: this.$store.getters.date_special,
      day_doctor: "",
      time_list: [],
      selectTime: "",
      text_appoint: "",
      action: "create",
      successAppoint: false,
      timeAppoint: "",
      data_appoint: "",
    };
  },
  methods: {
    handleCheckDate() {
      console.log(this.dateAppoint);
      if (this.dateAppoint) {
        this.dateSelected = moment(this.dateAppoint).format("DD/MM/YYYY");
        this.weekDisabled = false;
        const dateAppoint = moment(this.dateAppoint).format("YYYY-MM-DD");
        this.getDate = dateAppoint;
        this.$store.commit("getDateAppoint", dateAppoint);
        console.log(this.getDate);
      } else {
        this.weekDisabled = true;
        this.dateAppoint = "";
      }
    },
    disabledDate(date, day) {
      var special = [];
      const dayDoctor = [];
      const day_doctor = this.day_doctor.day;

      for (let i = 0; i < day.length; i++) {
        special.push({ start: day[i].start_date, end: day[i].end_date });
      }
      if (this.day_doctor.day) {
        day_doctor.map((val) => {
          dayDoctor.push(val.numday);
        });
      }
      special.push({ weekdays: date });
      special.push({ weekdays: dayDoctor });

      return special;
    },
    getTimeSlot() {
      console.log(this.dateAppoint);
      var date = moment(this.dateAppoint).format("YYYY-MM-DD");
      console.log(date);
      this.$store.dispatch("getTimeSlot", date).then((res) => {
        console.log(res);
        this.time_list = res;
      });
    },
    handleAppointmentEmpty() {
      console.log(this.dateAppoint);
      console.log(this.selectTime);
      console.log(this.text_appoint);
      var time_format =
        moment(this.dateAppoint).format("YYYY-MM-DD") +
        " " +
        this.selectTime.time;
      const data = {
        id: this.data_appoint.id,
        appoint_datetime: time_format,
        detail: this.text_appoint,
      };
      console.log(data);
      this.$store.dispatch("editAppointEmptyById", data).then((res) => {
        console.log(res);
        $("#appointEditModal").modal("show");
      });
    },
    ClicktoPromotions() {
      this.$store.commit("ChangePages", "default");
    },

    redirectPage() {
      console.log(this.$store.getters);
      const branch_id = localStorage.getItem("branch");
      this.$store.dispatch("getListAppointment", branch_id);
      this.$store.commit("ChangePages", "appointList");
    },
    changePage() {
      $("#appointEditModal").modal("hide");

      const branch_id = localStorage.getItem("branch");
      this.$store.dispatch("getListAppointment", branch_id);
      this.$store.commit("ChangePages", "appointList");
    },
    getDataEmptyAppoint() {
      console.log(this.$store.getters.appoint_data);
      const id = this.$store.getters.appoint_data.id;
      console.log(id);
      this.$store.dispatch("getAppointEmptyById", id).then((res) => {
        console.log(res);
        this.data_appoint = res;
        this.dateAppoint = res.appoint_datetime;
        this.text_appoint = res.detail;
        this.getTimeSlot();
      });
    },
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
  mounted() {
    this.getDataEmptyAppoint();
    console.log(this.$store.getters.appoint_data);
  },
};
</script>
<style scoped>
.btn-outline-main {
  color: #00205b;
  background-color: transparent;
  background-image: none;
  border-color: #00205b;
}
.wrapper {
  display: inline-flex;
}
.wrapper .option {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.wrapper .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
.wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #21326a;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"] {
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  border-color: #21326a;
  background: #21326a;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot {
  background: #fff;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  color: #808080;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  color: #fff;
}
</style>