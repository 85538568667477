<template>
  <div class="container mt-4" id="membership-page">
    <div class="py-2">
    <acculate-point />
    </div>
    <div class="flex justify-center py-2">
      <a @click="toRewardList()" v-if="!showList">
        <div class="flex other_reward pl-2">
          ดูรางวัลอื่นๆ ที่น่าสนใจ
          <img
            class="img-icon-plus px-2"
            src="../../assets/img/icons/icon-plus.png"
            alt=""
          />
        </div>
      </a>
    </div>
    <div class="coupon_list">
      <div class="row">
        <div class="col-8">
          <b class="text-xl">รางวัลที่ได้รับ</b>
        </div>
        <div class="col text-right text-gray-500">
          <b v-if="manyCampaign !== ''" class="text-xl">
            {{ manyCampaign }} รางวัล</b
          >
          <b v-else class="text-xl"> 0 รางวัล</b>
        </div>
      </div>
      <div v-for="(val, key) in campaigns" :key="key">
        <div
          class="coupon_card_free"
          v-if="val.campaign_type == 1 && val.used == 0 && val.exp == 0"
        >
          <a @click="handleToDetail(val)">
            <div class="row no-gutters">
              <div class="col-4">
                <img class="" :src="checkImg(val.campaign_picture)" alt="" />
              </div>
              <div class="col-8 text-truncate">
                <span class="text-xl px-3"
                  >แพ็คเกจ {{ val.campaign_name_th }}</span
                >
                <div class="flex pl-3 detail-membership">
                  รายละเอียด
                  {{ val.description_th == "" ? "-" : val.description_th }}
                  {{ val.exp_date }}
                </div>
                <div class="pl-3 flex justify-between mt-2 ">
                  <div class="expired-date">
                    <span class="bg-date-expired"
                      >ใช้ถึง {{ formatEndDate(val.exp_date) }}</span
                    >
                  </div>
                  <div class="bg-point text-right mr-2">
                    <span class="text_used">คะแนน</span>
                    <span class="text-point"> {{ val.points }}</span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          class="coupon_card_course"
          v-else-if="val.campaign_type == 2 && val.used == 0 && val.exp == 0"
        >
          <a @click="handleToDetail(val)">
            <div class="row no-gutters">
              <div class="col-4">
                <img class="" :src="checkImg(val.campaign_picture)" alt="" />
              </div>
              <div class="col-8 text-truncate">
                <span class="text-xl px-3"
                  >แพ็คเกจ {{ val.campaign_name_th }}</span
                >
                <div class="flex pl-3 detail-membership">
                  รายละเอียด
                  {{ val.description_th == "" ? "-" : val.description_th }}
                  {{ val.exp_date }}
                </div>
                <div class="pl-3 flex justify-between mt-2 ">
                  <div class="expired-date">
                    <span class="bg-date-expired"
                      >ใช้ถึง {{ formatEndDate(val.exp_date) }}</span
                    >
                  </div>
                  <div class="bg-point text-right mr-2">
                    <span class="text_used">คะแนน</span>
                    <span class="text-point"> {{ val.points }}</span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="coupon_list">
      <div class="row">
        <div class="col-8">
          <b class="text-xl">รางวัลที่ใช้แล้วและหมดอายุ</b>
        </div>
        <div class="col-4 text-right text-gray-500">
          <b v-if="count_used_campaign !== '' " class="text-xl"> {{ count_used_campaign }} รางวัล</b>
          <b v-else class="text-xl"> 0 รางวัล</b>
        </div>
      </div>
      <div v-for="(val, key) in campaigns" :key="key">
        <div
          class="coupon_card_free exp_bg"
          v-if="val.used == 1 || val.exp == 1"
        >
          <a @click="handleToDetail(val)">
            <div class="row no-gutters">
              <div class="col-4">
                <img class="" :src="checkImg(val.campaign_picture)" alt="" />
              </div>
              <div class="col-8 text-truncate">
                <span class="text-xl px-3"
                  >แพ็คเกจ {{ val.campaign_name_th }}</span
                >
                <div class="flex pl-3 detail-membership">
                  รายละเอียด
                  {{ val.description_th == "" ? "-" : val.description_th }}
                </div>
                <div class="pl-3 flex justify-between mt-2 ">
                  <div class="expired-date">
                    <span class="bg-date-expired"
                      >ใช้ถึง {{ formatEndDate(val.exp_date) }}</span
                    >
                  </div>
                  <div class="bg-point text-right mr-2">
                    <span class="text_used">คะแนน</span>
                    <span class="text-point"> {{ val.points }}</span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AcculatePoint from "./AcculatePoint.vue";
import moment from "moment";
export default {
  components: { AcculatePoint },
  data() {
    return {
      showList: false,
      manyCampaign: "",
      campaigns: [],
      count_used_campaign: "",
      coupon_free: [],
      coupon_course: [],
      url: this.$store.getters.url_api,
    };
  },
  methods: {
    toRewardList() {
      this.$store.commit("ChangePages", "rewardsList");
      this.$store.commit("ChangePageBack", "myProfile");
    },
    getMyCampaign() {
      this.$store.dispatch("getListMyCampaign").then((res) => {
        console.log(res);
        this.manyCampaign = res.length;
        this.campaigns = res;
        const redeem = [];
        const used = [];
        for (let i = 0; i < res.length; i++) {
          if (res[i].used == 1 || res[i].exp == 1) {
            console.log(res[i]);
            used.push(res[i]);
          }
          if (res[i].used == 0 && res[i].exp == 0) {
            console.log(res[i]);
            redeem.push(res[i]);
          }
        }
        console.log(res.length);
        console.log(redeem);
        this.count_used_campaign = used.length;
        this.manyCampaign = redeem.length;
      });
    },
    formatEndDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    // ../../assets/img/Default-caurse.png
    checkImg(img) {
      if (img[0] == "/") {
        return this.url + img;
      } else {
        console.log("false");

        return "../../assets/img/Default-caurse.png";
      }
    },
    handleToDetail(res) {
      this.$store.commit("ChangePages", "detailCoupon");
      this.$store.commit("ChangePageBack", "myProfile");
      this.$store.commit("getRewardId", res);
    },
  },
  mounted() {
    this.getMyCampaign();
  },
};
</script>
<style scoped>
.img-icon-plus {
  height: 15px;
  margin-top: 3px;
  margin-left: 4px;
}
.other_reward {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  color: #ff9900;
}
</style>
