<template>
  <div>
    <!-- <div>{{ data_booking }}</div> -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="appointModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="appointModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header ">
            <h5 class="modal-title font-weight-bold" id="exampleModalLabel">
              คุณได้ทำการจอง
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="text-align: -webkit-center;">
            <img
              width="55"
              src="../../assets/img/icons/booking-success.png"
              alt=""
            />
            <h4
              style="font-size:30px"
              class="text-yeah font-weight-bold text-truncate"
            >
              แพ็คเกจ : {{ data_booking.name_th }}
            </h4>
            <p
              style="font-size:28px"
              class="mt-3 text-complete font-weight-bold"
            >
              เรียบร้อยแล้ว
            </p>
          </div>
          <div class="modal-footer">
            <!-- <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button> -->
            <button
     
              type="button"
              class="btn btn-main form-control"
              @click="AppointDate(data_booking.id)"
            >
              นัดหมาย
            </button>
            <!-- <button
     
              type="button"
              class="btn btn-main"
              @click="changePage"
            >
              ตกลง
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data_booking", "day_close"],
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    AppointDate(id) {
      console.log(id);
      console.log(this.data_booking);
      const data = this.data_booking;
      console.log(data.doctor_status == 1);
      $("#appointModal").modal("hide");
      if (data.doctor_status == 1) {
        console.log("xxx");

        this.$store.commit("ChangePages", "doctorList");
        this.$store.commit("idCourse", id);
      } else {
        console.log("qqq");

        this.$store.commit("ChangePages", "appointDate");
        this.$store.commit("idCourse", id);
      }
    },
    showDialog() {
      $("#appointModal").modal("show");
    },
    changePage() {
      $("#appointModal").modal("hide");
      this.$store.commit("ChangePages", "course");
    },
  },
  mounted() {
    this.$root.$on("dialogAppoint", this.showDialog);
  },
};
</script>
