<template>
  <div class="profile-page mt-2">
    <div>
      <div class="col-12 py-2 text-center">
        <span class="profit-text">
          สิทธิพิเศษ
        </span>
        <div class="text-selected mx-auto">
          <p class="text-xl">เลือกรายการ</p>
        </div>

        <div class="list_member pr-2">
          <div @click="handleToMembership">
            <acculate-point> </acculate-point>
          </div>
          <div class="row mx-auto mt-2 flex">
            <div class="col history-card mx-2 pt-3" @click="handleToHistory">
              <div class="pt-5">
                <h4 class="text-white">ประวัติการใช้งาน</h4>
              </div>
            </div>
            <div class="col infomation-card mx-auto pt-3" @click="editProfile">
              <div class="pt-5">
                <h4 class="text-white">ข้อมูลส่วนตัว</h4>
              </div>
            </div>
          </div>
        </div>

        <!-- <button class="btn btn-main form-control" @click="editProfile">
          <i class="far fa-user-circle"></i> แก้ไขข้อมูลส่วนตัว
        </button> -->
      </div>
    </div>
  </div>
</template>
<script>
import AcculatePoint from "../Membership/AcculatePoint.vue";

export default {
  name: "profile-page",
  data() {
    return {
      url: this.$store.getters.url_api,
      isLoading: true,
    };
  },
  components: {
    AcculatePoint,
  },
  methods: {
    editProfile() {
      this.$store.commit("ChangePages", "editProfile");
      this.$store.commit("ChangePageBack", "myProfile");
    },
    handleToMembership() {
      this.$store.commit("ChangePages", "memberships");
      this.$store.commit("ChangePageBack", "myProfile");
    },
    handleToHistory() {
      this.$store.commit("ChangePages", "history");
      this.$store.commit("ChangePageBack", "myProfile");
    },
  },
};
</script>
<style scoped>
.profit-text {
  color: white;
  text-shadow: -1px -1px 0 #00205c, 1px -1px 0 #00205c, -1px 1px 0 #00205c,
    1px 1px 0 #00205c;
  /* color: transparent; */
  font-size: 35px;
}
.text-selected {
  background-image: linear-gradient(to right, #e3808c, #e566a8);
  border-radius: 13px;
  color: #fff;
  width: 35%;
  height: 6%;
  position: absolute;
  margin-left: 29% !important;
}

.history-card {
  background-image: linear-gradient(to right, #f47458, #feddbe);
  border-radius: 13px;
  height: 161px;
}
.infomation-card {
  background-image: linear-gradient(to right, #00e096, #34d1bf);
  border-radius: 13px;
  height: 161px;
}
.list_member {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset;
  border-radius: 13px;
  padding: 15% 1%;
  margin-top: 4%;
}
</style>
