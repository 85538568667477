<template>
      <div class="spinner-border text-point" role="status">
        <span class="sr-only">Loading...</span>
      </div>
</template>
<script>
export default {
    data() {
        return {}
    }
}
</script>