<template>
  <div class="container mt-3 py-4">
    <div class="detail-mycourse">
      <div class="img-detail-courses">
        <img
          style="border-radius: 13px"
          :src="url + detailCourse.course_picture"
          alt=""
        />
      </div>
      <div class="mt-2">
        <h3>แพ็คเกจ : {{ detailCourse.name_th }}</h3>
        <span v-if="detailCourse.points" style="font-size: 14px; color: #ff9900"
          ><img
            src="../../assets/img/icons/coin.png"
            alt=""
            style="display: inherit"
          />{{ detailCourse.points }} คะแนน</span
        >
      </div>
      <div v-if="detailCourse.details_th" class="detail-text">
        <b>รายละเอียด</b>
        <p>
          {{ detailCourse.details_th ? detailCourse.details_th : "-" }}
        </p>
      </div>
      <hr class="mt-3 mb-3" style="border-top: 1px solid #aaaaaa" />
      <div id="items-product">
        <div class="mt-3">
          <b>สินค้าประกอบ</b>
          <table style="width: 100%">
            <tbody>
              <tr
                v-for="(product, idp) in detailCourse.product"
                :key="idp + 'p'"
              >
                <td class="text-truncate">{{ product.name_th }}</td>
                <td class="amount-text">
                  <span class="text-success">{{ product.use }}</span> /
                  {{ product.amount }}
                </td>
                <td class="text-right">{{ product.unit }}</td>
                <td
                  v-if="product.amount != '∞'"
                  class="text-center text-success text-truncate"
                  style="font-size: 16px"
                >
                  คงเหลือ {{ product.amount - product.use }} {{ product.unit }}
                </td>
              </tr>
              <tr
                v-for="(service, ids) in detailCourse.service"
                :key="ids + 's'"
              >
                <td class="text-truncate">{{ service.name_th }}</td>
                <td class="amount-text">
                  <span class="text-success">{{ service.use }}</span> /
                  {{ service.amount }}
                </td>
                <td class="text-right">{{ service.unit }}</td>
                <td
                  v-if="service.amount != '∞'"
                  class="text-center text-success text-truncate"
                  style="font-size: 16px"
                >
                  คงเหลือ {{ service.amount - service.use }} {{ service.unit }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="price-detail mt-2">
        <table style="width: 100%">
          <tbody>
            <tr v-if="type_course == 'course'">
              <td>ราคา</td>
              <td class="text-right">฿ {{ formateNum(detailCourse.price) }}</td>
            </tr>
            <tr v-if="type_course == 'course'">
              <td>โปรโมชั่น</td>
              <td class="text-right">
                <span class="percent-discount" v-if="detailCourse.discount != 0"
                  >{{ "-" + detailCourse.discount }}
                  {{ detailCourse.discount_type == 1 ? "บาท" : "%" }}</span
                >
                <span v-else>-</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>ราคารวม</td>
              <td>
                <h3 class="text-right">
                  ฿ {{ formateNum(detailCourse.total) }}
                </h3>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="mt-4" v-if="type_course == 'mini'">
        <button
          class="btn btn-main-submit form-control mb-2"
          @click="handleDate(detailCourse.id)"
          :disabled="detailCourse.appoint == 1"
        >
          {{ detailCourse.appoint == 1 ? "อยู่ระหว่างนัดหมาย" : "นัดหมาย" }}
        </button>
      </div>

      <div class="mt-2" v-else>
        <button
          v-if="detailCourse.doctor_status == 1"
          class="btn btn-main-submit form-control"
          @click="getListDoctor(detailCourse.id)"
        >
          นัดหมาย
        </button>
        <button
          v-else
          class="btn btn-main-submit form-control mb-2"
          @click="handleDate(detailCourse.id)"
          :disabled="Object.keys(detailCourse.appointment).length > 0"
        >
          {{
            Object.keys(detailCourse.appointment).length > 0
              ? "อยู่ระหว่างนัดหมาย"
              : "นัดหมาย"
          }}
        </button>
      </div>
    </div>

    <div v-if="confirm">
      <booking-promotion :bookingCoures="detailCourse"></booking-promotion>
    </div>
  </div>
</template>
<script>
import BookingPromotion from "../Promotions/BookingPromotion";
import numeral from "numeral";
export default {
  data() {
    return {
      confirm: false,
      url: this.$store.getters.url_api,
      detailCourse: {},
      type_course: "",
    };
  },
  components: {
    BookingPromotion,
  },
  methods: {
    handleDate(id) {
      this.$store.commit("ChangePages", "appointDate");
      this.$store.commit("idCourse", id);

      // this.confirm = true;
      // this.$store.dispatch("bookingCourse", {course_id: id})
      //   .then( res => {
      //     console.log(res)
      //   })
    },
    handleAfter() {
      this.confirm = false;
    },
    formateNum(num) {
      return numeral(num).format("0,0");
    },
    getListDoctor(id) {
      this.$store.commit("ChangePages", "doctorList");
      this.$store.commit("idCourse", id);
    },
  },
  mounted() {
    console.log(this.$store.getters.id_course);
    console.log(this.$store.getters);
    const id = this.$store.getters.id_course;
    this.type_course = this.$store.getters.type_course;
    console.log(this.type_course);
    if (this.type_course == "course") {
      this.$store.dispatch("getDetailCourseById", id).then((res) => {
        console.log(res);
        this.detailCourse = res.course;
        console.log(this.detailCourse);
      });
    } else {
      this.$store.dispatch("getListMiniCourseById", id).then((res) => {
        console.log(res);
        this.detailCourse = res[0];
        console.log(this.detailCourse);
      });
    }
  },
};
</script>
<style>
.amount-text {
  color: #9d1a3a;
  text-align: left;
}
.percent-discount {
  border: 1px solid red;
  border-radius: 10px;
  color: red;
  /* padding: 1px; */
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}
.detail-text p {
  color: #aaaaaa;
  font-size: 18px;
}
.detail-text b {
  color: #aaaaaa;
  font-size: 22px;
}

.price-detail td {
  font-size: 18px;
}
.btn {
  /* padding: 2px; */
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  border-radius: 13px;
  /* font-size: 12px; */
}
.btn-main {
  background-color: #21326a;
  border: 1px solid #21326a;
  color: #fff;
}
.btn-main:active .btn-main:focus {
  background-color: #21326a;
}
</style>