<template>
  <div>
    <button class="btn btn-main-submit form-control" type="button" disabled>
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Loading...
    </button>
  </div>
</template>
<script>
export default {
    data() {
        return {}
    }
}
</script>
