<template>
  <div class="mt-3">
    <div class="float-left">
      <b class="text-xl text-left"
        >ดีลแนะนำ
        <img
          src="../../assets/img/icons/Medal.png"
          style="display: inline"
          alt=""
        />
      </b>
    </div>
    <!-- card course-recommend  -->
    <div v-if="course_recommend.length != 0" class="card-recommend-deal py-3">
      <br />
      <!-- {{course_recommend.course}} -->
      <div class="horizontal-scroll">
        <div
          class="card p-0 mt-1 mr-2 col-8"
          v-for="(recommend, idx) in course_recommend"
          :key="idx"
        >
          <div class="bar-rank">
            <p
              v-if="recommend.discount != 0"
              class="header-rank"
              :style="bg_tag[0].discount.bg"
              href="#"
            >
              {{ `-${recommend.discount}` }}
              {{ recommend.discount_type == 1 ? "฿" : "%" }}
            </p>
          </div>
          <img
            v-if="recommend.course_picture"
            :src="url + recommend.course_picture"
            class="card-img"
            alt="..."
            @click="handleDetail(recommend.id)"
          />
          <img
            v-else
            src="../../assets/img/default.jpg"
            class="card-img"
            alt="..."
            @click="handleDetail(recommend.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["course_recommend"],
  data() {
    return {
      bg_tag: [
        {
          tier_color: {
            silver: "background-color: #AAAAAA; border-radius: 12.5px;",
            gold: "background-color: #97825F; border-radius: 12.5px;",
            platinum: "background-color: #4C809E; border-radius: 12.5px;",
          },

          discount: {
            bg: "background-color: #9F1C3B; border-radius: 12.5px;",
          },
        },
      ],
      url: this.$store.getters.url_api,
    };
  },
  methods: {
    handleDetail(id) {
      console.log(id);
      this.id_detail = id;
      console.log(this);
      this.$store.commit("ChangePages", "detailPage");
      this.$store.commit("idCourse", id);
    },
  },
};
</script>
<style>
.horizontal-scroll .card {
  border-radius: 13px;
}
.horizontal-scroll {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  height: 270px;
}
.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
.bar-rank {
  position: absolute;
  /* width: 170px; */
  top: 4px;
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  /* font-size: 0; */
  color: #ffffff;
  /* background-color: #4c809e; */
  border-radius: 12.5px;
}
.bar-rank p {
  position: relative;
  /* left: 175px; */
  top: 6px;
  /* width: 150px; */
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding: 10px;
}
.card-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* border-top-left-radius: 25px;
  border-top-right-radius: 25px; */
  border-radius: 13px;
}
</style>
