<template>
  <div class="container-fluid">
    <!-- {{option_branch.branches}} -->
    <div v-if="option_branch.branches.length > 1">
      <select
        @change="handleChange"
        name="branches"
        id="branches"
        v-model="branch_id"
        class="form-control my-3"
      >
        <option
          class="lt"
          v-for="(val, idx) in option_branch.branches"
          :value="val.id"
          :key="idx"
          >สาขา: {{ val.branches_name_th }}</option
        >
      </select>
    </div>
    <div v-else class="text-center my-3 truncate-branch">
      <span class="text-branch px-2">
        สาขา:
        {{ clinic_detail.branches_name_th }}
      </span>
    </div>

    <div class="w-full mb-2">
      <div class="row" style="margin-left:-1.5px;">
        <div class="col-3" id="img-profiles-border" @click="handleProfile">
          <div>
            <div class="mt-2">
              <img
                class="rounded-full border-none img-profile object-cover"
                :src="
                  profile.user.user_profile
                    ? checkImg(profile.user.user_profile)
                    : ''
                "
                alt=""
              />
              <div
                class="text-center pt-1 text-truncate"
                style="font-size: 17px"
              >
                <span
                  >{{ profile.user.fname ? profile.user.fname : "" }}
                  {{ profile.user.lname ? profile.user.lname : "" }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-9">
          <img
            v-if="profile.userTier == ''"
            class="img-ranks"
            src="../../assets/img/ranks_img.png"
            alt=""
          />
          <div v-else>
            <a @click="handleBenefit">
              <div
                class="card"
                id="rank_member"
                :style="`background-color: ${profile.userTier.color}`"
              >
                <div class="card-body">
                  <h4 class="level_member px-2">
                    {{ profile.userTier.name_th }}
                    <i class="fas fa-crown fa-xs"></i>
                  </h4>

                  <div
                    class="progress mt-2"
                    :style="`background-color: ${profile.userTier.color}`"
                  >
                    <div
                      class="progress-bar bg-white "
                      role="progressbar"
                      :style="
                        progressWidth(
                          profile.user.points,
                          profile.user.points_use
                        )
                      "
                      aria-valuenow="25"
                      aria-valuemin="0"
                      :aria-valuemax="max_point"
                    ></div>
                  </div>
                  <span class="px-2" style="font-size: 16px"
                    >คะแนนสะสม:
                    {{
                      pointAcculate(
                        profile.user.points,
                        profile.user.points_use
                      )
                    }}</span
                  >
                </div>
                <div
                  class="card-footer"
                  :style="`background-color: ${profile.userTier.color}`"
                >
                  <div class="row flex justify-between">
                    <div class="col-8" style="padding-left:1px">
                      <span class="px-2" style="font-size: 16px">
                        View Tier Benefits</span
                      >
                    </div>
                    <div class="col-4 text-right" style="padding-right:5px">
                      <a class="" href="#"
                        ><i class="fas fa-angle-right text-white"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import numeral from "numeral";
export default {
  data() {
    return {
      background: [
        {
          bg_border: "background-color: #228B22",
          bg_progress: "background-color: #4C809E",
          bg_footer: "background-color: #228B22",
        },
      ],
      // profile: {},
      url: this.$store.getters.url_api,
      branch_id: localStorage.getItem("branch"),
      max_point: 100,
    };
  },
  methods: {
    checkImg(img) {
      if (img[0] == "/") {
        return this.url + img;
      } else {
        return img;
      }
    },
    handleProfile() {
      this.$store.commit("ChangePages", "editProfile");
      this.$store.commit("ChangePageBack", "myProfile");
    },
    progressWidth(point, point_used) {
      console.log(this.profile);
      const tier = this.profile.tier;
      const point_user = parseInt(point) + parseInt(point_used);
      console.log(tier);
      console.log(point_user);
      for (let i = 0; i < tier.length; i++) {
        console.log(tier[i].points);
        if (point_user < tier[i].points) {
          return "width:" + (point_user / tier[i].points) * 100 + "%;";
        } else if (point_user > tier[tier.length - 1].points)
          return "width: 100 %;";
      }
      // var cal_point =
      //   "width:" + (parseInt(point) + parseInt(point_used)) * 100 + "%;";
      // // "width:" +  + "%;";
      // console.log(cal_point);
      // return cal_point;
    },
    pointAcculate(point, point_used) {
      var cal_point = parseInt(point) + parseInt(point_used);
      return numeral(cal_point).format("0,0");
    },
    handleBenefit() {
      this.$store.commit("ChangePages", "benefitPage");
    },
    handleChange() {
      console.log(this.branch_id);
      this.$router.push(`/id/${this.branch_id}`);
    },
    checkBranch(branch) {
      if (branch.length > 1) {
        return true;
      }
    },
    getListBranch() {
      let id = localStorage.getItem("clinic_id");
      if(this.$route.query.clinic_id){
        id = this.$route.query.clinic_id
    }
      this.$store.dispatch("getBranchList", id);
    },
  },
  mounted() {
    // this.$store.dispatch("getProfile").then((res) => {
    //   this.profile = res.data.data;
    // });
    var branch = localStorage.getItem("branch");
    if (this.$route.query.branches_id) {
      branch = this.$route.query.branches_id;
    }
    this.token = localStorage.getItem("token_" + branch);
    if (this.token) {
      this.getListBranch();
    }
    console.log(this.$store.getters);
    mapGetters({
      profile: "myProfile",
      clinic_detail: "clinic_detail",
      option_branch: "option_branch",
    });
  },
  computed: {
    ...mapGetters({
      profile: "myProfile",
      clinic_detail: "clinic_detail",
      option_branch: "option_branch",
    }),
  },
};
</script>
<style>
#img-profiles-border {
  /* background: linear-gradient(180deg, #00205b 0%, #7286aa 100%); */
  border: 3px solid #4c809e;
  box-sizing: border-box;
  height: 101px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 13px;
  text-align: -webkit-center;
}
.img-profile {
  width: 52px;
  height: 52px;
  /* text-align: -webkit-center; */
  /* width: 98px; */
  /* height: 109px; */
}
.img-ranks {
  height: 101px;
}
#rank_member {
  background-image: url("../../assets/img/bg-ranks2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-attachment: fixed; */
  /* background-color: #32840c; */
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  color: #fff;
  border: 1px solid;
}
#rank_member .card-footer {
  padding: 0.1rem 1.25rem;
  /* background-color: #32840c; */
}
#rank_member .card-body {
  padding: 2px;
}
#rank_member .progress {
  /* background-color: #32840c; */
  height: 7px;
  background-color: #aaaaaa;
  margin-left: 8px;
}
.level_member {
  -webkit-text-stroke: 0.5px #fff;
  color: transparent;
}
.text-branch {
  font-size: 18px;
}
.truncate-branch {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #868686;
  border: 0.5px solid #868686;
  border-radius: 22px;
  padding: 4px;
}
#branches {
  text-align-last: center;
  color: #868686;
  padding: 4px;
  /* padding-left: 1px; */
  border: 0.5px solid #868686;
  font-size: 18px;
  border-radius: 22px;
}
</style>
