<template>
  <div class="booking">
    <div class="img-detail-courses">
      <img class="rounded" alt="" />
    </div>
    <div class="p-4" style="text-align: -webkit-center">
      <img
        width="30%"
        src="../../assets/img/icons/appoint_success.png"
        alt=""
      />
      <div class="border-booking">
        <div v-if="action == 'create'">
          <h5>คุณได้ทำการนัดหมาย</h5>
        </div>
        <div v-if="action == 'edit'">
          <h4>คุณได้เปลี่ยนแปลงการนัดหมาย</h4>
        </div>
        <table>
          <tbody>
            <tr class="text-success text-2xl">
              <td>แพ็คเกจ :</td>
              <td class="pl-3">{{ course.name_th }}</td>
            </tr>
            <tr v-if="action == 'edit'" style="color:#00205B" class="text-2xl">
              <td>ครั้งที่</td>
              <td class="pl-3">{{ course.appoint_no }}</td>
            </tr>
            <tr class="text-date text-2xl">
              <td>วันที่ :</td>
              <td>{{ formatDate(date) }}</td>
            </tr>
            <tr class="text-date text-2xl">
              <td>เวลา :</td>
              <td>{{ time }} น.</td>
            </tr>
          </tbody>
        </table>
        <h4 class="mt-3 text-success">เรียบร้อยแล้ว</h4>
      </div>
      <div class=" mt-3">
        <p>คุณสามารถเข้ารับบริการตามวันเวลาดังกล่าว</p>
        <p>หรือติดต่อผู้ให้บริการได้ที่ {{ phone }}</p>
      </div>
      <div class="mt-3 ">
        <h4 class="text-success">ทำการนัดหมายเพื่อเข้ารับบริการ</h4>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button
          v-if="action == 'create'"
          class="btn btn-main-submit form-control"
          @click="RedirectMyCousres"
        >
          รับทราบ
        </button>
        <button
          v-if="action == 'edit'"
          class="btn btn-main-submit form-control text-xl"
          @click="RedirectMyCousres"
        >
          เสร็จสิ้น
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: ["date", "time", "course", "action"],
  data() {
    return {
      url: this.$store.getters.url_api,
      phone: "",
    };
  },
  methods: {
    RedirectMyCousres() {
      console.log(this.$store);
      var branch_id = localStorage.getItem("branch");
      this.$store.commit("getDayDoctor", "")
      this.$store.commit("getDoctor", "")
      this.$store.dispatch("getListAppointment", branch_id);
      this.$store.commit("ChangePages", "appointList");
    },
    formatDate(date) {
      moment.locales("th");
      return moment(date)
        .add("year", 543)
        .format("LL");
    },
  },
  mounted() {
    this.phone = this.$store.getters.clinic_detail.phone_number
  },
};
</script>
<style>
.border-booking {
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 13px;
  margin-top: 25px;
}
.text-success {
  color: #43c506;
}
.text-date {
  color: #9d1a3a;
}
</style>
