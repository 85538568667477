<template>
  <div class="mt-3">
    <div class="text-point-member text-center">
      <span class="text-md">คะแนนสะสมที่ใช้ได้</span>
      <h4 class="shadow-point">{{ formatNumber(acculate) }}</h4>
    </div>
    <img src="../../assets/img/membership.png" alt="" />
    <!-- <div class="member_points mb-4">
     

      <div class="row pt-2 text-truncate">
        <div class="col-4 text-xl text-white">คะแนนสะสม</div>
        <div class="col-8 text-white">
          <h3>{{ formatNumber(acculate) }}</h3>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import numural from "numeral";
export default {
  data() {
    return {
      acculate: "",
    };
  },
  methods: {
    formatNumber(num) {
      console.log(num);
      if (num == 0) {
        return 0.0;
      } else {
        return numural(num).format("0,0");
      }
    },
  },
  mounted() {
    this.acculate = this.$store.getters.myProfile.user.points;
  },
};
</script>
<style scoped>
.text-point-member {
  color: #fff;
  /* font-size: 16px; */
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  left: 3%;
  right: 0;
  text-align: center;
}
.shadow-point {
  text-shadow: 2px 2px rgba(0, 0, 0, 0.25);
}
</style>
