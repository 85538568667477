<template>
  <div class="mycourse container mt-3">
    <div class="text-center py-4" v-if="isLoading">
      <Loading />
    </div>

    <div class="py-4" v-else>
      <div
        v-if="
          course_notUsed.length == 0 &&
            course_used.length == 0 &&
            course_mini.length == 0
        "
        style="text-align: -webkit-center"
      >
        <img src="../../assets/img/icons/no-course.png" alt="" />
        <h4 class="text-danger">
          ยังไม่มีแพ็คเกจที่ถูกจอง
          <img
            style="display: inline"
            src="../../assets/img/icons/no-course-page.png"
            alt=""
          />
        </h4>
        <button class="btn btn-outline-main mt-2" @click="ClicktoPromotions">
          เลือกแพ็คเกจที่ต้องการ
        </button>
        <Recommend
          v-if="recommenedList.length != 0"
          :course_recommend="recommenedList"
        />
      </div>
      <div v-if="course_notUsed.length != 0">
        <div class="mb-4">
          <div class="row">
            <div class="col">
              <b class="text-xl">แพ็คเกจที่ยังไม่เริ่มใช้บริการ</b>
            </div>
            <div class="col text-right text-gray-900">
              <b class="text-xl">{{ course_notUsed.length }} แพ็คเกจ</b>
            </div>
          </div>
          <div
            class="card-use-service"
            v-for="(val, idx) in course_notUsed"
            :key="idx"
          >
            <div class="card mb-2" v-if="val.sumUsed == 0 && val.status == 0">
              <div>
                <a
                  @click="
                    DetailMyCourse(
                      val.id,
                      val.down_payment,
                      val.status,
                      val.payment_status
                    )
                  "
                >
                  <div class="row no-gutters card-my-course">
                    <div class="col-5">
                      <img
                        class="img-appoint"
                        :src="url + val.course_picture"
                      />
                    </div>
                    <div class="col-7">
                      <div class="card-body p-0">
                        <h5 class="pl-2 text-truncate">
                          แพ็คเกจ {{ val.name_th }}
                        </h5>
                        <div class="container">
                          <span class="detail-truncate" style="height: 42px"
                            >รายละเอียด:
                            {{ val.details_th }}
                          </span>
                        </div>
                        <hr />
                        <div class="ml-3 pt-2">
                          <span class="text-coin"
                            ><img
                              src="../../assets/img/icons/coin.png"
                              alt=""
                              class="img-coin"
                            />{{ formatNum(val.points) }}</span
                          >
                          คะแนน
                          <div class="row">
                            <div class="col-6">
                              <small
                                class="discount-app"
                                v-if="val.discount != 0"
                                >{{ formatNum(val.discount) }}
                                {{ val.discount_type == 1 ? "บาท" : "%" }}
                              </small>
                              <small v-else style="color: white" class="mt-3"
                                >0</small
                              >
                              <h3 class="text-truncate">
                                ฿ {{ formatNum(val.total) }}
                              </h3>
                            </div>
                            <div
                              class="col-6 mt-4 wait-appoint"
                              v-if="val.down_payment == 0 && val.appoint == 0"
                            >
                              <button
                                v-show="btn_appoint"
                                class="btn btn-booking"
                              >
                                นัดหมาย
                              </button>
                            </div>
                            <div
                              class="col-6 mt-4 wait-appoint"
                              v-if="val.appoint == 1 || val.payment_status == 1"
                            >
                              <button
                                v-show="btn_appoint"
                                class="btn btn-disabled"
                              >
                                รออนุมัติ
                              </button>
                            </div>
                            <div
                              class="col-6 mt-4 wait-appoint"
                              v-if="
                                val.down_payment == 1 && val.payment_status == 0
                              "
                            >
                              <button class="btn btn-wait-pay">รอชำระ</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div v-else></div>
          </div>
        </div>
      </div>
      <div
        class="mb-4"
        v-if="course_used.length != 0 || course_mini.length != 0"
      >
        <div class="row">
          <div class="col">
            <b class="text-xl">แพ็คเกจที่เริ่มใช้บริการแล้ว</b>
          </div>
          <div class="col text-right text-gray-900">
            <b v-if="course_mini.length == 0" class="text-xl"
              >{{ course_used.length }} แพ็คเกจ</b
            >
            <b v-if="course_used.length == 0" class="text-xl"
              >{{ course_mini.length }} แพ็คเกจ</b
            >
            <b
              v-if="course_mini.length > 0 && course_used.length > 0"
              class="text-xl"
              >{{ course_mini.length + course_used.length }} แพ็คเกจ</b
            >
          </div>
        </div>
        <div
          class="card-use-service"
          v-for="(val, idx) in course_used"
          :key="idx"
        >
          <div class="card mb-2" v-if="val.status != 2">
            <div>
              <a
                @click="
                  DetailMyCourse(
                    val.id,
                    val.down_payment,
                    val.status,
                    val.payment_status
                  )
                "
              >
                <div class="row no-gutters card-my-course">
                  <div class="col-5">
                    <img class="img-appoint" :src="url + val.course_picture" />
                  </div>
                  <div class="col-7">
                    <div class="card-body p-0 text-truncate">
                      <h5 class="pl-2">แพ็คเกจ {{ val.name_th }}</h5>
                      <div class="container">
                        <span class="detail-truncate" style="height: 42px"
                          >รายละเอียด:
                          {{ val.details_th ? val.details_th : "-" }}
                        </span>
                      </div>
                      <hr />
                      <div class="ml-3">
                        <span style="font-size: 15px; color: #ff9900"
                          ><img
                            src="../../assets/img/icons/coin.png"
                            alt=""
                            style="display: inherit"
                          />{{ formatNum(val.points) }}</span
                        >
                        คะแนน
                        <div class="row">
                          <div class="col-6">
                            <small class="discount-app" v-if="val.discount != 0"
                              >{{ formatNum(val.discount) }}
                              {{ val.discount_type == 1 ? "บาท" : "%" }}
                            </small>
                            <small v-else style="color: white" class="mt-3"
                              >0</small
                            >
                            <h3 class="text-truncate">
                              ฿ {{ formatNum(val.total) }}
                            </h3>
                          </div>

                          <div
                            class="col-6 mt-4 wait-appoint"
                            v-if="
                              val.appoint == 0 &&
                                val.status == 1 &&
                                val.sumUsed == 0
                            "
                          >
                            <button
                              v-show="btn_appoint"
                              class="btn btn-booking"
                            >
                              นัดหมาย
                            </button>
                          </div>
                          <div
                            class="col-6 mt-4 wait-appoint"
                            v-else-if="
                              val.sumUsed >= 0 &&
                                val.status == 1 &&
                                val.appoint == 1
                            "
                          >
                            <button
                              v-show="btn_appoint"
                              class="btn btn-disabled"
                            >
                              เริ่มใช้บริการ
                            </button>
                          </div>
                          <div
                            class="col-6 mt-4 wait-appoint"
                            v-else-if="
                              val.sumUsed > 0 &&
                                val.status == 1 &&
                                val.appoint == 0
                            "
                          >
                            <button
                              v-show="btn_appoint"
                              class="btn btn-booking"
                            >
                              นัดหมายครั้งถัดไป
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div v-else></div>
        </div>
        <div
          class="card-use-service"
          v-for="(val, idx) in course_mini"
          :key="idx + 'm'"
        >
          <div class="card mb-2">
            <div>
              <a @click="DetailMiniCourse(val)">
                <div class="row no-gutters card-my-course">
                  <div class="col-5">
                    <img class="img-appoint" :src="url + val.course_picture" />
                  </div>
                  <div class="col-7">
                    <div class="card-body p-0 text-truncate">
                      <h5 class="pl-2">แพ็คเกจ {{ val.name_th }}</h5>
                      <div class="container">
                        <span class="detail-truncate" style="height: 42px"
                          >รายละเอียด:
                          {{ val.details_th ? val.details_th : "-" }}
                        </span>
                      </div>
                      <hr />
                      <div>
                        <div class="container">
                          <div class="my-5 flex justify-content-between">
                            <div>
                              <h3 class="text-truncate">
                                ฿ {{ formatNum(val.total) }}
                              </h3>
                            </div>
                            <div>
                              <button
                                v-if="val.appoint == 0"
                                class="btn btn-booking"
                                @click="DetailMiniCourse(val)"
                              >
                                นัดหมาย
                              </button>
                              <button
                                v-if="val.appoint == 1"
                                class="btn btn-disabled"
                                @click="DetailMiniCourse(val)"
                              >
                                นัดหมายแล้ว
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Recommend from "../Promotions/Recommend";
import Loading from "../Loading";
import numeral from "numeral";
export default {
  data() {
    return {
      url: this.$store.getters.url_api,
      recommenedList: "",
      course_used: [],
      course_notUsed: [],
      course_mini: [],
      myCourseList: {},
      isLoading: false,
      btn_appoint: true,
    };
  },
  components: {
    Recommend,
    Loading,
  },
  methods: {
    ClicktoPromotions() {
      this.$store.commit("ChangePages", "default");
    },
    DetailMyCourse(id, type, status, payment) {
      console.log(id);
      console.log(this.btn_appoint);
      this.id_detail = id;
      if (this.btn_appoint) {
        if (type == 1) {
          if (status == 0 && payment == 1) {
            this.$store.commit("ChangePages", "detailMyCourse");
            this.$store.commit("idCourse", id);
            this.$store.commit("getTypeCourse", "course");
          } else if (status == 1 && payment == 1) {
            this.$store.commit("ChangePages", "detailMyCourse");
            this.$store.commit("idCourse", id);
            this.$store.commit("getTypeCourse", "course");
          } else {
            this.$store.dispatch("getProfile");
            this.$store.commit("ChangePages", "payments");
            this.$store.commit("idCourse", id);
          }
        } else {
          this.$store.commit("ChangePages", "detailMyCourse");
          this.$store.commit("idCourse", id);
          this.$store.commit("getTypeCourse", "course");
        }
      } else {
        return;
      }
    },
    DetailMiniCourse(data) {
      console.log(data);
      this.$store.commit("ChangePages", "detailMyCourse");
      this.$store.commit("idCourse", data.id);
      this.$store.commit("getTypeCourse", "mini");
    },
    getMyCourse() {
      this.isLoading = true;
      var branch_id = localStorage.getItem("branch");
      this.$store.dispatch("getCourseUser", branch_id).then((res) => {
        console.log(res);

        this.myCourseList = this.$store.getters.myCourse
          ? this.$store.getters.myCourse
          : res;

        this.isLoading = false;
        console.log(this.myCourseList);
        var array_minicourse = "";
        this.$store.dispatch("getListMiniCourse").then((mini) => {
          console.log(mini);
          array_minicourse = mini;
          array_minicourse.map((val) => {
            console.log(val);
            if (val.status == 1) {
              this.course_mini.push(val);
            }
          });
        });
        const length = res.course.length;
        if (Object.keys(this.myCourseList.course).length != 0) {
          for (let i = 0; i < length; i++) {
            if (this.myCourseList.course[i].status != 2) {
              if (this.myCourseList.course[i].status == 0) {
                this.course_notUsed.push(this.myCourseList.course[i]);
              }
              if (this.myCourseList.course[i].status == 1) {
                this.course_used.push(this.myCourseList.course[i]);
              }
            }
          }
        }
      });
    },
    formatNum(res) {
      return numeral(res).format("0,0");
    },
    getDayClose() {
      const day = this.$store.getters.date_close;
      console.log(day.length);
      if (day.length == 7) {
        this.btn_appoint = false;
      }
    },
    getCourseRec() {
      var branch_id = localStorage.getItem("branch");
      this.$store.dispatch("getCoursesRec", branch_id).then((res) => {
        console.log(res);
        this.recommenedList = res;
      });
    },
  },
  mounted() {
    // console.log(this.myCourseList)
    console.log(this.$route);
    console.log(this.$route.params.id);
    this.getDayClose();
    this.getMyCourse();
    this.getCourseRec();
  },

  // computed: {
  //   recommenedList() {
  //     console.log(this.$store.getters.course);
  //     return this.$store.getters.course.recommened;
  //   },
  // },
};
</script>
<style scoped>
.history-table th {
  font-size: 13px;
}
.history-table a {
  color: #85a4d2;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  border-bottom: 1px solid #85a4d2;
}
.btn-outline-main {
  color: #00205b;
  background-color: transparent;
  background-image: none;
  border-color: #00205b;
}
.detail-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
  color: #aaaaaa;
  height: 47px;
  line-height: normal;
}
.img-book-service {
  object-fit: cover;
  height: auto;
  border-radius: 13px;
}
.card-use-service .btn {
  padding: 2px 8px;
}
.card-use-service .card {
  border-radius: 13px;
}
.card-use-service img {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}
.btn-disabled {
  background-color: #aaaaaa;
  border: 1px solid #aaaaaa;
  color: #fff;
  width: 102px;
}
.wait-appoint {
  margin: -12%;
}
.discount-app {
  text-decoration: line-through;
  color: red;
  font-size: 16px;
}
.img-appoint {
  width: 168px;
  height: 168px;
  object-fit: cover;
}
.btn-booking {
  background-color: #21326a !important;
  border: 1px solid #21326a !important;
  border: 2px solid #00205c;
  color: #fff !important;
  width: 102px;
}
.btn-wait-pay {
  background-color: #ffc107 !important;
  border: 1px solid #ffc107 !important;
  border: 2px solid #ffc107;
  color: #fff !important;
  width: 102px;
}
</style>
