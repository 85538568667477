<template>
  <div>
    <div class="card mb-3 card-doctor" @click="handleClick(data)">
      <div class="row no-gutters">
        <div class="col-4">
          <img
            style="object-fit:cover;"
            class="p-3"
            :src="url + data.doctor_picture"
            alt=""
          />
        </div>
        <div class="col-8 doctor-require">
          <h5>{{ data.prefix_name}} {{ data.fname }} {{ data.lname}}</h5>
          <div>
            <i
              class="fas fa-star mr-1"
              style="font-size:11px;color:#FEAD7F"
            ></i>
            <span
              v-for="(expertise, idx) in data.expertise"
              :key="idx"
              class="mr-2 text-truncate expertises"
              >{{ expertise.expertise_th }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      url: this.$store.getters.url_api,
    };
  },
  methods: {
    handleClick(data) {
      console.log(data.id);
      this.$store.commit("getDoctor", data)
      this.$store.dispatch("getDayDoctor", data.id).then((res) => {
        this.$store.commit("ChangePages", "appointDate");
        console.log(res)
        console.log(this.$store.getters.date_doctor);
        // this.$store.commit("idCourse", id);
      });
    },
  },
};
</script>
<style>
.expertises {
  border: 1px solid #aaaaaa;
  border-radius: 13px;
  padding: 0px 5px;
}
</style>
