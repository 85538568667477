import state from "./state";

export default {
    authorized: state => !!state.token,
    authstatus: state => state.authStatus,
    defaultPage: state => state.page_current,
    userData: state => state.user,
    course: state => state.course,
    id_course: state => state.id_course,
    myCourse: state => state.myCourse,
    token: state => state.token,
    tokenLine: state => state.tokenLine,
    clinic_detail: state => state.clinic_detail,
    myProfile: state => state.myProfile,
    appointment_list: state => state.appointment_list,
    appoint_data: state => state.appoint_data,
    getClinic: state => state.getClinic,
    url_api: state => state.url,
    page_history: state => state.page_history,
    course_booking: state => state.course_booking,
    reward_id: state => state.reward_id,
    date_appoint: state => state.date_appoint,
    date_close: state => state.date_close,
    date_special: state => state.date_special,
    option_branch: state => state.option_branch,
    date_doctor: state => state.date_doctor,
    doctor: state => state.doctor,
    appoint_empty_list: state => state.appoint_empty_list,
    type_course: state => state.type_course,
}