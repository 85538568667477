import Vue from "vue";
import Router from "vue-router";
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import Dashboard from "../views/Dashboard.vue";
import CourseAppoint from "../components/Courses/CourseAppoint";
import Index from "../views/Index.vue";
import Payments from "../components/MyCourse/Payments";
import Promotions from "../components/Promotions/Promotions";
import ChooseBranch from "../components/ChooseBranch/ChooseBranch"
import LoadingPage from "../views/LoadingPage";

Vue.use(Router);
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      // redirect: '/id/:id',
      component: LoadingPage,
    },
    {
      path: "/id/:id",
      name: "loading",
      component: LoadingPage,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/register",
      name: "register",
      component: Register,
    },
    {
      path: "/mycourse/id/:id",
      name: "mycourse_line",
      component: LoadingPage,
    },
    {
      path: "/appointment/id/:id",
      name: "appointment_line",
      component: LoadingPage,
    },
    {
      path: "/profile/id/:id",
      name: "profile_line",
      component: LoadingPage,
    },
    {
      path: "/clinic/id/:id",
      name: "chooseBranch",
      component: ChooseBranch
    },
    {
      path: '/appointLine/:id',
      name: 'appointsuccess',
      component: Dashboard
    },
    {
      path: '/appointLine/empty/:id',
      name: 'appointEmpty',
      component: Dashboard
    },
    {
      path: "/",
      component: Dashboard,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: Promotions,
        },
        {
          path: "/appoint/:id",
          name: "appoint",
          component: CourseAppoint,
        },
        {
          path: "/mycourse",
          name: "mycourse",
          component: Dashboard,
        },
    
        {
          path: "/appointment",
          name: "appointment",
          component: Dashboard,
        },
    
        {
          path: "/profile",
          name: "profile",
          component: Dashboard,
        },
     
        {
          path: "/payments",
          name: "payments",
          component: LoadingPage,
        },
        {
          path: "/payments/course/:course/id/:id",
          name: "payments_line",
          component: LoadingPage,
        },
        {
          path: "/courses/id/:id",
          name: "course_detail",
          component: LoadingPage
        },
        {
          path: "/appoint_empty",
          name: "appoint_empty",
          component: Dashboard
        }
      ],
    },
  ],
});

export default router;
