<template>
  <div class="container mt-3 py-3">
    <div v-if="successAppoint == false" class="detail-appoint">
      <div class="img-detail-courses">
        <img
          style="border-radius: 13px"
          :src="
            detailCourse.course_picture ? url + detailCourse.course_picture : ''
          "
          alt=""
        />
      </div>
      <div class="mt-2">
        <h4>แพ็คเกจ : {{ detailCourse.name_th }}</h4>
        <!-- <span style="font-size: 14px; color: #ff9900"
          ><img
            src="../../assets/img/icons/coin.png"
            alt=""
            style="display: inherit"
          />{{ detailCourse.points }} คะแนน</span
        > -->
      </div>
      <div class="detail-text">
        <b>รายละเอียด</b>
        <p>
          {{ detailCourse.details_th ? detailCourse.details_th : "-" }}
        </p>
      </div>
      <div v-if="typeof data_doctor == 'object'">
        <doctor-card :data="data_doctor" />
      </div>
      <div class="text-center">
        <h3>วันที่เลือก : {{ dateSelected }}</h3>
      </div>
      <!-- calendar -->
      <div v-if="chooseDate" class="calendar_appoint">
        <v-date-picker
          is-expanded
          :min-date="new Date()"
          v-model="dateAppoint"
          @dayclick="handleCheckDate"
          :disabled-dates="disabledDate(dateClose, daySpecial)"
        >
        </v-date-picker>
        <div class="mt-4">
          <div v-if="btnLoading">
            <button-loading />
          </div>
          <div v-else>
            <button
              class="btn btn-main-submit form-control mb-2"
              @click="handleDate"
              :disabled="dateAppoint == ''"
            >
              เลือกเวลา
            </button>
          </div>
        </div>
      </div>
      <div v-if="chooseTime" class="time_appoint">
        <div class="container px-0">
          <div v-if="loadingTimeSlot" class="text-center">
            <loading />
          </div>
          <div class="row" data-toggle="buttons">
            <div
              class="col-4 px-1"
              v-for="(times, idx) in list_times"
              :key="idx"
            >
              <label
                :for="times.time"
                :class="`col-12 mb-2 btn btn-outline-time ${checkCurrentTime(
                  times.time,
                  times.status
                )}`"
                @click="handleClickTime(times.time)"
              >
                <input
                  type="radio"
                  :checked="times.time == time_appoint ? 'checked' : ''"
                  :id="times.time"
                  name="time_app"
                  v-model="timeAppoint"
                  :disabled="checkCurrentRadio(times.time, times.status)"
                />
                <span class="time-text text-2xl">{{ times.time }} น.</span>
              </label>
            </div>
          </div>
        </div>
        <div v-if="btnLoading" class="mt-4">
          <ButtonLoading />
        </div>
        <div v-else class="mt-4">
          <div class="text-center" v-if="disabledTime">
            <span class="text-danger">กรุณาลองใหม่อีกครั้ง</span>
          </div>
          <button
            class="btn btn-main-submit form-control mb-2"
            @click="confirmAppoint"
            v-if="action == 'create'"
            :disabled="timeAppoint == ''"
          >
            ยืนยันนัดหมาย
          </button>
          <button
            class="btn btn-main-submit form-control mb-2"
            @click="editAppoint"
            v-if="action == 'edit'"
            :disabled="time_appoint == ''"
          >
            ยืนยันนัดหมาย
          </button>
          <button class="btn btn-danger form-control" @click="backToDate">
            ย้อนกลับ
          </button>
        </div>
      </div>
    </div>

    <div v-if="successAppoint">
      <AppointSuccess
        :date="getDate"
        :time="timeAppoint"
        :course="detailCourse"
        :action="action"
      />
    </div>
  </div>
</template>
<script>
import AppointSuccess from "../Appointment/AppointSuccess";
import moment from "moment";
import ButtonLoading from "../ButtonLoading";
import Loading from "../Loading";
import DoctorCard from "../Doctors/DoctorCard.vue";

export default {
  data() {
    return {
      confirm: false,
      url: this.$store.getters.url_api,
      detailCourse: {},
      dateAppoint: "",
      getDate: "",
      id_course: "",
      list_times: [],
      chooseDate: true,
      chooseTime: false,
      timeAppoint: "",
      successAppoint: false,
      action: "",
      full_date: "",
      time_appoint: "",
      disabledTime: false,
      btnLoading: false,
      dateClose: this.$store.getters.date_close,
      weekDisabled: false,
      isDisabled: false,
      dateSelected: "",
      daySpecial: this.$store.getters.date_special,
      loadingTimeSlot: false,
      data_doctor: "",
      day_doctor: "",
    };
  },
  components: {
    AppointSuccess,
    ButtonLoading,
    Loading,
    DoctorCard,
  },
  methods: {
    handleDate() {
      var date = moment(this.getDate).format("YYYY-MM-DD");
      console.log(date);
      console.log(this.data_doctor);

      if (this.data_doctor.doctor_id) {
        const data = {
          id: this.data_doctor.doctor_id,
          date: date,
        };
        this.$store.dispatch("getTimeSlotDoctor", data).then((res) => {
          console.log(res);
          this.list_times = res;
          this.chooseDate = false;
          this.chooseTime = true;
        });
      } else {
        this.$store.dispatch("getTimeSlot", date).then((res) => {
          console.log(res);
          this.list_times = res;
          this.chooseDate = false;
          this.chooseTime = true;
        });
      }
    },
    handleAfter() {
      this.confirm = false;
    },
    handleCheckDate() {
      console.log(this.dateAppoint);
      if (this.dateAppoint) {
        this.dateSelected = moment(this.dateAppoint).format("DD/MM/YYYY");
        this.weekDisabled = false;
        const dateAppoint = moment(this.dateAppoint).format("YYYY-MM-DD");
        this.getDate = dateAppoint;
        this.$store.commit("getDateAppoint", dateAppoint);
        console.log(this.getDate);
      } else {
        this.weekDisabled = true;
        this.dateAppoint = "";
      }
    },
    getDetailCourse(id) {
      console.log(id);
      const type_course = this.$store.getters.type_course;
      if (type_course == "course") {
        this.$store.dispatch("getDetailCourseById", id).then((res) => {
          var getDoctorCourse = this.$store.getters.doctor;
          this.day_doctor = this.$store.getters.date_doctor;
          this.detailCourse = res.course;
          console.log(res);
          console.log(getDoctorCourse.length != 0);
          if (res.course.doctor_status == 1) {
            this.data_doctor = this.$store.getters.doctor;
            this.day_doctor = this.$store.getters.date_doctor;
          }
        });
      } else {
        this.$store.dispatch("getListMiniCourseById", id).then((res) => {
          console.log(res)
          var getDoctorCourse = this.$store.getters.doctor;
          this.day_doctor = this.$store.getters.date_doctor;
          this.detailCourse = res[0];
          console.log(res);
          console.log(getDoctorCourse.length != 0);
          if (res.course.doctor_status == 1) {
            this.data_doctor = this.$store.getters.doctor;
            this.day_doctor = this.$store.getters.date_doctor;
          }
        });
      }
    },
    handleClickTime(time) {
      console.log(time);
      this.timeAppoint = time;
      this.time_appoint = time;
    },
    confirmAppoint() {
      this.btnLoading = true;
      const type_course = this.$store.getters.type_course;
      console.log(this.$store.getters.doctor)
      const id_doctor = this.$store.getters.doctor.id
      var fulldate = {
        id: this.id_course,
        appoint_type: type_course == "course" ? "course" : "mini",
        date:
          moment(this.getDate).format("YYYY-MM-DD") + " " + this.timeAppoint,
          doctor_id: id_doctor
      };
      console.log(fulldate);
      this.$store.dispatch("createAppointment", fulldate).then((res) => {
        console.log(res);
        if (res.message == "success") {
          this.btnLoading = false;
          this.full_date = fulldate;
          this.successAppoint = true;
          this.disabledTime = false;
        } else {
          this.disabledTime = true;
        }
      });
    },
    getDataByAppoint(data) {
      console.log(data);
      this.id_course = data.id;
      this.loadingTimeSlot = true;
      this.$store.dispatch("getDataAppointment", data.id).then((res) => {
        console.log(res.admin);
        this.detailCourse = res.admin;
        this.loadingTimeSlot = false;
        if (res.admin.doctor_status == 1) {
          const id = res.admin.doctor.doctor_id;
          console.log(id);
          if (id) {
            this.getDateDoctor(id);
            this.getDoctor(id);
          }
        }
        console.log(this.dateAppoint);
        var split_time = res.admin.appoint_datetime
          ? res.admin.appoint_datetime.split(" ")
          : "";
        this.dateAppoint = split_time[0];
        this.timeAppoint = split_time[1];
        this.time_appoint = "";
      });
    },
    editAppoint() {
      console.log(this.getDate);
      console.log(this.timeAppoint);
      var data_update = {
        id: this.id_course,
        date:
          moment(this.getDate).format("YYYY-MM-DD") + " " + this.timeAppoint,
      };
      this.$store.dispatch("updateAppointment", data_update).then((res) => {
        console.log(res);
        this.successAppoint = true;
      });
    },
    checkAppoint() {
      const id = this.$store.getters.id_course;
      const data_appoint = this.$store.getters.appoint_data;
      this.id_course = id;
      console.log(id);
      if (id) {
        this.action = "create";
        this.getDetailCourse(id);
      }
      if (id == 0) {
        this.action = "edit";
        this.getDataByAppoint(data_appoint);
      }
    },
    checkCurrentTime(time, status) {
      const timeCurrent = moment().format("HH:mm");
      const dayCurrent = moment();
      const dateAppoint = moment(this.dateAppoint);
      // console.log(time);
      // console.log(status);
      // console.log(dateAppoint.diff(dayCurrent, "days"));
      // console.log(moment.duration(dayCurrent.diff(dateAppoint)));
      var duration = moment.duration(dayCurrent.diff(dateAppoint));
      var days = duration.asDays();
      // console.log(days);
      // console.log(days < 0);
      if (status == 0) {
        return "disabled";
      }
      if (days > 0) {
        console.log("inDay");
        if (status == 0) {
          return "disabled";
        }
        if (time < timeCurrent) {
          return "disabled";
        } else if (time == this.time_appoint) {
          return "active";
        } else {
          return "";
        }
      } else {
        if (status == 0) {
          return "disabled";
        }
        if (time == this.timeAppoint) {
          return "active";
        } else {
          return "";
        }
      }
    },
    checkCurrentRadio(time, status) {
      const timeCurrent = moment().format("HH:mm");
      const dayCurrent = moment();

      if (dayCurrent.diff(timeCurrent, "days") == 0) {
        if (time < timeCurrent) {
          return time < timeCurrent;
        }
      }
    },
    backToDate() {
      this.chooseDate = true;
      this.chooseTime = false;
    },
    disabledDate(date, day) {
      console.log(date);
      console.log(day);
      var special = [];
      const dayDoctor = [];
      const day_doctor = this.day_doctor.day;

      for (let i = 0; i < day.length; i++) {
        special.push({ start: day[i].start_date, end: day[i].end_date });
      }
      if (this.day_doctor.day) {
        day_doctor.map((val) => {
          console.log(val);
          dayDoctor.push(val.numday);
        });
        console.log(dayDoctor);
      }
      special.push({ weekdays: date });
      special.push({ weekdays: dayDoctor });
      console.log(special);
      const start = day.start_date;
      const end = day.end_date;
      return special;
    },
    disabledButton() {
      // this.weekDisabled = true;

      console.log(moment(this.dateAppoint).isoWeekday() + 1);

      const weekCurrent = moment(this.dateAppoint).isoWeekday() + 1;
      this.dateClose.map((res) => {
        console.log(res);
        console.log(res == weekCurrent);
        if (res == weekCurrent) {
          this.weekDisabled = true;
          console.log(weekCurrent);
        }
      });
    },
    getDateDoctor(doctor_id) {
      this.$store.dispatch("getDayDoctor", doctor_id).then((res) => {
        console.log(res);
        this.day_doctor = res;
      });
    },
    getDoctor(doctor_id) {
      this.$store.dispatch("getDoctor", doctor_id).then((res) => {
        console.log(res);
        this.data_doctor = res;
      });
    },
  },
  mounted() {
    console.log(this.$store.getters);
    this.checkAppoint();
    this.disabledButton();

    const container = this.$el.querySelector(".detail-appoint");
    console.log(container.scrollHeight);
    window.scrollTo(0, container.scrollHeight);
  },
};
</script>
<style>
.amount-text {
  color: #9d1a3a;
  text-align: left;
}
.percent-discount {
  border: 1px solid red;
  border-radius: 10px;
  color: red;
  /* padding: 1px; */
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}
.detail-promotion td {
  font-size: 18px;
}

.btn {
  /* padding: 2px; */
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  border-radius: 13px;
  /* font-size: 12px; */
}

.btn-outline-time {
  color: #00205b;
  border-color: #00205b !important;
  padding-left: 15px;
  padding-right: 15px;
}
.btn-outline-time:focus {
  background: rgb(71, 114, 205);
  background: linear-gradient(102.77deg, #00205c 11.83%, #4c809e 84.22%);
  border-color: transparent !important;
}
.btn-outline-time .time-text {
  /* -webkit-text-stroke: 0.5px black; */
  /* color: transparent; */
  color: #00205c;
}
.btn-outline-time.active .time-text {
  /* -webkit-text-stroke: 0.5px white; */
  color: #ffff;
}
.btn-outline-time.active {
  background: rgb(71, 114, 205);
  background: linear-gradient(
    102.77deg,
    #00205c 11.83%,
    #4c809e 84.22%
  ) !important;
  border-color: transparent !important;
}
.btn-outline-time.disabled {
  pointer-events: none;
  display: block;
  border-color: #c8c8c8 !important;
}
.btn-outline-time.disabled .time-text {
  color: #c8c8c8 !important;
}
.time_appoint input[type="radio"] {
  width: 0;
  height: 0;
  display: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
</style>
