export default {
  auth_request(state) {
    console.log(state);
    state.authStatus = "loading";
  },
  auth_success(state, { token, user }) {
    state.authStatus = "success";
    state.token = token;
    state.user = user;
  },
  auth_error(state) {
    state.authStatus = "error";
  },
  logout(state) {
    state.authStatus = "";
    state.token = "";
  },
  ChangePages(state, pages) {
    state.page_current = pages;
  },
  courseAll(state, course) {
    state.course = course;
  },
  courseHot(state, course) {
    state.course.hot = course;
  },
  courseFast(state, course) {
    state.course.fast = course;
  },
  courseRec(state, course) {
    state.course.recommened = course;
  },
  courseNormal(state, course) {
    state.course.normal = course;
  },
  myCourse(state, course) {
    state.myCourse = course;
  },
  myProfile(state, data) {
    state.myProfile = data;
  },
  idCourse(state, id) {
    state.id_course = id;
  },
  getToken(state, token) {
    state.token = token;
  },
  getTokenLine(state, token) {
    state.tokenLine = token;
  },
  getClinic(state, data) {
    console.log(data);
    state.clinic_detail = data;
  },
  getAppointList(state, data) {
    state.appointment_list = data;
  },
  getAppointData(state, data) {
    state.appoint_data = data;
  },
  ChangePageBack(state, data) {
    state.page_history = data;
  },
  getBookingCourse(state, data) {
    state.course_booking = data;
  },
  getRewardId(state, id) {
    state.reward_id = id;
  },
  getDateAppoint(state, date) {
    state.date_appoint = date;
  },
  getDateClose(state, date) {
    state.date_close = date;
  },
  getDaySpacial(state, day) {
    state.date_special = day;
  },
  getBranchList(state, branch) {
    state.option_branch = branch;
  },
  getDoctor(state, data) {
    state.doctor = data;
  },
  getDayDoctor(state, date) {
    state.date_doctor = date;
  },
  getAppointEmtyList(state, data) {
    state.appoint_empty_list = data
  },
  getTypeCourse(state, data) {
    state.type_course = data
  },
};
