<template>
  <div class="container mt-4">
    <div class="py-4">
    <acculate-point />
    </div>
    <div class="row">
      <div class="col">
        <b class="text-xl">รางวัลที่สามารถแลกได้</b>
      </div>
      <div class="col text-right text-gray-500">
        <b class="text-xl"> {{ many_course }} รางวัล</b>
      </div>
    </div>
    <div>
      <div
        class="coupon_card_free"
        v-for="(val, idx) in campaign_free"
        :key="idx"
      >
        <a @click="handleToDetail(val)">
          <div class="row no-gutters">
            <div class="col-4">
              <img
                class=""
                :src="val.campaign_picture ? url + val.campaign_picture : ''"
                alt=""
              />
            </div>
            <div class="col-8 ">
              <div class="campaign-title">
                <span class="text-xl px-3 "
                  >แพ็คเกจ {{ val.campaign_name_th }}</span
                >
              </div>
              <div class="flex pl-3 detail-membership">
                รายละเอียด
                {{ val.description_th == "" ? "-" : val.description_th }}
              </div>
              <div class="pl-3 flex justify-between mt-2 pb-3">
                <div class="expired-date">
                  <span class="bg-date-expired"
                    >ก่อน {{ formatEndDate(val.end_date) }}</span
                  >
                </div>
                <div class="bg-point text-right mr-2">
                  <span class="text_used">คะแนน</span>
                  <span class="text-point"> {{ val.points }}</span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
 
      <div
        class="coupon_card_course"
        v-for="(val, i) in campaign_course"
        :key="i"
      >
        <a @click="handleToDetail(val)">
          <div class="row no-gutters">
            <div class="col-4">
              <img
                class=""
                :src="val.campaign_picture ? url + val.campaign_picture : ''"
                alt=""
              />
            </div>
            <div class="col-8 ">
              <div class="campaign-title">
                <span class="text-xl px-3"
                  >แพ็คเกจ {{ val.campaign_name_th }}</span
                >
              </div>
              <div class="flex pl-3 detail-membership">
                รายละเอียด Hyaluronic acid เข้าไปในบริเวณหน้าผาก Hyaluronic acid
                เข้าไปในบริเวณหน้าผาก Hyaluronic acid เข้าไปในบริเวณหน้าผาก
              </div>
              <div class="pl-3 flex justify-between mt-2 ">
                <div class="expired-date">
                  <span class="bg-date-expired"
                    >ก่อน {{ formatEndDate(val.end_date) }}</span
                  >
                </div>
                <div class="bg-point text-right mr-2">
                  <span class="text_used">คะแนน</span>
                  <span class="text-point"> {{ val.points }}</span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import AcculatePoint from "./AcculatePoint";
import moment from "moment";
export default {
  data() {
    return {
      url: this.$store.getters.url_api,
      campaign_free: "",
      campaign_course: "",
      many_course: "",
    };
  },
  components: {
    AcculatePoint,
  },
  methods: {
    handleToDetail(res) {
      this.$store.commit("ChangePages", "detailReward");
      this.$store.commit("ChangePageBack", "rewardsList");
      this.$store.commit("getRewardId", res);
    },
    getRewardList() {
      this.$store.dispatch("getCampaign").then((res) => {
        console.log(res);
        const free = [];
        const course = [];
        // this.list_rewards = res;
        for (let i = 0; i < res.length; i++) {
          console.log(res[i]);
          if (res[i].campaign_type == 1) free.push(res[i]);

          if (res[i].campaign_type == 2) course.push(res[i]);
        }
        this.campaign_free = free;
        this.campaign_course = course;
        this.many_course = res.length
      });
    },
    formatEndDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  mounted() {
    this.getRewardList();
  },
};
</script>
