import config from '../../config'
var apiUrl = config.value('apiUrl')
console.log('apiUrl',apiUrl)
import { configcode } from '../config/bzbsconfig'

export default {
  authStatus: "",
  // token: localStorage.getItem("token") || "",
  user: {},
  tableList: [],
  page_current: "default",
  page_history: "default",
  // backend endpoint

  url: configcode.basePublic,
  course: {
    all: [],
    hot: [],
    recommened: [],
    normal: [],
  },
  id_course: "",
  myCourse: [],
  myCourseList: [],
  myProfile: [],
  token: "",
  tokenLine: "",
  clinic_detail: "",
  appointment_list: [],
  appoint_data: [],
  getClinic: {},
  course_booking: {},
  reward_id: [],
  date_appoint: "",
  date_close: [],
  date_special: [],
  option_branch: [],
  date_doctor: [],
  doctor: [],
  appoint_empty_list: [],
  type_course: "course",
};
