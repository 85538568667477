<template>
  <div class="container-fluid mt-2">
    <div class="mx-auto pt-3" v-if="!successAppoint">
      <div class="text-center">
        <h5>เพิ่มนัดหมาย</h5>
      </div>
      <div class="d-flex" style="justify-content: space-evenly">
        <div class="wrapper">
          <input
            type="radio"
            value="0"
            v-model="selectedType"
            @change="handleReset"
            name="select"
            id="option-2"
            checked
          />
          <input
            type="radio"
            value="1"
            v-model="selectedType"
            @change="handleReset"
            name="select"
            id="option-1"
          />
          <label for="option-1" class="option option-1 mr-2">
            <div class="dot mr-2"></div>
            <span>แพ็คเกจของฉัน</span>
          </label>
          <label for="option-2" class="option option-2">
            <div class="dot mr-2"></div>
            <span v-if="branch_id ==  77">ปรึกษาแอดมิน</span>
            <span v-else>ปรึกษาผู้ให้บริการ</span>
          </label>
        </div>
      </div>

      <div v-show="selectedType == 1">
        <div class="mt-2" v-if="myCourseList.length != 0 || isLoading == false">
          <v-select
            style="font-size: 20px"
            v-model="select_course"
            :options="myCourseList"
            label="name_th"
            placeholder="---เลือกแพ็คเกจ---"
            :searchable="true"
            :clearable="false"
          >
            <template #no-options="{ search, searching, loading }">
              <div style="text-align: -webkit-center" class="my-2">
              
                <h5 class="text-danger">
                  ยังไม่มีแพ็คเกจที่ถูกจอง
                  <img
                    style="display: inline; height:15px;"
                    src="../../assets/img/icons/no-course-page.png"
                    alt=""
                  />
                </h5>
                <button
                  class="btn btn-outline-main mt-2"
                  @click="ClicktoPromotions"
                >
                  เลือกแพ็คเกจที่ต้องการ
                </button>
     
              </div>
            </template>
            <template
              class="flex justify-between"
              slot="option"
              slot-scope="option"
              label="เลือกแพ็คเกจ"
            >
              <div class="row">
                <div class="col">
                  <img
                    style="height: 50px; width: 50px; object-fit: cover"
                    :src="url + option.course_picture"
                  />
                </div>
                <div class="col mt-3">
                  <span class="text-left" style="font-size: 24px">
                    {{ option.name_th }}
                  </span>
                </div>
              </div>
            </template>
          </v-select>

          <div class="mt-2">
            <v-date-picker
              is-expanded
              :min-date="new Date()"
              v-model="dateAppoint"
              @dayclick="handleCheckDate"
              :disabled-dates="disabledDate(dateClose, daySpecial)"
              @input="getTimeSlot"
            >
            </v-date-picker>
          </div>
          <div class="mt-2" v-if="time_list.length != 0">
            <h5>เลือกเวลา</h5>
            <select
              v-if="dateAppoint"
              class="form-control"
              v-model="selectTime"
              name="select_time"
              id="select_time"
            >
              <option value="">เวลา</option>
              <option
                v-show="checkDate(time)"
                :value="time"
                v-for="(time, j) in time_list"
                :key="j"
              >
                {{ time.time }}
              </option>
            </select>
            <div v-else>กรุณาเลือกวันนัดหมายก่อน</div>
          </div>
          <div class="mt-2">
            <div v-if="errorAppoint" class="text-danger text-center text-xl">
              ***ไม่สามารถนัดหมายได้ เนื่องจากอาจจะมีการนัดหมายก่อนแล้ว***
              ***โปรดลองอีกครั้ง***
            </div>
            <button
              class="btn btn-main-submit form-control"
              @click="handleAppointCourse"
              :disabled="
                dateAppoint == '' || selectTime == '' || select_course == []
              "
            >
              นัดหมาย
            </button>
            <div class="mt-2">
              <button class="btn btn-danger form-control" @click="redirectPage">
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <div style="text-align: -webkit-center" class="mt-4">
            <img src="../../assets/img/icons/no-course.png" alt="" />
            <h4 class="text-danger">
              ยังไม่มีแพ็คเกจที่ถูกจอง
              <img
                style="display: inline"
                src="../../assets/img/icons/no-course-page.png"
                alt=""
              />
            </h4>
            <button
              class="btn btn-outline-main mt-2"
              @click="ClicktoPromotions"
            >
              เลือกแพ็คเกจที่ต้องการ
            </button>
            <Recommend
              v-if="recommenedList.length != 0"
              :course_recommend="recommenedList"
            />
          </div>
        </div>
      </div>
      <div v-show="selectedType == 0">
        <div class="mt-2">
          <v-date-picker
            is-expanded
            :min-date="new Date()"
            v-model="dateAppoint"
            @dayclick="handleCheckDate"
            :disabled-dates="disabledDate(dateClose, daySpecial)"
            @input="getTimeSlot"
          >
          </v-date-picker>
        </div>
        <div class="mt-2">
          <h5>เลือกเวลา</h5>
          <select
            v-if="dateAppoint"
            class="form-control"
            v-model="selectTime"
            name="select_time"
            id="select_time"
          >
            <option value="">เวลา</option>
            <option
              v-show="checkDate(time)"
              :value="time"
              v-for="(time, j) in time_list"
              :key="j"
            >
              {{ time.time }}
            </option>
          </select>
          <div v-else>กรุณาเลือกวันนัดหมายก่อน</div>
        </div>
        <div class="mt-2">
          <textarea
            placeholder="ข้อความ...."
            class="form-control"
            name="text_appoint"
            id="text_appoint"
            v-model="text_appoint"
            cols="20"
            rows="5"
          ></textarea>
        </div>
        <div class="mt-2">
          <div v-if="errorAppoint" class="text-danger text-center text-xl">
            ***ไม่สามารถนัดหมายได้ เนื่องจากอาจจะมีการนัดหมายก่อนแล้ว***
            ***โปรดลองอีกครั้ง***
          </div>
          <button
            class="btn btn-main-submit form-control"
            @click="handleAppointmentEmpty"
            :disabled="
              dateAppoint == '' || selectTime == '' || text_appoint == ''
            "
          >
            นัดหมาย
          </button>
          <div class="mt-2">
            <button class="btn btn-danger form-control" @click="redirectPage">
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="appointSuccessModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="appointSuccessModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold" id="exampleModalLabel">
              คุณได้ทำนัดหมายปรึกษา
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="text-align: -webkit-center">
            <img width="55" src="../../assets/img/icons/appoints.png" alt="" />
            <h4
              style="font-size: 30px"
              class="text-yeah font-weight-bold text-truncate"
            >
              {{ formatDate(dateAppoint) }}
            </h4>
            <h5
              style="font-size: 30px"
              class="text-yeah font-weight-bold text-truncate"
            >
              {{ selectTime.time }}
            </h5>
            <p
              style="font-size: 28px"
              class="mt-3 text-complete font-weight-bold"
            >
              เรียบร้อยแล้ว
            </p>
          </div>
          <div class="modal-footer">
            <!-- <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button> -->
            <button
              type="button"
              class="btn btn-main form-control"
              @click="changePage"
            >
              ตกลง
            </button>
            <!-- <button
     
              type="button"
              class="btn btn-main"
              @click="changePage"
            >
              ตกลง
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="successAppoint">
      <AppointSuccess
        :date="getDate"
        :time="timeAppoint"
        :course="detailCourse"
        :action="action"
      />
    </div>
  </div>
</template>
<script>
import AppointSuccess from "../Appointment/AppointSuccess.vue";
import Recommend from "../Promotions/Recommend";
import moment from "moment";
import Loading from "../Loading.vue";
export default {
  data() {
    return {
      selectedType: 1,
      select_course: [],
      myCourseList: [],
      url: this.$store.getters.url_api,
      dateClose: this.$store.getters.date_close,
      dateAppoint: "",
      weekDisabled: false,
      dateSelected: "",
      getDate: "",
      daySpecial: this.$store.getters.date_special,
      day_doctor: "",
      time_list: [],
      selectTime: "",
      text_appoint: "",
      action: "create",
      successAppoint: false,
      timeAppoint: "",
      detailCourse: "",
      recommenedList: "",
      errorAppoint: false,
      isLoading: false,
      branch_id: localStorage.getItem('branch')
    };
  },
  components: {
    AppointSuccess,
    Recommend,
    Loading,
  },
  methods: {
    getListCourse() {
      let branch_id = localStorage.getItem("branch");
      if(this.$route.query.branches_id){
        branch_id = this.$route.query.branches_id
      }
      this.isLoading = true;
      this.$store.dispatch("getCourseUser", branch_id).then((res) => {
        let arrayCourse = [];
        console.log(res);
        this.isLoading = false;
        this.myCourseList = this.$store.getters.myCourse;

        this.$store.dispatch("getListMiniCourse").then((mini) => {
          console.log(mini);
          for (let i = 0; i < mini.length; i++) {
            if (mini[i].status != 2) {
              arrayCourse.push(mini[i]);
            }
          }
          for (let j = 0; j < res.course.length; j++) {
            if (res.course[j].status != 2) {
              arrayCourse.push(res.course[j]);
            }
          }
        });
        // const length = res.course.length;
        console.log(arrayCourse);
        this.myCourseList = arrayCourse;
      });
    },
    handleCheckDate() {
      console.log(this.dateAppoint);
      if (this.dateAppoint) {
        this.dateSelected = moment(this.dateAppoint).format("DD/MM/YYYY");
        this.weekDisabled = false;
        const dateAppoint = moment(this.dateAppoint).format("YYYY-MM-DD");
        this.getDate = dateAppoint;
        this.$store.commit("getDateAppoint", dateAppoint);
        console.log(this.getDate);
      } else {
        this.weekDisabled = true;
        this.dateAppoint = "";
      }
    },
    disabledDate(date, day) {
      var special = [];
      const dayDoctor = [];
      const day_doctor = this.day_doctor.day;

      for (let i = 0; i < day.length; i++) {
        special.push({ start: day[i].start_date, end: day[i].end_date });
      }
      if (this.day_doctor.day) {
        day_doctor.map((val) => {
          console.log(val);
          dayDoctor.push(val.numday);
        });
      }
      special.push({ weekdays: date });
      special.push({ weekdays: dayDoctor });

      return special;
    },
    getTimeSlot() {
      console.log(this.dateAppoint);
      var date = moment(this.dateAppoint).format("YYYY-MM-DD");
      console.log(date);
      this.$store.dispatch("getTimeSlot", date).then((res) => {
        console.log(res);
        this.time_list = res;
      });
    },
    handleReset() {
      this.dateAppoint = "";
      this.selectTime = "";
      this.text_appoint = "";
      this.select_course = "";
      this.time_list = [];
      this.errorAppoint = false;
    },
    handleAppointmentEmpty() {
      console.log(this.dateAppoint);
      console.log(this.selectTime);
      console.log(this.text_appoint);
      var time_format =
        moment(this.dateAppoint).format("YYYY-MM-DD") +
        " " +
        this.selectTime.time;
      const data = {
        appoint_datetime: time_format,
        detail: this.text_appoint,
      };
      this.$store.dispatch("createAppointEmpty", data).then((res) => {
        console.log(res);
        if (res.message != "error") {
          $("#appointSuccessModal").modal("show");
        } else {
          console.log("error", res);
          this.errorAppoint = true;
          this.getTimeSlot();
        }
      });
    },
    handleAppointCourse() {
      console.log(this.dateAppoint);
      console.log(this.selectTime);
      console.log(this.select_course);

      var time_format =
        moment(this.dateAppoint).format("YYYY-MM-DD") +
        " " +
        this.selectTime.time;
      const data = {
        id: this.select_course.id,
        appoint_type: this.select_course.type,
        date: time_format,
      };
      console.log(time_format);
      this.$store.dispatch("createAppointment", data).then((res) => {
        console.log(res);

        if (res.message != "error") {
          this.timeAppoint = this.selectTime.time;
          this.detailCourse = this.select_course;
          this.successAppoint = true;
        } else {
          console.log("error", res);
          this.errorAppoint = true;
          this.getTimeSlot();
        }
      });
    },
    ClicktoPromotions() {
      this.$store.commit("ChangePages", "default");
    },
    getCourseRec() {
      var branch_id = localStorage.getItem("branch");
      this.$store.dispatch("getCoursesRec", branch_id).then((res) => {
        console.log(res);
        this.recommenedList = res;
      });
    },
    redirectPage() {
      console.log(this.$store.getters);
      const branch_id = localStorage.getItem("branch");
      this.$store.dispatch("getListAppointment", branch_id);
      this.$store.commit("ChangePages", "appointList");
    },
    changePage() {
      $("#appointSuccessModal").modal("hide");
      const branch_id = localStorage.getItem("branch");
      this.$store.dispatch("getListAppointment", branch_id);
      this.$store.commit("ChangePages", "appointList");
    },
    formatDate(date) {
      return moment(date).format("LL");
    },
    checkDate(date) {
      var dateAppoint = moment(this.dateAppoint).format("YYYY-MM-DD");
      var date_current = moment().format("YYYY-MM-DD");

      if (date_current >= dateAppoint) {
        if (date.time >= moment().format("HH:mm")) {
          if (date.status == 1) {
            return true;
          }
        }
      } else {
        if (date.status == 1) {
          return true;
        }
      }
    },
  },
  mounted() {
    this.getListCourse();
    this.myCourseList = this.$store.getters.myCourse.course;
    console.log(this.myCourseList);
    this.getCourseRec();
  },
};
</script>
<style scoped>
.btn-outline-main {
  color: #00205b;
  background-color: transparent;
  background-image: none;
  border-color: #00205b;
}
.wrapper {
  display: inline-flex;
}
.wrapper .option {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.wrapper .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
.wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #21326a;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"] {
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  border-color: #21326a;
  background: #21326a;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot {
  background: #fff;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  color: #808080;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  color: #fff;
}
</style>