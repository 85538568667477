<template>
  <div>
    <div class="text-center my-3" v-if="isLoading">
      <Loading />
    </div>
    <div v-else>
      <div class="history_service container mt-2">
        <span class="font-weight-bold text-xl">ประวัติการใช้บริการ</span>
        <div id="history">
          <div v-if="history_using.length != 0">
            <div
              class="card_history"
              v-for="(use, idx) in history_using"
              :key="idx"
            >
              <div v-if="use.sumUsed != 0" class="card mb-2">
                <div class="card-header" id="headingUsing">
                  <a
                    class="btn btn-link w-100"
                    data-toggle="collapse"
                    :data-target="`#collapseOne_${idx}`"
                    @click="expandCard(`arrow_${idx}`)"
                    aria-expanded="true"
                    :aria-controls="`collapseOne_${idx}`"
                  >
                    <h5 class="mb-0">
                      <div class="row no-gutters">
                        <div class="col-3">
                          <img
                            :src="url + use.course_picture"
                            alt=""
                            style=""
                          />
                        </div>
                        <div class="col-6 text-left text-truncate">
                          <span>แพ็คเกจ {{ use.name_th }}</span>
                          <h5 class="column_dropdown">
                            {{ setDate(use.updated_at) }}
                          </h5>
                        </div>
                        <div class="col-3">
                          {{ branch == 72 ? "" : "ครั้งที่" }}
                          <span style="color: #43c506">{{ use.sumUsed }}</span>
                          /
                          <span style="color: #cc1a0a">{{
                            use.sumUnit < 0 ? "∞" : use.sumUnit
                          }}</span>
                          <i
                            :class="`fas fa-chevron-down float-right collapse_icon arrow_${idx}`"
                          ></i>
                        </div>
                      </div>
                    </h5>
                  </a>
                  <div
                    :id="`collapseOne_${idx}`"
                    class="collapse"
                    aria-labelledby="headingUsing"
                    data-parent="#headingUsing"
                  >
                    <div class="card-body">
                      <div>บริการ</div>
                      <div v-if="use.product.length != 0">
                        <div
                          class="row"
                          v-for="(product, pdi) in use.product"
                          :key="pdi"
                        >
                          <div class="col">- {{ product.name_th }}</div>

                          <div class="col text-right">
                            {{ product.use }}
                            {{ product.unit }}
                          </div>
                        </div>
                      </div>
                      <div v-if="use.service.length != 0">
                        <div
                          class="row"
                          v-for="(service, svi) in use.service"
                          :key="svi"
                        >
                          <div class="col">- {{ service.name_th }}</div>
                          <div class="col text-right">
                            {{ service.use }}
                            {{ service.unit }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- used history -->
          <div v-if="history_used.length != 0">
            <div
              class="card_history"
              v-for="(used, id) in history_used"
              :key="id"
            >
              <div class="card mb-2">
                <div class="card-header" id="headingUsing">
                  <a
                    class="btn btn-link w-100"
                    data-toggle="collapse"
                    :data-target="`#collapseTwo_${id}`"
                    @click="expandCard(`arrow_used${id}`)"
                    aria-expanded="true"
                    :aria-controls="`collapseTwo_${id}`"
                  >
                    <h5 class="mb-0">
                      <div class="row no-gutters">
                        <div class="col-3">
                          <img
                            :src="url + used.course_picture"
                            alt=""
                            style=""
                          />
                        </div>
                        <div class="col-6 text-left text-truncate">
                          <span>แพ็คเกจ {{ used.name_th }}</span>
                          <h5 class="column_dropdown">
                            {{ setDate(used.updated_at) }}
                          </h5>
                        </div>
                        <div class="col-3">
                          {{ branch == 72 ? "" : "ครั้งที่" }}
                          <span style="color: #43c506">{{ used.sumUsed }}</span>
                          /
                          <span v-if="used.sumUnit > 0" style="color: #cc1a0a">
                            {{ used.sumUnit < 0 ? "∞" : used.sumUnit }}
                          </span>
                          <i
                            :class="`fas fa-chevron-down float-right collapse_icon arrow_used${id}`"
                          ></i>
                        </div>
                      </div>
                    </h5>
                  </a>
                  <div
                    :id="`collapseTwo_${id}`"
                    class="collapse"
                    aria-labelledby="headingUsing"
                    data-parent="#headingUsing"
                  >
                    <div class="card-body">
                      <div>บริการ</div>
                      <div v-if="used.product.length != 0">
                        <div
                          class="row"
                          v-for="(product, ip) in used.product"
                          :key="ip"
                        >
                          <div class="col">- {{ product.name_th }}</div>

                          <div class="col text-right">
                            {{ product.use }}
                            {{ product.unit }}
                          </div>
                        </div>
                      </div>
                      <div v-if="used.service.length != 0">
                        <div
                          class="row"
                          v-for="(service, si) in used.service"
                          :key="si"
                        >
                          <div class="col">- {{ service.name_th }}</div>
                          <div class="col text-right">
                            {{ service.use }}
                            {{ service.unit }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- cancel history -->
          <div v-if="history_cancel.length != 0">
            <div
              class="card_history"
              v-for="(used, id) in history_cancel"
              :key="id"
            >
              <div class="card mb-2">
                <div class="card-header" id="headingUsing">
                  <a
                    class="btn btn-link w-100"
                    data-toggle="collapse"
                    :data-target="`#collapseThree_${id}`"
                    @click="expandCard(`arrow_cancel${id}`)"
                    aria-expanded="true"
                    :aria-controls="`collapseThree_${id}`"
                  >
                    <h5 class="mb-0">
                      <div class="row no-gutters">
                        <div class="col-3">
                          <img
                            :src="url + used.course_picture"
                            alt=""
                            style="filter: grayscale(100%)"
                          />
                        </div>
                        <div class="col-6 text-left text-truncate">
                          <span>แพ็คเกจ {{ used.name_th }}</span>
                          <h5 class="column_dropdown">
                            {{ setDate(used.updated_at) }}
                          </h5>
                        </div>
                        <div class="col-3">
                          {{ branch == 72 ? "" : "ครั้งที่" }}
                          <span style="color: #43c506">{{ used.sumUsed }}</span>
                          /
                          <span style="color: #cc1a0a">
                            {{ used.sumUnit < 0 ? "∞" : used.sumUnit }}
                          </span>

                          <i
                            :class="`fas fa-chevron-down float-right collapse_icon arrow_cancel${id}`"
                          ></i>
                        </div>
                      </div>
                    </h5>
                  </a>
                  <div
                    :id="`collapseThree_${id}`"
                    class="collapse"
                    aria-labelledby="headingUsing"
                    data-parent="#headingUsing"
                  >
                    <div class="card-body">
                      <div>บริการ</div>
                      <div v-if="used.product.length != 0">
                        <div
                          class="row"
                          v-for="(product, ip) in used.product"
                          :key="ip"
                        >
                          <div class="col">- {{ product.name_th }}</div>

                          <div class="col text-right">
                            {{ product.use }}
                            {{ product.unit }}
                          </div>
                        </div>
                      </div>
                      <div v-if="used.service.length != 0">
                        <div
                          class="row"
                          v-for="(service, si) in used.service"
                          :key="si"
                        >
                          <div class="col">- {{ service.name_th }}</div>
                          <div class="col text-right">
                            {{ service.use }}
                            {{ service.unit }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              history_used.length == 0 &&
              history_using.length == 0 &&
              history_cancel == 0
            "
          >
            <div class="text-center">
              <span>ยังไม่มีประวัติใช้บริการ</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Loading from "../Loading";

export default {
  data() {
    return {
      collapse: false,
      history_using: [],
      history_used: [],
      history_cancel: [],
      url: this.$store.getters.url_api,
      isLoading: false,
      branch: localStorage.getItem("branch"),
    };
  },
  components: {
    Loading,
  },
  methods: {
    expandCard(target) {
      console.log(target);
      const arrow = $(`.${target}`);
      if (arrow.hasClass(`fa-chevron-down ${target}`) == true) {
        arrow.addClass("fa-chevron-up");
        arrow.removeClass("fa-chevron-down");
      } else {
        arrow.addClass(`fa-chevron-down ${target}`);
        arrow.removeClass("fa-chevron-up");
      }

      // console.log(!this.collapse);
      // return (this.collapse = !this.collapse);
    },
    setDate(date) {
      return moment(date).add("year", 543).format("LL : HH:mm") + " น.";
    },
    getCourseCancel() {
      this.$store.dispatch("getStatusCourse", 5).then((res) => {
        console.log(res);
        this.history_cancel = res.getCourse;
        this.isLoading = false;
      });
    },
    getCourseExpired() {},
    getStatusUsed() {
      this.$store.dispatch("getStatusCourse", 2).then((res) => {
        console.log(res);
        this.history_used = res.getCourse;
        this.isLoading = false;
      });
    },
    getStatusUse() {
      this.$store.dispatch("getStatusCourse", 1).then((res) => {
        console.log(res.getCourse.length);
        this.history_using = res.getCourse;
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.getStatusUsed();
    this.getStatusUse();
    this.getCourseCancel();
  },
};
</script>
<style>
#history .card-header {
  padding: 0.15rem 0.25rem;
  background-color: #fff !important;
  border-radius: 13px;
  /* border-color: #4C809E; */
}
#history .card {
  border-radius: 13px;
  border-color: #4c809e;
}
#history .card img {
  border-radius: 13px;
  width: 55px;
  height: 55px;
  object-fit: cover;
}
.middle_acculate {
  align-items: center;
  vertical-align: middle;
  display: flex;
}
.collapse_icon {
  color: #aaaaaa;
  padding: 8%;
}
.column_dropdown {
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}
</style>
