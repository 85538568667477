<template>
  <div class="appoint-table container-fluid mt-2">
    <div class="py-3">
      <button
        class="btn btn-main px-4 py-1 form-control"
        @click="handleRedirect"
      >
        สร้างนัดหมาย
      </button>
    </div>
    <div class="py-2" v-if="branch_id ==  77"><b class="text-xl">ปรึกษาแอดมิน</b></div>
    <div class="py-2" v-else><b class="text-xl">ปรึกษาผู้ให้บริการ</b></div>
    <div v-if="list_empty_doctor.length == 0" class="text-center">
      <h5 class="text-gray-600 mb-2">*** ยังไม่มีนัดหมาย ***</h5>
    </div>
    <div
      v-else
      class="mb-3"
      v-for="(val, idx) in list_empty_doctor"
      :key="idx + 'empty'"
    >
      <div class="list_appoint" v-if="idx == 0">
        <div class="row no-gutters">
          <div class="col-3">
            <img
              :src="url + clinic_detail.logo_nav"
              class="card-img-appoint"
              alt="..."
            />
          </div>
          <div class="col-6">
            <div class="card-body pt-0 pl-0 p-1">
              <h5 class="text-left text-truncate title-appoint">
                {{ val.detail }}
              </h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <i class="far fa-clock"></i> {{ formatDate(val.appoint_datetime) }}
          </div>
          <div class="col text-right mt-2">
            <button class="btn btn-outline-appoint" @click="handleEditEmpty(val)">
              แก้ไขนัด
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="py-2"><b class="text-xl">แพ็คเกจ</b></div>
    <div v-if="list_appoint.length == 0" class="text-center">
      <h5 class="text-gray-600 mb-2">*** ยังไม่มีนัดหมาย ***</h5>
    </div>
    <div
      v-else
      class="mb-3 list_appoint"
      v-for="(val, idx) in list_appoint"
      :key="idx"
    >
      <div class="row no-gutters">
        <div class="col-3">
          <img
            :src="url + val.course_picture"
            class="card-img-appoint"
            alt="..."
          />
        </div>
        <div class="col-6">
          <div class="card-body pt-0 pl-0 p-1">
            <h5 class="text-left text-truncate title-appoint">
              แพ็คเกจ : {{ val.name_th }}
            </h5>
            <p
              style="font-size: 14px"
              class="detail-appoint-truncate text-gray-600"
            >
              รายละเอียด {{ val.details_th }}
            </p>
          </div>
        </div>
        <div class="col-3 py-1 text-right">
          <span style="font-weight: bold"
            >ครั้งที่ <span class="text-success">{{ val.appoint_no }}</span>
          </span>
          <h3 class="text-truncate">
            ฿ <span style="color: #cc1a0a">{{ formateNum(val.total) }}</span>
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <i class="far fa-clock"></i> {{ formatDate(val.appoint_datetime) }}
        </div>
        <div class="col text-right mt-2">
          <button
            v-if="val.push_message == 0"
            class="btn btn-outline-appoint"
            @click="handleEdit(val)"
          >
            แก้ไขนัด
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import numeral from "numeral";
import { mapGetters } from "vuex";
moment.locale("th");
export default {
  data() {
    return {
      url: this.$store.getters.url_api,
      branch_id: localStorage.getItem('branch')
    };
  },
  methods: {
    formatDate(date) {
      console.log();
      return moment(date).format("LLL");
    },
    formateNum(num) {
      return numeral(num).format("0,0");
    },
    handleEdit(data) {
      console.log(data);
      console.log(data.id);
      this.$store.commit("getAppointData", data);
      this.$store.commit("idCourse", 0);
      this.$store.commit("getDayDoctor", "");
      this.$store.commit("getDoctor", "");
      this.$store.commit("ChangePages", "appointDate");
    },
    handleEditEmpty(data) {
      this.$store.commit("ChangePages", "appointment_empty_consult");
      this.$store.commit("getAppointData", data)
    },
    handleRedirect() {
      this.$store.commit("ChangePages", "appointment_consult");
    },

    getAppointment(branch_id) {
      this.$store.dispatch("getListAppointment", branch_id).then((res) => {
        console.log(res);
      });
    },
    getEmptyAppoint() {
      this.$store.dispatch("getAppointEmpty").then((res) => {
        console.log(res);
      });
    },
    getListAppointUser() {
      const id_user = this.$store.getters.myProfile;
      console.log(id_user);
      // this.$store.dispatch("getListEmptyAppointUser", id_user)
      //   .then( res => {
      //     console.log(res)
      //   })
    },
  },
  mounted() {
    mapGetters({
      list_appoint: "appointment_list",
      list_empty_doctor: "appoint_empty_list",
      clinic_detail: "clinic_detail",
      profile: "myProfile",
    });
    console.log(this.$store.getters);
    var branch_id = localStorage.getItem("branch");
    this.getAppointment(branch_id);
    this.getEmptyAppoint();
    this.getListAppointUser();
  },
  computed: {
    ...mapGetters({
      list_appoint: "appointment_list",
      list_empty_doctor: "appoint_empty_list",
      clinic_detail: "clinic_detail",
      profile: "myProfile",
    }),
  },
};
</script>
<style scoped>
.appoint-table a {
  color: #85a4d2;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  border-bottom: 1px solid #85a4d2;
}
.appoint-table th td {
  font-size: 12px;
}
.card-img-appoint {
  border-radius: 13px;
  object-fit: cover;
  width: 63px;
  height: 63px;
}
.detail-appoint-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 13px;
  color: #aaaaaa;
}
.list_appoint {
  border-bottom: 1px dashed #c4c4c4;
}
.btn-outline-main {
  color: #00205b;
  background-color: transparent;
  background-image: none;
  border-color: #00205b;
}
.btn-outline-appoint {
  color: #00205b;
  background-color: transparent;
  background-image: none;
  border-color: #00205b;
  margin-top: -15px;
  margin-bottom: 10px;
  /* padding-right: 21px;
  padding-left: 21px; */
  padding: 5px 25px;
}
.title-appoint {
  font-family: 18px;
  color: #00205b;
  font-weight: bold;
}
</style>
