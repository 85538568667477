<template>
  <div class="booking">
    <div class="img-detail-courses">
      <img class="rounded" :src="url + bookingCoures.course_picture" alt="" />
    </div>
    <div class="p-4" style="text-align: -webkit-center">
      <img src="../../assets/img/icons/booking-success.png" alt="" />
      <div class="border-booking">
        <p style="font-size:20px" class="font-weight-bold">คุณได้ทำการจอง</p>
        <h4 style="font-size:30px" class="text-yeah font-weight-bold">
          แพ็คเกจ : {{ bookingCoures.name_th }}
        </h4>
        <h4 style="font-size:30px;color:#40609B" class="font-weight-bold">
          ราคา ฿ {{ formatNum(bookingCoures.total) }}
        </h4>
        <p style="font-size:28px" class="mt-3 text-yeah font-weight-bold">
          เรียบร้อยแล้ว
        </p>
      </div>
      <div class="text-xl">
        <!-- <p>คุณจะได้รับคะนนสะสม {{ bookingCoures.points }} คะแนน</p> -->
        <!-- <p>เมื่อมีการเข้ารับบริการและชำระในครั้งแรก</p> -->
      </div>
      <div class="mt-3">
        <h5 class="text-yeah font-weight-bold">
          ทำการนัดหมายเพื่อเข้ารับบริการ
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <button class="btn btn-danger form-control" @click="RedirectMyCousres">
          ภายหลัง
        </button>
      </div>
      <div class="col-7">
        <button
          class="btn btn-main-submit form-control"
          @click="AppointDate(bookingCoures.id)"
        >
          นัดหมาย
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import numeral from "numeral";
export default {
  data() {
    return {
      url: this.$store.getters.url_api,
      course_id: "",
      bookingCoures: {},
    };
  },
  methods: {
    RedirectMyCousres() {
      var branch_id = localStorage.getItem("branch");
      this.$store.dispatch("getCourseUser", branch_id).then((res) => {
        console.log(res);
        console.log(this.$store.getters.myCourse);
        this.$store.commit("ChangePages", "course");
      });
    },
    AppointDate(id) {
      console.log(id);
      this.$store.commit("ChangePages", "appointDate");
      this.$store.commit("idCourse", id);
    },
    formatNum(num) {
      return numeral(num).format("0,0");
    },
  },
  created() {
    console.log(this.$store.getters.course_booking);
    this.bookingCoures = this.$store.getters.course_booking;
  },
};
</script>
<style>
.border-booking {
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 13px;
  margin-top: 25px;
}
.text-yeah {
  color: #43c506 !important;
}
</style>
