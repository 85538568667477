import axios from "axios";

let key = "1698191680705252";
let secret = "fa560511ea34c23705111a5ecd315944";
export default {
  getLoginWith({ commit }, tag_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/users/signin`, tag_id)
        .then((res) => {
          console.log(res);
          console.log(res.data.data.clinic_id);
          if (res.data.message == "success") {
            var clinic_id = res.data.data.clinic_id;
            localStorage.setItem("clinic_id", clinic_id);
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getProfile({ commit }) {
    var config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Branches: branch_id,
      },
    };
    var branch_id = localStorage.getItem("branch");
    var token = localStorage.getItem("token_" + branch_id);
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/users/profile", null, config)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            axios.defaults.headers.common["Branches"] = branch_id;
            // localStorage.setItem("clinic_id", res.data.data.clinic_id);
            commit("myProfile", res.data.data);
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getClinic({ commit }, id_branch) {
    console.log(id_branch);
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/userLineRich/id/${id_branch}`)
        .then((res) => {
          console.log(res);

          if (res.status == 200) {
            commit("getClinic", res.data.data.branches[0]);
            resolve(res.data.data.branches);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getTokenLine({ commit }, data) {
    return new Promise((resolve, reject) => {
      console.log(data);
      console.log(data.state);
      const params = new URLSearchParams();
      params.append("client_id", data.client_id);
      params.append("client_secret", data.client_secret);
      params.append("code", data.code);
      params.append("grant_type", "authorization_code");
      params.append("redirect_uri", data.redirect_uri);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      commit("auth_request");
      axios
        .post(`https://api.line.me/oauth2/v2.1/token`, params, config)
        .then((res) => {
          console.log(res.data);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.id_token;
          axios.defaults.headers.common["Branches"] = data.state;
          localStorage.setItem("token_" + data.state, res.data.id_token);
          localStorage.setItem("access_token", res.data.access_token);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  Register({ commit }, res) {
    return new Promise((resolve, reject) => {
      var branch_id = localStorage.getItem("branch");
      var token = localStorage.getItem("token_" + branch_id);
      // console.log(this.$store.getter)
      console.log(token);
      axios
        .post(`/api/v1/users/register`, res, {
          headers: {
            Authorization: `Bearer ${token}`,
            Branches: branch_id,
          },
        })
        .then((res) => {
          var token = res.data.data.line_access_token;
          var user = res.data.data;
          commit("auth_success", { token, user });
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  async getListCourse({ commit }, branch_id) {
    console.log(branch_id);
    return new Promise((resolve, reject) => {
      //course
      axios
        .get(`/api/v1/userLineRich/getListCourse/id/${branch_id}`)
        .then((res) => {
          commit("courseAll", res.data.data.course);
          resolve(res.data.data.course);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCoursesHot({ commit }, client_id) {
    return new Promise((resolve, reject) => {
      //course Hot
      axios
        .get(`/api/v1/userLineRich/getHot/id/${client_id}`)
        .then((res) => {
          console.log(res.data);
          commit("courseHot", res.data.data.course);
          resolve(res.data.data.course);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getCoursesRec({ commit }, client_id) {
    return new Promise((resolve, reject) => {
      //course Recommented
      axios
        .get(`/api/v1/userLineRich/getRec/id/${client_id}`)
        .then((res) => {
          commit("courseRec", res.data.data.course);
          resolve(res.data.data.course);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getCoursesFast({ commit }, client_id) {
    return new Promise((resolve, reject) => {
      //course Fast
      axios
        .get(`/api/v1/userLineRich/getFast/id/${client_id}`)
        .then((res) => {
          commit("courseFast", res.data.data.course);
          resolve(res.data.data.course);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getCourseByBranchId({ commit }, id) {
    console.log(id);
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/course/id/${id}`)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getDetailCourse({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userLineRich/getCourse/id/${id}`)
        .then((res) => {
          console.log(res);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getCourseUser({ commit }, branch_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/bookingCourse/branches/${branch_id}`)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log(res.data.data);
            commit("myCourse", res.data.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  bookingCourse({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/bookingCourse/`, id)
        .then((res) => {
          commit("getBookingCourse", res.data.data.course);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getDetailCourseById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/bookingCourse/id/${id}`)
        .then((res) => {
          console.log(res);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getTimeSlot({ commit }, data) {
    var date = { date: data };
    console.log(date);
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/userAppointment/timeSlot`, date)
        .then((res) => {
          console.log(res);
          resolve(res.data.data.time);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  createAppointment({ commit }, data) {
    var id = data.id;
    var date = data.date;
    var data_appoint = "";
    console.log(data);
    if (data.doctor_id) {
      data_appoint = {
        appoint_datetime: date,
        appoint_type: data.appoint_type,
        doctor_id: data.doctor_id,
      };
    } else {
      data_appoint = {
        appoint_datetime: date,
        appoint_type: data.appoint_type,
      };
    }
    console.log(data_appoint);
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/userAppointment/create/UserCourseId/${id}`, data_appoint)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getListAppointment({ commit }, branch_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userAppointment/branches/${branch_id}`)
        .then((res) => {
          console.log(res);
          commit("getAppointList", res.data.data.data);
          console.log(res.data.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getDataAppointment({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userAppointment/id/${id}`)
        .then((res) => {
          console.log(res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  updateAppointment({ commit }, date) {
    console.log(date);
    var id = date.id;
    var date = date.date;
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/userAppointment/edit/id/${id}`, {
          appoint_datetime: date,
        })
        .then((res) => {
          console.log(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getLineLich({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userLineRich/linelogin/branches/${id}`)
        .then((res) => {
          console.log(res.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  updateProfile({ commit }, data) {
    console.log(data);
    const branch_id = localStorage.getItem("branch");
    const token = localStorage.getItem("token_" + branch_id);
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/users/update`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            Branches: branch_id,
          },
        })
        .then((res) => {
          console.log(res.data.data);
          commit("myProfile", res.data.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getStatusCourse({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/bookingCourse/getstatus/${id}`)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log(res.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getCampaign({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userCampaign`)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log(res.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getRewardDetail({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userCampaign/id/${id}`)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log(res.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  redeemReward({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/userCampaign/redeem/${id}`)
        .then((res) => {
          console.log(res);
          // if (res.status == 200) {
          console.log(res.data);
          resolve(res.data.data);
          // }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getListMyCampaign({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userCampaign/get/userCampaign`)
        .then((res) => {
          console.log(res);
          // if (res.status == 200) {
          console.log(res.data);
          resolve(res.data.data);
          // }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getListTier({ commit }) {
    // /api/v1/userLvPoints/
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userLvPoints/`)
        .then((res) => {
          console.log(res);
          // if (res.status == 200) {
          console.log(res.data);
          resolve(res.data.data);
          // }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getCampaignTierByid({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userLvPoints/lv_points/${id}`)
        .then((res) => {
          console.log(res);
          // if (res.status == 200) {
          console.log(res.data);
          resolve(res.data.data);
          // }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  sendOTP({ commit }, phone) {
    // console.log(bodyFormData)
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/smsBzbs/requestOtp", phone)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log(res.data);
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  validateOTP({ commit }, dataOtp) {
    console.log(dataOtp);
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/smsBzbs/verifyOTP`, dataOtp)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log(res.data);
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getBankList({ commit }, price_down) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userLineRich/getBank/${price_down}`)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log(res.data.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  uploadSlip({ commit }, data) {
    console.log(data);
    let formData = new FormData();
    formData.append("slip_picture", data.slip_picture);
    formData.append("user_course_id", data.user_course_id);
    console.log(data);

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/userLineRich/uploadSlip/`, formData)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log(res.data.data);
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getDateClose({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userAppointment/getDayOpeningClose`)
        .then((res) => {
          console.log(res);
          const getClose = [];
          if (res.status == 200) {
            resolve(res.data.data);
            const day = res.data.data.day;
            for (let i = 0; i < day.length; i++) {
              getClose.push(day[i].numday);
            }
            commit("getDateClose", getClose);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getDaySpecial({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userAppointment/getDaySpecialClose`)
        .then((res) => {
          console.log(res.data.data);
          if (res.status == 200) {
            resolve(res.data.data.getDaySpecial);
            commit("getDaySpacial", res.data.data.getDaySpecial);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getLinePhone({ commit }, id) {
    // /api/v1/linePhone/appoint/
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/linePhone/appoint/${id}`)
        .then((res) => {
          console.log(res.data.data);
          if (res.status == 200) {
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  // /api/v1/userLineRich/get/clinic_id/
  getBranchList({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userLineRich/get/clinic_id/${id}`)
        .then((res) => {
          console.log(res.data.data);
          if (res.status == 200) {
            commit("getBranchList", res.data.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getDayDoctor({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userAppointment/getDayDoctorClose/${id}`)
        .then((res) => {
          console.log(res.data.data);
          if (res.status == 200) {
            commit("getDayDoctor", res.data.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getTimeSlotDoctor({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/userAppointment/timeSlot/doctor/${data.id}`, {
          date: data.date,
        })
        .then((res) => {
          0;
          console.log(res.data.data);
          if (res.status == 200) {
            resolve(res.data.data.time);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getDoctor({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userLineRich/doctor/id/${id}`)
        .then((res) => {
          console.log(res.data.data);
          if (res.status == 200) {
            commit("getDoctor", res.data.data.doctor);
            resolve(res.data.data.doctor);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  // empty appoint
  getAppointEmpty({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userAppointment/empty`)
        .then((res) => {
          commit("getAppointEmtyList", res.data.data.data);
          console.log(res.data.data);
          if (res.status == 200) {
            resolve(res.data.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getAppointEmptyById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userAppointment/empty/id/${id}`)
        .then((res) => {
          commit("");
          console.log(res.data.data);
          if (res.status == 200) {
            resolve(res.data.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  createAppointEmpty({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/userAppointment/empty`, data)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            commit("");
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          resolve(err.response.data)
          // reject(err);
        });
    });
  },
  editAppointEmptyById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/userAppointment/empty/id/${data.id}`, data)
        .then((res) => {
          commit("");
          console.log(res.data.data);
          if (res.status == 200) {
            resolve(res.data.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getListEmptyAppointUser({ commit }, id) {
    console.log(id);
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userAppointment/empty/user_id/${id}`)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            commit("");
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  confirmEmptyAppointment({ commit }, id) {
    console.log(id);
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/linePhone/appoint/empty/${id}`)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            commit("");
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getListMiniCourse({commit}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userCourseMini/`)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            commit("");
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    }); 
  },
  getListMiniCourseById({commit},id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/userCourseMini/id/${id}`)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            commit("getTypeCourse", "mini");
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    }); 
  }
};
