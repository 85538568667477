<template>
  <nav
    id="nav-main"
    :style="setTheme"
    class="top-0 absolute z-50 w-full flex flex-wrap items-center mb-5"
  >
    <div class="row col-12 flex items-center justify-center -text-center">
      <div v-if="checkPages" class="back-btn col-2 " @click="handleBack">
        <i class="fas fa-reply"></i>
        <!-- <img src="../../assets/img/icons/back_btn.png" alt="" /> -->
      </div>
      <div v-else class="col-2"></div>
      <div class="row col-8 flex justify-center">
        <router-link class="text-white " style="height:8%;" to="/dashboard">
          <img class="img-navbar" :src="url + img" alt="" />
          <!-- <img
            class="img-navbar"
            src="../../assets/img/icons/logoAnon3.png"
            alt=""
          /> -->
        </router-link>
        <!-- <button @click="handleLogout">logout</button> -->
      </div>
      <div class="col-2"></div>
    </div>
  </nav>
</template>
<script>
export default {
  props: ["checkPages"],
  data() {
    return {
      navbarOpen: false,
      setTheme: {
        name: "default",
        background: "#FFFF",
        color: "#FFF",
      },
      img: "",
      url: this.$store.getters.url_api,
      currentPage: this.$store.getters.defaultPage,
      backPage: this.$store.getters.page_history,
    };
  },
  // props: ['setTheme'],
  methods: {
    handleLogout() {
      localStorage.clear();
      location.replace("http://localhost:8080/id/1");
    },
    getImage(branch) {
      this.$store.dispatch("getClinic", branch).then((res) => {
        console.log(res);
        this.img = res[0].logo_nav;
      });
    },
    handleBack() {
      console.log(this.$store.getters.page_history);
      var page_back = this.$store.getters.page_history;
      this.$store.commit("ChangePages", page_back);
    },
  },
  components: {},
  mounted() {
    var branch = localStorage.getItem("branch");
    if (this.$route.query.branches_id) {
      branch = this.$route.query.branches_id;
    }
    this.token = localStorage.getItem("token_" + branch);
    if (this.token) {
      this.getImage(branch);
    }
    console.log(this.$store.getters.page_history);
    console.log(this.$store.getters.defaultPage);
  },
};
</script>
<style scoped>
#nav-main {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 8%;
}
#nav-main .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -10px;
}
.img-navbar {
  /* width: 35%; */
  height: 45px;
  /* margin-left: 14%; */
}
.back-btn {
  color: #21326a;
  font-size: 27px;
  /* border: 1px solid #21326a; */
  border-radius: 13px;
  padding: 1px 5px;
  margin-top: 10px;
}
</style>
