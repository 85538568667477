<template>
  <div>
    <Navbar
      :checkPages="pageSet"
      v-if="dynamicPage != 'confirmAppoint' && dynamicPage != 'appointment_confirm_consult'"
    />
    <TierBenefit v-if="dynamicPage == 'benefitPage'" />
    <ConfirmAppoint v-if="dynamicPage == 'confirmAppoint'" />
    <confirmEmptyAppointment
      v-if="dynamicPage == 'appointment_confirm_consult'"
    />

    <main v-else class="profile-page mt-1 py-16">
      <ProfileTab   v-if="dynamicPage != 'confirmAppoint' && dynamicPage != 'appointment_confirm_consult'" />
      <div class="tab_menu mt-3"   v-if="dynamicPage != 'confirmAppoint' && dynamicPage != 'appointment_confirm_consult'">
        <ul class="nav nav-tabs nav-justified">
          <li class="nav-item">
            <a
              :class="`nav-link ${
                dynamicPage == 'default' || dynamicPage == 'detailPage'
                  ? 'active'
                  : ''
              } `"
              href="#tabPromotion"
              @click="handleChageTabs('default')"
              data-toggle="tab"
            >
              <img
                class="icon-stable"
                src="../assets/img/icons/shop.png"
                alt=""
              />
              <!-- <span class="badge">3</span> -->
              ช้อป
            </a>
          </li>
          <li class="nav-item">
            <a
              :class="`nav-link ${
                dynamicPage == 'course' ||
                dynamicPage == 'detailMyCourse' ||
                dynamicPage == 'payments'
                  ? 'active'
                  : ''
              } `"
              href="#tabCourses"
              @click="handleChageTabs('course')"
              data-toggle="tab"
            >
              <img
                class="icon-stable"
                src="../assets/img/icons/course.png"
                alt=""
              />
              กระเป๋า
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="
                dynamicPage == 'appointList' ||
                dynamicPage == 'appointDate' ||
                dynamicPage == 'doctorList' ||
                dynamicPage == 'appointment_consult' ||
                dynamicPage == 'appointment_empty_consult'
                  ? 'active'
                  : ''
              "
              href="#tabAppoint"
              @click="handleChageTabs('appointList')"
              data-toggle="tab"
            >
              <img
                class="icon-stable"
                src="../assets/img/icons/appoint.png"
                alt=""
              />
              นัดหมาย
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="
                dynamicPage == 'myProfile' ||
                dynamicPage == 'editProfile' ||
                dynamicPage == 'memberships' ||
                dynamicPage == 'history' ||
                dynamicPage == 'rewardsList' ||
                dynamicPage == 'detailReward' ||
                dynamicPage == 'detailCoupon'
                  ? 'active'
                  : ''
              "
              href="#tabProfile"
              data-toggle="tab"
              id="tabProfit"
              @click="handleChageTabs('myProfile')"
            >
              <div class="profit-nav">
                <img
                  class="profit-img"
                  src="../assets/img/icons/icon-profit.png"
                  alt=""
                />
              </div>
            </a>
          </li>
        </ul>
        <div class="tab-content" id="tabContent">
          <div
            :class="`tab-pane fade show  ${
              dynamicPage == 'default' || dynamicPage == 'detailPage'
                ? ' active'
                : ''
            }`"
            id="tabPromotion"
          >
            <Promotions v-if="dynamicPage == 'default'" />
            <DetailPromotion v-if="dynamicPage == 'detailPage'" />
          </div>
          <!-- <div v-if="" class="tab-pane fade active show" id="tabPromotion">
            <Promotions />
          </div> -->
          <!-- Appointment -->
          <div
            class="tab-pane fade show"
            :class="`${
              dynamicPage == 'course' ||
              dynamicPage == 'detailMyCourse' ||
              dynamicPage == 'payments' ||
              dynamicPage == 'doctorList'
                ? ' active'
                : ''
            }`"
            id="tabCourses"
          >
            <MyCourses v-if="dynamicPage == 'course'" />
            <MyCourseDetail v-if="dynamicPage == 'detailMyCourse'" />
            <Payments v-if="dynamicPage == 'payments'" />
          </div>
          <div
            class="tab-pane fade show"
            :class="
              dynamicPage == 'appointList' ||
              dynamicPage == 'appointDate' ||
              dynamicPage == 'doctorList' ||
              dynamicPage == 'appointment_consult' ||
              dynamicPage == 'appointment_empty_consult'
                ? ' active'
                : ''
            "
            id="tabAppoint"
          >
            <AppointmentList v-if="dynamicPage == 'appointList'" />
            <AppointDate v-if="dynamicPage == 'appointDate'" />
            <doctor-list v-if="dynamicPage == 'doctorList'" />
            <create-appoint
              v-if="dynamicPage == 'appointment_consult'"
            ></create-appoint>
            <edit-empty-appoint
              v-if="dynamicPage == 'appointment_empty_consult'"
            ></edit-empty-appoint>
          </div>
          <div
            class="tab-pane fade show"
            id="tabProfile"
            :class="
              dynamicPage == 'myProfile' ||
              dynamicPage == 'editProfile' ||
              dynamicPage == 'memberships' ||
              dynamicPage == 'history' ||
              dynamicPage == 'rewardsList' ||
              dynamicPage == 'detailReward' ||
              dynamicPage == 'detailCoupon'
                ? 'active'
                : ''
            "
          >
            <profile v-if="dynamicPage == 'myProfile'" />
            <Infomation v-if="dynamicPage == 'editProfile'" />
            <membership v-if="dynamicPage == 'memberships'" />
            <history v-if="dynamicPage == 'history'" />
            <RewardList v-if="dynamicPage == 'rewardsList'" />
            <DetailReward v-if="dynamicPage == 'detailReward'" />
            <detail-coupon v-if="dynamicPage == 'detailCoupon'" />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
// import FooterComponent from "@/components/Footers/Footer.vue";

import ProfileTab from "../components/Profiles/ProfileTab";
//section tabs
import Infomation from "../components/Profiles/Infomation";
import Promotions from "../components/Promotions/Promotions";
import AppointmentList from "../components/Appointment/Appointment_list";
import MyCourses from "../components/MyCourse/MyCourses";
import DetailPromotion from "../components/Promotions/DetailPromotion";
import MyCourseDetail from "../components/MyCourse/MyCourseDetail";
import AppointDate from "../components/Appointment/AppointDate";
import Profile from "../components/Profiles/Profile";

//memberships
import Membership from "../components/Membership/Membership";
import RewardList from "../components/Membership/RewardList";
import DetailReward from "../components/Membership/DetailReward";
import DetailCoupon from "../components/Membership/DetailCoupon.vue";
//Benefit
import TierBenefit from "../components/TierBenefit/TierBenefit";

//Payment
import Payments from "../components/MyCourse/Payments";

//appointConfirm
import ConfirmAppoint from "../components/Appointment/ConfirmAppoint";
import DoctorList from "../components/Doctors/DoctorList.vue";
import History from "../components/Profiles/History.vue";
import CreateAppoint from "../components/Appointment/CreateAppoint.vue";
import EditEmptyAppoint from "../components/Appointment/EditEmptyAppoint.vue";
import confirmEmptyAppointment from "../components/Appointment/confirmAppointEmpty.vue";

export default {
  data() {
    return {
      pathLink: true,
      id_course: "",
      courseList: "",
      myCourseList: "",
      pageSet: false,
    };
  },
  props: ["setTheme"],
  components: {
    Navbar,
    // FooterComponent,
    ProfileTab,
    Infomation,
    Promotions,
    AppointmentList,
    MyCourses,
    DetailPromotion,
    MyCourseDetail,
    AppointDate,
    Profile,
    Membership,
    RewardList,
    DetailReward,
    DetailCoupon,
    TierBenefit,
    Payments,
    ConfirmAppoint,
    DoctorList,
    History,
    CreateAppoint,
    EditEmptyAppoint,
    confirmEmptyAppointment,
  },
  methods: {
    handleChageTabs(res) {
      this.pathLink = true;
      this.$store.commit("ChangePages", res);
      this.$store.commit("ChangePageBack", res);
    },
    getTimeClose() {
      this.$store.dispatch("getDateClose").then((res) => console.log(res));
    },
    getDaySpecial() {
      this.$store.dispatch("getDaySpecial").then((res) => console.log(res));
    },
  },
  computed: {
    dynamicPage: function () {
      console.log(this.$store.getters);
      console.log(this.$store.getters.defaultPage);
      console.log(this.$store.getters.page_history);
      this.myCourseList = this.$store.getters.myCourse;
      if (this.$store.getters.defaultPage == this.$store.getters.page_history) {
        console.log("true");
        this.pageSet = false;
      } else {
        console.log("false");
        this.pageSet = true;
      }
      return this.$store.getters.defaultPage;
    },
  },
  created() {
    this.token = localStorage.getItem("token_" + this.$route.query.branches_id);
    if (this.token) {
      var branch_id = localStorage.getItem("branch");
    if(this.$route.query.branches_id){
      branch_id = this.$route.query.branches_id
    }
    console.log("🚀 ~ created ~ branch_id:", branch_id)

    this.$store.dispatch("getCourseUser", branch_id).then((res) => {
      console.log(res);
      this.myCourseList = res;
      console.log(this.myCourseList);
    });
    this.$store.dispatch("getListCourse", branch_id).then((res) => {
      console.log(res);
      this.myCourseList = res;
    });
    this.$store.dispatch("getClinic", branch_id).then((clinic) => {
      console.log(clinic);
    });
    }

  },
  watch: {
    dynamicPage: function () {
      console.log(this.$store.getters);
      this.token = localStorage.getItem("token_" + branch_id);
    if (this.token) {
      var branch_id = localStorage.getItem("branch");
      if(this.$route.query.branches_id){
        branch_id = this.$route.query.branches_id
      }
      this.$store.dispatch("getListCourse", branch_id).then((res) => {
        console.log(res);
      });
      this.$store.dispatch("getCourseUser", branch_id).then((res) => {
        console.log(res);
        this.myCourseList = res;
      });
    }


      return this.$store.getters.defaultPage;
    },
  },
  mounted() {
    this.token = localStorage.getItem("token_" + this.$route.query.branches_id);
    if (this.token) {
      var branch_id = localStorage.getItem("branch");
    var token = localStorage.getItem("token_" + branch_id);
    if(this.$route.query.branches_id){
      branch_id = this.$route.query.branches_id
    }
    if (
      token == null && this.$route.name != "appointsuccess" &&
      this.$route.name != "appointEmpty"
    ) {
      location.replace("/id/" + branch_id);
    }
    this.$store.dispatch("getClinic", branch_id).then((clinic) => {
      console.log(clinic);
    });
    console.log(this.$store.getters);
    this.$store.dispatch("getProfile").then((res) => {
      this.profile = res.data;
    });
    this.$store.dispatch("getListAppointment", branch_id).then((res) => {
      console.log(res);
    });
    this.$store.dispatch("getCourseUser", branch_id).then((res) => {
      console.log(res);
    });
    this.getTimeClose();
    this.getDaySpecial();
    this.myCourseList = this.$store.getters.myCourse;
    }

    if (this.$route.name == "mycourse") {
      this.$store.commit("ChangePages", "course");
      this.$store.commit("ChangePageBack", "course");
    }
    if (this.$route.name == "appointment") {
      this.$store.commit("ChangePages", "appointList");
      this.$store.commit("ChangePageBack", "appointList");
    }
    if (this.$route.name == "profile") {
      this.$store.commit("ChangePages", "myProfile");
      this.$store.commit("ChangePageBack", "myProfile");
    }
    if (this.$route.name == "mycourse_line") {
      this.$store.commit("ChangePages", "course");
      this.$store.commit("ChangePageBack", "course");
    }
    if (this.$route.name == "profile_line") {
      this.$store.commit("ChangePages", "myProfile");
      this.$store.commit("ChangePageBack", "myProfile");
    }

    if (this.$route.name == "appointment_line") {
      this.$store.commit("ChangePages", "appointList");
      this.$store.commit("ChangePageBack", "appointList");
    }

    if (this.$route.name == "payments_line") {
      this.$store.commit("ChangePages", "payments");
      this.$store.commit("ChangePageBack", "course");
    }
    if (this.$route.name == "appointsuccess") {
      this.$store.commit("ChangePages", "confirmAppoint");
      this.$store.commit("ChangePageBack", "course");
    }
    if (this.$route.name == "appointEmpty") {
      this.$store.commit("ChangePages", "appointment_confirm_consult");
      this.$store.commit("ChangePageBack", "appointList");
    }
    if (this.$route.name == "course_detail") {
      const id = this.$route.params.id;
      this.$store.commit("ChangePages", "detailPage");
      this.$store.commit("idCourse", id);
      this.$store.commit("ChangePageBack", "course");
    }
    if (this.$route.name == "appoint_empty") {
      this.$store.commit("ChangePages", "appointment_consult");
      this.$store.commit("ChangePageBack", "appointList");
    }
  },
};
</script>
<style scoped>
a.disabled {
  pointer-events: none;
  color: #ccc;
}
.profile-page li {
  text-align: -webkit-center;
}

.nav-link:active {
  border-bottom: transparent;
}

.notification {
  text-decoration: none;
  /* padding: 15px 26px; */
  position: relative;
  display: inline-block;
  border-radius: 2px;
}

.notification .badge {
  position: absolute;
  top: 2px;
  right: 13px;
  padding: 7px 10px;
  border-radius: 50%;
  background: red;
  color: white;
  font-size: 13px;
}
.profile-page a {
  font-size: 18px;
}
.nav-tabs:active {
  border-bottom: 0;
}
.active .icon-stable {
  width: 45%;
}

.profit-img {
  color: white;
  width: 36%;
  padding-top: 16%;
  height: 55px;
  object-fit: cover;
}
.profit-nav {
  background-image: url("../assets/img/icons/bg-profit.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background-attachment: fixed; */
  background-position: center;
  color: #fff !important;
  width: 90%;
  border-radius: 25px;
  height: 90px;
}
.icon-stable {
  width: 85%;
}
.nav-item {
  border-color: transparent;
  background-color: #fff;
  /* box-shadow: 0 0 0 1px #888888; */
}
.nav-tabs .nav-link.active {
  border-bottom-color: black;
  /* box-shadow: 0 0 0 1px #888888; */
  background-color: #fff;
  border-color: transparent !important;
}
.nav-link.active .profit-img {
  /* width: 98%; */
  color: #fff;
  height: 55px;
  object-fit: cover;
  margin-top: -10px;
}
.nav-link.active .icon-stable {
  margin-top: -10px;
  width: 100%;
  box-shadow: 0px 15px 10px -15px #111;
  border-radius: 30px;
}

.nav-link.active .profit-nav {
  /* width: 98%; */
  height: 88px;
  box-shadow: 0px 15px 10px -15px #111;
}
.nav-tabs {
  border-bottom: 1px solid transparent;
}
#tabContent {
  box-shadow: 0px -11px 10px -15px #111;
}
#tabProfit {
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 0px;
}
</style>
