<template>
  <div>
    <div class="container p-4">
      <div>
        <img class="img-appointLine" :src="url + data_appoint.course_picture" alt="" />
      </div>
      <div class="card text-center bordered-appoint mt-3">
        <div class="mt-4 border-solid">
          <p class="text-xl font-weight-bold">ยืนยันนัดหมาย</p>
          <h4 class="text-success font-weight-bold">
            แพ็คเกจ : {{ data_appoint.name_th }}
          </h4>
          <h6>
            วันที่ :
            <span class="text-primary font-weight-bold text-xl">{{
                formatDate(data_appoint.appoint_datetime)
            }}</span>
          </h6>
          <h6>
            เวลา :
            <span class="text-primary text-xl">{{
                formatTime(data_appoint.appoint_datetime)
            }}</span>
          </h6>
          <h4 class="text-danger font-weight-bold">สำเร็จ</h4>
        </div>
      </div>
      <!-- <div class="mt-5">
        <button @click="handleClose()" class="form-control btn btn-danger">
          ปิด
        </button>
      </div> -->
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      url: this.$store.getters.url_api,
      phone: "",
      data_appoint: "",
      dateAppoint: "",
      timeAppoint: "",
    };
  },
  methods: {
    RedirectMyCousres() {
      console.log(this.$store);
      var branch_id = localStorage.getItem("branch");
      this.$store.dispatch("getListAppointment", branch_id);
      this.$store.commit("ChangePages", "appointList");
    },
    formatDate(date) {
      moment.locales("th");
      return moment(date)
        .add("year", 543)
        .format("LL");
    },
    formatTime(date) {
      moment.locales("th");
      return moment(date).format("HH:mm");
    },
    getCourseAppoint() {
      const id_appoint = this.$route.params.id;
      console.log(id_appoint);
      this.$store.dispatch("getDataAppointment", id_appoint).then((res) => {
        console.log(res);
        this.data_appoint = res.admin;
      });
    },
    getLinePhone() {
      const id_appoint = this.$route.params.id;
      console.log(id_appoint);
      this.$store.dispatch("getLinePhone", id_appoint).then((res) => {
        console.log(res);
      });
    },
    handleClose() {
      // var branch_id = localStorage.getItem("branch");
      // this.$store.dispatch("getListAppointment", branch_id);
      // this.$store.commit("ChangePages", "appointList");
    },
  },
  beforeCreate() {

  },
  mounted() {
    this.token = localStorage.getItem("token_" + this.$route.query.branches_id);
    if (this.token) {
      this.phone = this.$store.getters.clinic_detail.phone_number;
    this.getCourseAppoint();
    this.getLinePhone();
    }else{
      let qs = `/appointLine/${this.$route.params.id}?branches_id=${this.$route.query.branches_id}&clinic_id=${this.$route.query.clinic_id}`
      this.$router.push(`/id/${this.$route.query.branches_id}?redirect=${qs}`)
    }

  },
};
</script>
<style>
.border-booking {
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 13px;
  margin-top: 25px;
}

.text-success {
  color: #43c506;
}

.text-date {
  color: #9d1a3a;
}

.bordered-appoint {
  border: 1px solid #aaaaaa;
  border-radius: 13px;
}

.img-appointLine {
  border-radius: 13px;
}
</style>
