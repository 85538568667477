<template>
  <div id="bg-background" class="container mx-auto px-4 h-full register-form">
    <div class="flex content-center items-center justify-center pt-5">
      <div class="w-full">
        <div class="min-w-0 break-words w-full mb-1 rounded-lg border-0">
          <ValidationObserver
            v-slot="{ invalid }"
            ref="register-form"
            slimtag="form"
          >
            <div class="mb-0 px-6 text-center">
              <b class="text-xl">ยืนยันเบอร์โทรศัพท์มือถือ</b>
            </div>
            <!-- <form class="register-form"> -->
            <section class="slide-1" v-if="stepProgressParams.current == '1'">
              <div class="text-center mb-3">
                <div style="text-align: -webkit-center" class="mb-0">
                  <img v-if="url + logo_img" :src="url + logo_img" alt="" />
                  <img
                    v-else
                    id="logo_screen"
                    src="../../assets/img/icons/Logo5.png"
                    alt=""
                  />
                </div>
                <small id="confirm_letter" class="font-bold text-lg">{{
                  language_text.thai.contact
                }}</small>
              </div>
              <div class="row justify-content-center">
                <div class="col-12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="length:10|max:10|required"
                    name="เบอร์โทร"
                  >
                    <div class="input-group mb-2 mt-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <i class="fas fa-mobile-alt"></i>
                        </div>
                      </div>

                      <input
                        type="number"
                        class="form-control py-2"
                        id="inlineFormInputGroup"
                        placeholder="0XXXXXXXXX"
                        maxlength="10"
                        v-model="input.phone"
                      />
                    </div>
                    <span
                      v-if="errors"
                      class="text-danger"
                      style="font-size: 16px"
                      >{{
                        errors[0] ? "กรุณากรอก 'เบอร์โทร' เพื่อลงทะเบียน " : ""
                      }}</span
                    >
                  </ValidationProvider>
                </div>
              </div>
            </section>
            <section class="slide-3" v-if="stepProgressParams.current == '2'">
              <div class="text-center mb-3 mt-5 pt-4">
                <span id="cfOTP_letter" class="text-lg">
                  <p>โปรดกรอกรหัส OTP ที่ได้รับ</p>
                </span>
              </div>
              <otp-input
                class="justify-content-center"
                ref="otpInput"
                input-classes="otp-input"
                separator=""
                v-model="input.otp"
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-change="handleOnChange"
              />
              <!-- @on-complete="handleOnComplete" -->
            </section>
            <div
              v-if="stepProgressParams.current == '2'"
              class="text-center mt-10"
            >
              <vac :left-time="60000" ref="reotp">
                <template v-slot:process="resendOTP">
                  <div>
                    <span style="font-size: 18px">{{
                      `หากคุณไม่ได้รับข้อความ ภายใน : ${resendOTP.timeObj.ceil.s}`
                    }}</span>
                  </div>
                  <div class="spinner-border mt-3 text-primary">
                    <span class="sr-only">Loading...</span>
                  </div>
                </template>

                <!-- <template v-if="resend_btn == false">
                  <div v-show="count_time">
                    <button class="
                        btn
                        mt-1
                        text-xl
                        btn-repeat
                        hover:shadow-lg
                        outline-none
                        focus:outline-none
                        ease-linear
                        transition-all
                        duration-150
                      " @click="SendOtpagain">
                      ส่งอีกครั้ง
                    </button>
                  </div>
                </template> -->
                <template v-slot:finish>
                  <div>
                    <button
                      class="
                        btn
                        mt-1
                        btn-repeat
                        hover:shadow-lg
                        outline-none
                        focus:outline-none
                        ease-linear
                        transition-all
                        duration-150
                      "
                      @click="SendOtpagain"
                    >
                      ส่งอีกครั้ง
                    </button>
                  </div>
                </template>
              </vac>
            </div>
            <div
              class="text-center mt-1 btns_register"
              v-if="stepProgressParams.current == '1'"
            >
              <button
                class="
                  text-white
                  btn btn-main-submit
                  form-control
                  mb-2
                  confirm_btn
                "
                type="submit"
                id="next_btn"
                @click="confirmData"
                :disabled="invalid"
              >
                ยืนยัน
              </button>
              <router-link to="/login">
                <button
                  class="btn btn-danger form-control text-white"
                  id="cancel_btn"
                  type="button"
                >
                  ยกเลิก
                </button>
              </router-link>
            </div>
            <div
              class="text-center mt-1 btns_register"
              v-if="stepProgressParams.current == '2'"
            >
              <div style="color: red; font-size: 18px" v-if="text_alert_otp">
                หมายเลข otp ไม่ถูกต้อง
              </div>
              <!-- <button
                type="button"
                class="btn btn-main-submit text-white form-control mb-2 "
                id="load1"
                @click="confirmOtpData"
              >
                {{ language_text.thai.btn_confirm_otp }}
              </button> -->
              <button
                v-if="isLoading_btn == true"
                class="btn btn-main-submit form-control mb-2"
                type="button"
                disabled
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
              <button
                v-else
                class="btn btn-main-submit text-white form-control mb-2"
                type="submit"
                id="next_btn"
                :style="setTheme"
                @click="confirmOtpData"
                :disabled="input.otp == ''"
              >
                ยืนยัน
              </button>
              <button
                class="btn btn-danger form-control text-white"
                id="cancel_btn"
                type="button"
                @click="back"
              >
                ยกเลิก
              </button>
            </div>
            <!-- </form> -->
          </ValidationObserver>
          <div id="recaptcha"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import stepProgress from "../../components/stepProgessSlider";
import OtpInput from "@bachdgvn/vue-otp-input";
// import firebase from "../../firebase";
// import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm";
import { th } from "vuejs-datepicker/src/locale";
import moment from "moment";
// import store from "../../store";
// import { required, maxLength, alpha } from "vuelidate/lib/validators";

export default {
  components: {
    // stepProgress,
    OtpInput,
    // Datepicker,
  },
  props: ["setTheme"],
  data() {
    return {
      language_text: {
        thai: {
          register: "ลงทะเบียน",
          contact: "ยืนยันเบอร์โทรศัพท์มือถือเพื่อเปิดการใช้งาน",
          getOTP: "กรอกเลข OTP",
          forRegister: "โปรดกรอกรหัส OTP ที่ได้รับ",
          fname: "ชื่อ",
          lname: "นามสกุล",
          nickname: "ชื่อเล่น",
          gender: "เพศ",
          male: "ชาย",
          female: "หญิง",
          other: "ไม่ระบุ",
          birthDate: "วันเกิด",
          email: "อีเมล",
          phone: "เบอร์โทร",
          btn_next: "ถัดไป",
          btn_cancel: "ยกเลิก",
          btn_prev: "ย้อนกลับ",
          btn_confirm: "ยืนยัน",
          btn_otp: "ยืนยัน OTP",
          repeat_send: "ส่งอีกครั้ง",
          message_not_otp: "หากคุณไม่ได้รับข้อความ ภายใน",
          btn_confirm_otp: "ยืนยัน",
        },
      },
      input: {
        fname: "",
        lname: "",
        nickname: "",
        gender: "",
        birthDate: "",
        email: "",
        phone: "",
        otp: "",
      },
      stepProgressParams: {
        current: 1,
        total: 3,
        prev: 1,
      },
      btnNext: false,
      btnBack: true,
      section: "",
      btnCancel: true,
      btnfinish: false,
      otpPage: false,
      th: th,
      chckbox_policy: false,
      text_err_policy: false,
      checkOTP: false,
      checkNext: false,
      branch_id: "",
      appVerifier: "",
      countdown_success: false,
      resend_btn: false,
      isLoading_btn: false,
      text_alert_otp: false,
      url: this.$store.getters.url_api,
      logo_img: "",
      otp_token: "",
      ref_code: "",
      count_time: false,
      // setTheme: {
      //   name: "default",
      //   background: "#21326a",
      //   color: "#FFF",
      // }
    };
  },
  methods: {
    next() {
      console.log(this.input);
      if (
        this.input.fname &&
        this.input.lname &&
        this.input.gender &&
        this.input.birthDate &&
        this.input.email
      ) {
        this.checkNext = true;
      }
      if (this.chckbox_policy == true) {
        this.stepProgressParams.current++;
        if (this.stepProgressParams.current == 2) {
          $("#next_btn").addClass("d-none");
          this.otpPage = true;
        }
      } else {
        this.text_err_policy = true;
      }
      this.stepProgressParams.current++;
      if (this.stepProgressParams.current == 2) {
        this.otpPage = true;
        $("#next_btn").addClass("d-none");
        $("#cancel_btn").addClass("d-none");
      }
    },
    back() {
      this.stepProgressParams.current--;
      this.isLoading_btn = false;
    },
    confirmData() {
      console.log(this.input.phone);
      const param = {
        phone: this.input.phone,
      };

      var clinic_id = localStorage.getItem("clinic_id");
      var branch = localStorage.getItem("branch");
      if (clinic_id) {
        param.clinic_id = clinic_id;
      }
      if (branch) {
        param.branches_id = branch;
      }

      this.$store.dispatch("sendOTP", param).then((res) => {
        console.log(res);
        this.stepProgressParams.current++;
        this.otp_token = res.data.expire_date;
        this.ref_code = res.data.ref_code;
        setTimeout(() => {
          this.count_time = true;
        }, 7000);
      });
      // console.log(this.stepProgressParams.current);
      // let thainumber = "+66";
      // let number = thainumber + this.input.phone;

      // firebase
      //   .auth()
      //   .signInWithPhoneNumber(number, recaptchaVerifier)
      //   .then(function(confirmationResult) {
      //     console.log(confirmationResult);
      //     window.confirmationResult = confirmationResult;
      //   })
      //   .catch(function(error) {
      //     console.error(error);
      //   });
    },

    //otp change
    handleOnChange(val) {
      console.log(val);
      this.input.otp = val;
      this.text_alert_otp = false;
    },
    handleOnComplete(val) {
      this.input.otp = val;

      var access_token = localStorage.getItem("access_token");
      var branch_id = localStorage.getItem("branch");
      var token = localStorage.getItem("token_" + branch_id);
      var clinic_id = localStorage.getItem("clinic_id");

      var data = {
        line_access_token: access_token,
        // token: token,
        clinic_id: clinic_id,
        phone: this.input.phone,
      };
      console.log(window.confirmationResult);
      window.confirmationResult
        .confirm(val)
        .then(function(result) {
          console.log(result.user);
          // console.log(clinic_id);
          console.log(data);
          this.$store
            .dispatch("Register", data)
            .then((res) => {
              console.log(res);
              location.replace("/dashboard");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    customFormatter(date) {
      console.log(date);
      moment.locale("th");
      return moment(date)
        .add(543, "year")
        .format("l");
    },
    checkPolicy() {
      this.chckbox_policy = !this.chckbox_policy;
      console.log(this.chckbox_policy);
    },
    checkGender(event) {
      this.input.gender = event;
      // var data = event.target.value;
    },
    handleClickOnComplete() {},
    confirmOtpData() {
      var access_token = localStorage.getItem("access_token");
      var branch_id = localStorage.getItem("branch");
      var token = localStorage.getItem("token_" + branch_id);
      var clinic_id = localStorage.getItem("clinic_id");
      // var store = this.$store;
      var data = {
        line_access_token: access_token,
        // token: token,
        clinic_id: clinic_id,
        phone: this.input.phone,
      };
      var validateOTP = {
        otp: this.input.otp,
        ref_code: this.ref_code,
        phone: this.input.phone,
      };
      this.isLoading_btn = true;
      this.$store
        .dispatch("validateOTP", validateOTP)
        .then((res) => {
          console.log(res);
          this.isLoading_btn = false;
          this.text_alert_otp = false;
          this.Register(data);
        })
        .catch((error) => {
          console.log(error);
          this.text_alert_otp = true;
          this.isLoading_btn = false;
        });
      // console.log(typeof window.confirmationResult);
      // console.log(window.confirmationResult);
      // if (
      //   window.confirmationResult == undefined ||
      //   window.confirmationResult == "undefined"
      // ) {
      //   this.isLoading_btn = false;
      //   this.text_alert_otp = true;
      // } else {
      //   this.isLoading_btn = true;
      // }
      // window.confirmationResult
      //   .confirm(val)
      //   .then(function(result) {
      //     console.log(result.user);
      //     console.log(data);
      //     this.Register(data);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     console.log(error);
      //     if (error) {
      //       this.text_alert_otp = true;
      //       this.isLoading_btn = false;
      //     }
      //   });
    },
    SendOtpagain() {
      this.text_alert_otp = false;
      this.resend_btn = true;
      this.$refs.otpInput.clearInput();
      this.$refs.reotp.startCountdown(true);
      let param = {
        phone: this.input.phone,
      };
      var clinic_id = localStorage.getItem("clinic_id");
      var branch = localStorage.getItem("branch");
      if (clinic_id) {
        param.clinic_id = clinic_id;
      }
      if (branch) {
        param.branches_id = branch;
      }
      this.$store.dispatch("sendOTP", param).then((res) => {
        this.isLoading_btn = false;
        console.log(res);
        this.otp_token = res.data.token;
        this.ref_code = res.data.ref_code;
      });
      // firebase
      //   .auth()
      //   .signInWithPhoneNumber(number, recaptchaVerifier)
      //   .then(function(confirmationResult) {
      //     console.log(confirmationResult);
      //     window.confirmationResult = confirmationResult;
      //   })
      //   .catch(function(error) {
      //     console.error(error);
      //   });
    },
    initReCaptcha() {
      // setTimeout(() => {
      //   let vm = this;
      //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      //     "recaptcha",
      //     {
      //       size: "invisible",
      //       callback: function (response) {
      //         // reCAPTCHA solved, allow signInWithPhoneNumber.
      //         // ...
      //         console.log(response);
      //       },
      //       "expired-callback": function () {
      //         // Response expired. Ask user to solve reCAPTCHA again.
      //         // ...
      //       },
      //     }
      //   );
      //   //
      //   vm.appVerifier = window.recaptchaVerifier;
      // }, 1000);
    },
    Register(data) {
      console.log(data);
      this.$store
        .dispatch("Register", data)
        .then((res) => {
          console.log(res);
          location.replace("/dashboard");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.logo_img = localStorage.getItem("img_logo");
    // this.setTheme = this.$attrs.setTheme
    console.log(this.stepProgressParams.current);
  },
  created() {
    this.initReCaptcha();
  },
};
</script>
<style>
.spinner-border {
  vertical-align: middle !important;
}

#bg-background {
  /* background-image: url("../../../public/images/bg.png");
  background-repeat: no-repeat;
  background-size: fixed;
  background-size: 100% 100%;
  height: 100vh; */
}

.btn-other {
  border-color: #85a4d2;
  border: 2px solid #85a4d2;
  border-radius: 10px;
}

.btn-female {
  border-color: #f20741;
  border: 2px solid #f20741;
  border-radius: 10px;
}

.btn-male {
  border-color: #21326a;
  border: 2px solid #21326a;
  border-radius: 10px;
}

.btn-other:not(:disabled):not(.disabled).active,
.btn-other:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #85a4d2;
  border-color: #85a4d2;
}

.btn-female:not(:disabled):not(.disabled).active,
.btn-female:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #f20741;
  border-color: #f20741;
}

.btn-male:not(:disabled):not(.disabled).active,
.btn-male:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #21326a;
  border-color: #21326a;
}

.birthdate_input {
  border: 1px solid #21326a;
  border-radius: 5px;
  /* color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59)); */
}

.register_input {
  border: 1px solid #21326a;
  border-radius: 5px;
  /* opacity: 70%; */
}

#inlineFormInputGroup {
  font-size: 18px;
}

.btn-prev {
  background: #9d1a3a;
  border-radius: 10px;
}

#next_btn {
  background: #21326a;
  color: #fff;
}

#next_btn:disabled {
  background: #5e6372 !important;
  border-radius: 10px;
}

.btn-repeat {
  background-color: #d9dee7 !important;
  color: #00205c !important;
  padding: 7px 20px !important;
}

.btn-repeat:disabled {
  color: #ffffff;
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  /* &.error {
      border: 1px solid red !important;
    } */
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Chrome, Safari, Edge, Opera / */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Firefox / */
input[type="number"] {
  -moz-appearance: textfield;
}

.btns_register {
  padding-top: 15%;
  bottom: 20%;
  /* width: 88%; */
}

@media screen and (max-width: 375px) {
  #gender_txt {
    margin-right: 2px;
    padding-left: 9px;
    color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  }
}

@media screen and (min-width: 414px) {
  #gender_txt {
    margin-right: 30px;
    padding-left: 9px;
    color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  }
}

@media screen and (min-width: 320px) and (orientation: landscape) {
  /* html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  } */
}

@media screen and (min-width: 1024px) {
  #register_letter {
    font-size: 36px;
  }

  #confirm_letter {
    font-size: 34px;
  }

  #getOTP_letter {
    font-size: 34px;
  }

  #cfOTP_letter {
    font-size: 26px;
  }
}
</style>
